import { Button } from '@/components/new/Button';
import { Dialog } from '@/components/new/Dialog';
import { Link } from '@/components/new/Link';
import { ModalContent, type ModalContentProps } from './ModalContent';
import styles from './styles.module.css';

export type SlackIntegrationModalProps = {
	loading: boolean;
	errors: string | null;
};

export const SlackIntegrationModal = ({
	loading,
	errors,
}: SlackIntegrationModalProps): JSX.Element => {
	const getState = (error: string | null): ModalContentProps => {
		if (error) return 'error';
		if (loading) return 'loading';
		return 'completed';
	};

	return (
		<Dialog open={true}>
			<Dialog.Window
				size="medium"
				onInteractOutside={(e) => e.preventDefault()}
				onEscapeKeyDown={(e) => e.preventDefault()}
				onPointerDownOutside={(e) => e.preventDefault()}
			>
				<Dialog.Contents>
					<ModalContent state={getState(errors)} />
				</Dialog.Contents>
				<Dialog.Footer>
					<div className={styles.footer}>
						<Link href={loading ? '' : '/settings/integrations'}>
							<span className={styles.linkButton}>
								<Button type="button" disabled={loading} size="large">
									OK
								</Button>
							</span>
						</Link>
					</div>
				</Dialog.Footer>
			</Dialog.Window>
		</Dialog>
	);
};
