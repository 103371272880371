export const getIsReapplicationEnabled = (createdAt: string | undefined) => {
	// NOTE: After 1 hour from the application creation time, reapplication is enabled
	const now = new Date();
	const applicationCreatedAt = createdAt ?? now.toISOString();
	const parsedApplicationCreatedAt = Date.parse(applicationCreatedAt);
	// NOTE: If the createdAt is invalid, reapplication is disabled
	// biome-ignore lint/suspicious/noGlobalIsNan: This is a valid use case
	if (isNaN(parsedApplicationCreatedAt)) return false;
	const createdAtDate = new Date(parsedApplicationCreatedAt);
	return now >= new Date(createdAtDate.getTime() + 1000 * 60 * 60);
};
