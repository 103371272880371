import { SelectBox } from '@/components/new/SelectBox';
import { DepositWithdrawal } from '@/graphql';
import type { Option } from '@moneyforward/cloud-react-ui/lib/components/Select/Select';
import { type Control, useController } from 'react-hook-form';
import styled from 'styled-components';
import type { FormValues } from './SearchPanel';

export type DepositWithdrawalType = {
	depositWithdrawalType: DepositWithdrawal;
};

const options: Option[] = [
	{ label: 'すべて', value: DepositWithdrawal.Both },
	{ label: '入金', value: DepositWithdrawal.Deposit },
	{ label: '出金', value: DepositWithdrawal.Withdrawal },
];

export type DepositWithdrawalTypeSelectProps = {
	control: Control<FormValues>;
};

const SelectBoxWrapper = styled.div({
	minWidth: '112px',
});

export const DepositWithdrawalTypeSelect = ({
	control,
}: DepositWithdrawalTypeSelectProps): JSX.Element => {
	const { field } = useController({
		name: 'depositWithdrawalType',
		control,
	});

	return (
		// biome-ignore lint/a11y/noLabelWithoutControl: TODO
		<label>
			入出金区分
			<SelectBoxWrapper>
				<SelectBox
					{...field}
					aria-label="入出金区分"
					onValueChange={(value) => {
						field.onChange(value);
						console.log(value);
					}}
					value={options.find((element) => element.value === field.value)}
					options={options}
					selectedTestId="depositWithdrawalType"
					ariaControlsId="depositWithdrawalType"
				/>
			</SelectBoxWrapper>
		</label>
	);
};
