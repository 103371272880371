import { RegistrationTable } from '@/components/ui/RegistrationTable';
import { RegistrationTableRow } from '@/components/ui/RegistrationTableRow';
import styled from 'styled-components';
import { PlannedCardHolderCountInput } from '../inputs/PlannedCardHolderCount';
import { PlannedUsageAmountInput } from '../inputs/PlannedUsageAmount';

const Wrapper = styled.div({
	marginBottom: '1.5em',
});

const InputWrapper = styled.div({
	width: '260px',
});

export const RegistrationFirstCardPlans = (): JSX.Element => {
	return (
		<Wrapper>
			<RegistrationTable header="現在または将来にわたり発行されるカードについてお答えください">
				<RegistrationTableRow title="年間のご利用予定額">
					<InputWrapper>
						<PlannedUsageAmountInput />
					</InputWrapper>
				</RegistrationTableRow>
				<RegistrationTableRow title="カードご利用予定人数">
					<InputWrapper>
						<PlannedCardHolderCountInput />
					</InputWrapper>
				</RegistrationTableRow>
			</RegistrationTable>
		</Wrapper>
	);
};
