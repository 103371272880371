import { Table } from '@/components/new/Table';
import { AddressRow } from '@/components/screen/settings/office/Index/AddressRow';
import type { UserIdentification } from '@/graphql';

type Props = Pick<
	UserIdentification,
	| 'buildingName'
	| 'city'
	| 'firstName'
	| 'lastName'
	| 'postalCode'
	| 'prefectureCode'
	| 'street'
>;

export const IndividualIdentificationTable = ({
	firstName,
	lastName,
	...addressRowProps
}: Props): JSX.Element => {
	return (
		<Table.Root>
			<Table.Head>
				<Table.Row>
					<Table.ColumnHeaderCell colSpan={2}>登録情報</Table.ColumnHeaderCell>
				</Table.Row>
			</Table.Head>
			<Table.Body>
				<Table.Row>
					<Table.RowHeaderCell>事業主名</Table.RowHeaderCell>
					<Table.Cell width="440px">
						{lastName}
						{firstName}
					</Table.Cell>
				</Table.Row>

				<AddressRow {...addressRowProps} />
			</Table.Body>
		</Table.Root>
	);
};
