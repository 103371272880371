import chargingAccountImage from '@/assets/images/chargingAccount.svg';
import { Link } from '@/components/new/Link';
import { Image } from '@/components/ui/Image';
import {
	type ChargingMoneyQuery,
	PostPayRequestStatusEnum,
	useChargingMoneyQuery,
} from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import styled from 'styled-components';
import { getIsReapplicationEnabled } from '../_lib/helper';

const Wrapper = styled.div`
  min-width: 900px;
  width: 100%;
  display: grid;
  grid-template-columns: 350px auto;
  background-color: #fff;
  padding: 20px;
`;

const TextLayout = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr;
  gap: 24px;
  align-self: center;
`;

const Heading = styled.h4`
  margin: 0;
  line-height: 28px;
  font-size: 16px;
  font-weight: 700;
`;

const CharingWrapper = styled.div``;

const AccountDetails = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  gap: 4px;
`;

const List = styled.ul`
  margin: 16px 0 0 0;
  padding: 0;
  list-style: none;
  color: #666;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
`;

const LinkText = styled.span`
  font-weight: 700;
`;

const shouldDisplayChargingMoney = (data: ChargingMoneyQuery | undefined) => {
	if (!data) return false;
	const { valueTransactions } = { ...data.currentOffice.wallet };
	const { status, createdAt } = { ...data.currentOffice.postPayRequest };

	const hasNoTransaction = (valueTransactions?.totalCount ?? 0) === 0;
	// NOTE: status ref => https://docs.google.com/spreadsheets/d/1w09-4_TfSkJm_HdTFc2vOUD_Xn2rQCCWsbKmxwQdZ7Q/edit#gid=1218611599
	const isPrepaidOnly = [
		undefined,
		PostPayRequestStatusEnum.Pending,
		PostPayRequestStatusEnum.Reviewing,
		PostPayRequestStatusEnum.Rejected,
	].includes(status);
	// NOTE: createdAt is the time when the postpay request was created.
	const isApplicationEnabled = createdAt
		? getIsReapplicationEnabled(createdAt)
		: true;
	return hasNoTransaction && isPrepaidOnly && isApplicationEnabled;
};

export const ChargingMoney = (): JSX.Element | null => {
	const { handleQueryError } = useApiError();
	const { data, loading } = useChargingMoneyQuery({
		fetchPolicy: 'cache-and-network',
		onError: handleQueryError,
	});

	if (loading) return null;

	if (!data || !shouldDisplayChargingMoney(data))
		return <div data-testid="emptyChargingMoney" />;

	const { gmoTransferAccount, specifiedRemitterName } = {
		...data.currentOffice.wallet,
	};

	return (
		<Wrapper>
			<Image src={chargingAccountImage} alt="" width={350} height={232} />
			<TextLayout>
				<Heading>
					マネーフォワード ビジネスカードは与信限度額に加えて
					<br />
					チャージすることで利用可能額を増やすことができます
				</Heading>
				<CharingWrapper>
					{gmoTransferAccount && (
						<AccountDetails>
							<span>入金用口座：GMOあおぞらネット銀行</span>
							<span>{gmoTransferAccount.branchNameKana}支店</span>
							<span>普通</span>
							<span>{gmoTransferAccount.accountNumber}</span>
							<span>{gmoTransferAccount.holderNameKana}</span>
						</AccountDetails>
					)}

					<AccountDetails>
						<span>振込依頼人名：{specifiedRemitterName ?? ''}</span>
					</AccountDetails>

					<List>
						<li>
							・振込依頼人名には必ずお客さま名フリガナ（法人の場合は法人名フリガナ）を含めてご入金ください
						</li>
						<li>・ウォレット残高の払い戻し（返金）はできません</li>
						<li>
							・入金タイミングにより残高への反映が翌営業日となる場合があります
						</li>
					</List>
				</CharingWrapper>
				<Link href="/postpay/registration">
					<LinkText>チャージなしでご利用いただく場合は与信審査へ</LinkText>
				</Link>
			</TextLayout>
		</Wrapper>
	);
};
