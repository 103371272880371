import { UsagePurposeEnum } from '@/graphql';
import { yup } from '@/lib/yup';

export type UsagePurposesData = yup.Asserts<typeof usagePurposesSchema>;

const usagePurposeSchema = yup
	.mixed<UsagePurposeEnum>()
	.oneOf(Object.values(UsagePurposeEnum), '利用目的にチェックをしてください')
	.required('利用目的にチェックをしてください');

export const usagePurposesSchema = yup.object({
	usagePurposes: yup
		.array()
		.of(usagePurposeSchema)
		.min(1, '利用目的にチェックをしてください')
		.required('利用目的にチェックをしてください'),
});
