import { FlashMessage } from '@/components/new/FlashMessage';
import styled from 'styled-components';

type Props = {
	isCorporate: boolean;
	displayName?: string;
	email?: string;
};

const Box = styled.div({
	margin: '8px 0 32px 0',
});

export const OwnerActiveError = ({
	isCorporate,
	displayName,
	email,
}: Props) => {
	return (
		<Box>
			<FlashMessage color="error">
				{`与信審査の申請には、${
					isCorporate
						? 'クラウド会計のオーナー権限を持った方（会計Plusの場合は「紐付けユーザー」）'
						: 'クラウド確定申告のオーナー権限を持った方'
				}がPay for Businessのユーザーである必要があります。オーナーの${displayName}（${email}）さんをユーザーに招待してください。`}
			</FlashMessage>
		</Box>
	);
};
