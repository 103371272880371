import { BaseStyle } from '@/components/ui/BaseStyle';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ${BaseStyle}
  html {
    background-color: #fff;
  }
`;

const Wrapper = styled.div`
  min-height: 100vh;
`;

export type DocsLayoutProps = {
	children: React.ReactNode;
};

export const DocsLayout = ({ children }: DocsLayoutProps): JSX.Element => {
	return (
		<>
			<GlobalStyle />
			<Wrapper>
				<main>{children}</main>
			</Wrapper>
		</>
	);
};
