import type { EstimatedPostPaidBilling, PostPaidBilling } from '@/graphql';
import styled from 'styled-components';
import { BillingTableRow } from './BillingTableRow';

type PostPaidBillingSlim = Pick<
	PostPaidBilling,
	'amount' | 'paymentStatus' | 'targetMonth'
>;
type EstimatedPostPaidBillingSlim = Pick<
	EstimatedPostPaidBilling,
	'amount' | 'targetMonth'
>;

type Props = {
	latestPostPaidBilling?: PostPaidBillingSlim | null;
	estimatedPostPaidBilling?: EstimatedPostPaidBillingSlim | null;
	isPostPayEnabled: boolean;
};

// NOTE: Remove the `margin-bottom` from the table to avoid the extra space
const Table = styled.table`
  width: 100%;
  margin-bottom: -16px;
`;

const Body = styled.tbody``;

export const BillingTable = ({
	estimatedPostPaidBilling,
	latestPostPaidBilling,
	isPostPayEnabled,
}: Props): JSX.Element => {
	return (
		<Table>
			<Body>
				{/* NOTE: Show estimated post paid billing even if  */}
				{estimatedPostPaidBilling && (
					<BillingTableRow {...estimatedPostPaidBilling} />
				)}
				{isPostPayEnabled && latestPostPaidBilling && (
					<BillingTableRow {...latestPostPaidBilling} />
				)}
			</Body>
		</Table>
	);
};
