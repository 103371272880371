import {
	type Corporation,
	PurposeEnum,
	type RegistrationCorporateApplicantQuery,
} from '@/graphql';
import { transformQueryResult } from '@/lib/transform';
import type { DefaultValues } from 'react-hook-form';
import type { FormData } from './types';

const fallbackNullableTo = <V, F>(
	value: V | null | undefined,
	fallback: F,
): NonNullable<V> | F => {
	return value ?? fallback;
};

const fallbackFalsyTo = <V, F>(
	value: V | null | undefined,
	fallback: F,
): NonNullable<V> | F => {
	return value || fallback;
};

export type SelectedCorporation = Pick<
	Corporation,
	| 'corporateNumber'
	| 'name'
	| 'furigana'
	| 'postCode'
	| 'prefectureCode'
	| 'cityName'
	| 'streetNumber'
>;

export const useRegistrationCorporateApplicantForm = (
	data: RegistrationCorporateApplicantQuery | undefined,
	selectedCorporation: SelectedCorporation | undefined,
) => {
	const { corporateIdentification, identificationPurposes, usagePurposes } = {
		...data?.currentOffice?.identificationVerificationRequest,
	};

	// MEMO: 現状一つしか項目がないから決め打ちで入れている
	const purposeItem = identificationPurposes &&
		identificationPurposes.length > 0 && {
			purpose: PurposeEnum.SettlementOfBusinessExpenses,
			purposeItemId: identificationPurposes[0].itemId,
		};

	const applicant = {
		...corporateIdentification,
		representativeFirstNameEn: fallbackNullableTo(
			corporateIdentification?.representativeFirstNameEn,
			undefined,
		),
		representativeLastNameEn: fallbackNullableTo(
			corporateIdentification?.representativeLastNameEn,
			undefined,
		),
		representativeMiddleNameEn: fallbackNullableTo(
			corporateIdentification?.representativeMiddleNameEn,
			undefined,
		),
		representativePostalCode: fallbackNullableTo(
			corporateIdentification?.representativePostalCode,
			undefined,
		),
		representativePrefectureCode: fallbackNullableTo(
			corporateIdentification?.representativePrefectureCode,
			undefined,
		),
		representativeCity: fallbackNullableTo(
			corporateIdentification?.representativeCity,
			undefined,
		),
		representativeBuildingName: fallbackNullableTo(
			corporateIdentification?.representativeBuildingName,
			undefined,
		),
		representativeStreet: fallbackNullableTo(
			corporateIdentification?.representativeStreet,
			undefined,
		),
		representativeNationalityId: fallbackNullableTo(
			corporateIdentification?.representativeNationality?.id,
			'JP',
		),
		isListed: corporateIdentification?.isListed ? 'true' : 'false',
		...purposeItem,
		postalCode: corporateIdentification?.postalCode
			? transformQueryResult.postalCode(corporateIdentification?.postalCode)
			: '',
		establishmentDate: corporateIdentification?.establishmentDate
			? transformQueryResult.iso8601Date(
					corporateIdentification.establishmentDate,
				)
			: '',
		website: fallbackNullableTo(corporateIdentification?.website, ''),
		industryText: fallbackNullableTo(corporateIdentification?.industryText, ''),
		birthDate: corporateIdentification?.representativeBirthDate
			? transformQueryResult.iso8601Date(
					corporateIdentification.representativeBirthDate,
				)
			: '',
		usagePurposes: usagePurposes?.map((item) => item.purpose) ?? [],
		salesAmount: fallbackNullableTo(
			corporateIdentification?.salesAmount,
			undefined,
		),
		assetsAmount: fallbackNullableTo(
			corporateIdentification?.assetsAmount,
			undefined,
		),
	};

	const defaultValues: DefaultValues<FormData> = {
		...applicant,
		...{
			// 法人検索の選択結果があればそちらを優先してformにセット
			corporateNumber: fallbackFalsyTo(
				selectedCorporation?.corporateNumber,
				applicant?.corporateNumber,
			),
			name: fallbackFalsyTo(selectedCorporation?.name, applicant?.name),
			nameKana: fallbackFalsyTo(
				selectedCorporation?.furigana,
				applicant?.nameKana,
			),
			nameEn: fallbackNullableTo(applicant?.nameEn, undefined),
			postalCode: fallbackFalsyTo(
				selectedCorporation?.postCode,
				applicant?.postalCode,
			),
			prefectureCode: fallbackFalsyTo(
				selectedCorporation?.prefectureCode,
				applicant?.prefectureCode,
			),
			city: fallbackFalsyTo(selectedCorporation?.cityName, applicant?.city),
			street: fallbackFalsyTo(
				selectedCorporation?.streetNumber,
				applicant?.street,
			),
		},
	};

	return {
		defaultValues,
	};
};
