import { RouteHistoryContext } from '@/contexts/RouteHistoryProvider';
import { useContext } from 'react';

export const useBackHref = (): string => {
	const { routeHistory } = useContext(RouteHistoryContext);

	const lastCardsRoute = routeHistory
		.filter((route) => /^\/cards(\?.+)?$/.test(route))
		.pop();

	return lastCardsRoute ?? '/cards';
};
