import { Button } from '@/components/new/Button';
import { SearchPanel } from '@/components/new/SearchPanel';
import { TextField } from '@/components/new/TextField';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useDefaultOpen } from '../hooks/useDefaultOpen';
import { useFormDefaultValues } from '../hooks/useFormDefaultValues';
import { type FormValues, ROLE_ALL } from '../types';
import { RoleSelect } from './RoleSelect';
import styles from './styles.module.css';

const SearchPanelWrapper = styled.div`
  /*
   * 画面のHeader下部に隙間なく設置するために PageLayout>Content のPaddingを打ち消す。
   * 表示箇所は固定されているので、margin等はここで指定してしまう。
   */
  margin: -20px -20px 48px -20px;

  div > div:not([aria-hidden='true']) {
    overflow-y: visible;
    row-gap: 15px;
  }
`;

export const UsersSearchPanel = (): JSX.Element => {
	const router = useRouter();
	const defaultOpen = useDefaultOpen();
	const defaultValues = useFormDefaultValues();
	const { register, handleSubmit, reset, control } = useForm<FormValues>({
		defaultValues,
	});

	const onSubmit = ({ displayName, role }: FormValues) => {
		router.push(
			{
				pathname: router.pathname,
				query: {
					...(displayName && { displayName }),
					...(role !== ROLE_ALL && { role }),
				},
			},
			undefined,
			{ shallow: true },
		);
	};
	const handleReset = () => {
		reset({
			displayName: '',
			role: ROLE_ALL,
		});
		router.push(router.pathname, undefined, { shallow: true });
	};

	return (
		<SearchPanelWrapper>
			<SearchPanel.Base defaultOpen={defaultOpen}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<SearchPanel.Filters>
						<SearchPanel.Row>
							<div className={styles.column}>
								<label htmlFor="displayName">表示名</label>
								<TextField id="displayName" {...register('displayName')} />
							</div>

							<div className={styles.column}>
								{/* biome-ignore lint/a11y/noLabelWithoutControl: TODO */}
								<label>
									ロール
									<RoleSelect control={control} />
								</label>
							</div>
						</SearchPanel.Row>
					</SearchPanel.Filters>

					<SearchPanel.Actions>
						<SearchPanel.ButtonGroup>
							<Button variant="outline" type="button" onClick={handleReset}>
								クリア
							</Button>
							<Button>検索</Button>
						</SearchPanel.ButtonGroup>
					</SearchPanel.Actions>
				</form>
			</SearchPanel.Base>
		</SearchPanelWrapper>
	);
};
