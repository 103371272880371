import { Link } from '@/components/new/Link';
import type { ReactNode } from 'react';
import { useSearchQueries } from '../hooks/useSearchQueries';

export const ClearTenantUser = ({
	children,
}: {
	children?: ReactNode;
}): JSX.Element => {
	const query = useSearchQueries();

	return (
		<Link href={{ pathname: '/cards', query }} shallow>
			{children}
		</Link>
	);
};
