import { PlannedCardHolderCountEnum } from '@/graphql';

export const PlannedCardHolderCountSelectOptions = [
	{ value: PlannedCardHolderCountEnum.Lte10, label: '1〜10人' },
	{ value: PlannedCardHolderCountEnum.Gte11Lte30, label: '11～30人' },
	{ value: PlannedCardHolderCountEnum.Gte31Lte100, label: '31～100人' },
	{ value: PlannedCardHolderCountEnum.Gte101, label: '101人以上' },
];

export const getCardHolderCountDescription = (
	count?: PlannedCardHolderCountEnum | null,
): string => {
	const value = PlannedCardHolderCountSelectOptions.find(
		(option) => option.value === count,
	);
	return value ? value.label : '';
};
