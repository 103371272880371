import { Button } from '@/components/new/Button';
import { Link } from '@/components/new/Link';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { Image } from '@/components/ui/Image';
import accounting from '@images/accounting.svg';
import expense from '@images/expense.svg';
import styled from 'styled-components';

const TableWrapper = styled.div`
  max-width: 800px;
`;

const Heading = styled.h4`
  font-size: 14px;
  margin: 0;
`;

const NoteText = styled(Text)`
  margin-left: 8px;
`;

const GuideLinkWrapper = styled.span`
  margin-left: 8px;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextBox = styled.div`
  margin-top: 8px;
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
`;

export type MfCloudTableProps = {
	caSsoUrl: string;
	caPlusSsoUrl: string;
	exSsoUrl: string;
	isIndividual: boolean;
};

export const MfCloudTable = ({
	caSsoUrl,
	caPlusSsoUrl,
	exSsoUrl,
	isIndividual,
}: MfCloudTableProps): JSX.Element => {
	return (
		<TableWrapper>
			<Table.Root>
				<Table.Head>
					<Table.Row>
						<Table.ColumnHeaderCell colSpan={2}>
							マネーフォワード クラウドとの連携
						</Table.ColumnHeaderCell>
					</Table.Row>
				</Table.Head>
				<Table.Body>
					<Table.Row>
						<Table.Cell width={230}>
							<ImageWrapper>
								<Image src={accounting} alt="" width={120} height={100} />
							</ImageWrapper>
						</Table.Cell>
						<Table.Cell>
							<Heading>
								{isIndividual
									? 'マネーフォワード クラウド確定申告'
									: 'マネーフォワード クラウド会計 / 会計Plus'}
							</Heading>

							<TextBox>
								<Text>
									カード利用明細がリアルタイム連携
									<br />
									電子帳簿保存法の活用でペーパーレスを促進
								</Text>
							</TextBox>

							<ButtonWrapper>
								<Link href={caSsoUrl} target="_blank">
									<Button type="button" variant="outline">
										{isIndividual ? 'クラウド確定申告' : 'クラウド会計'}
										と連携する
									</Button>
								</Link>

								<NoteText color={'notes'}>
									※ {isIndividual ? 'クラウド確定申告' : '会計'}
									のオーナー権限が必要です
								</NoteText>
							</ButtonWrapper>

							{!isIndividual && (
								<TextBox>
									<Link href={caPlusSsoUrl} target="_blank">
										<Button type="button" variant="outline">
											会計Plusと連携する
										</Button>
									</Link>

									<NoteText color={'notes'}>
										※ 会計Plusの代表者権限が必要です
									</NoteText>
								</TextBox>
							)}
						</Table.Cell>
					</Table.Row>

					<Table.Row>
						<Table.Cell width={230}>
							<ImageWrapper>
								<Image src={expense} alt="" width={120} height={100} />
							</ImageWrapper>
						</Table.Cell>
						<Table.Cell>
							<Heading>マネーフォワード クラウド経費</Heading>

							<TextBox>
								<Text>
									全従業員のカード利用明細がリアルタイムに連携
									<br />
									データを元にすることで入力負担が軽減
									<GuideLinkWrapper>
										<Link
											href="https://biz.moneyforward.com/support/expense/guide/linkage-general/li13.html"
											target="_blank"
										>
											連携ガイド
										</Link>
									</GuideLinkWrapper>
								</Text>
							</TextBox>

							<ButtonWrapper>
								<Link href={exSsoUrl} target="_blank">
									<Button type="button" variant="outline">
										クラウド経費と連携する
									</Button>
								</Link>

								<NoteText color={'notes'}>
									※ 経費の管理者権限が必要です
								</NoteText>
							</ButtonWrapper>
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table.Root>
		</TableWrapper>
	);
};
