import { Table } from '@/components/new/Table';
import type { ScheduledPointFragment } from '@/graphql';
import { PointFormat } from '@/lib/i18n';

export const ScheduledPointsTableRow = ({
	amount,
	description,
}: ScheduledPointFragment): JSX.Element => {
	return (
		<Table.Row>
			<Table.Cell size="large">{description}</Table.Cell>
			<Table.Cell width="156px" justify="end" size="large">
				{PointFormat.jp.format(Number(amount)).replace(/-/, '- ')} P
			</Table.Cell>
		</Table.Row>
	);
};
