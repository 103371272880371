import { Button } from '@/components/new/Button';
import { DataTable } from '@/components/new/DataTable';
import { Link } from '@/components/new/Link';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { TextWithQuestionIcon } from '@/components/ui/TextWithQuestionIcon';
import { RoleEnum } from '@/graphql';
import type { ApolloError } from '@apollo/client';
import styled from 'styled-components';
import type { OfficeMemberCard, Users } from '../types';

export const NO_USERS_MESSAGE = '絞り込み条件に一致するユーザーがいません';

const LinkButton = styled.div`
  display: grid;
  grid-template-columns: 48px;
`;

const CardsLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

export type UsersTableProps = {
	loading: boolean;
	error: ApolloError | undefined;
	users: Users;
	isCorporate: boolean;
};
export const UsersTable = ({
	loading,
	error,
	users,
	isCorporate,
}: UsersTableProps): JSX.Element => (
	<DataTable
		loading={loading}
		error={error}
		items={users}
		noItemsMessage={<Text color="notes">{NO_USERS_MESSAGE}</Text>}
		thead={
			<Table.Row>
				<Table.ColumnHeaderCell>表示名</Table.ColumnHeaderCell>
				<Table.ColumnHeaderCell>メールアドレス</Table.ColumnHeaderCell>
				<Table.ColumnHeaderCell>紐づいているカード</Table.ColumnHeaderCell>
				<Table.ColumnHeaderCell>
					<TextWithQuestionIcon
						label="ロール"
						content={
							<>
								管理者：すべての情報を閲覧・管理可能
								<br />
								一般：紐づいているカードのみ閲覧可能
							</>
						}
					/>
				</Table.ColumnHeaderCell>
				<Table.ColumnHeaderCell />
			</Table.Row>
		}
		renderItem={(item) => {
			const cards = (item.cards.nodes ?? []).reduce<OfficeMemberCard[]>(
				// biome-ignore lint/performance/noAccumulatingSpread: TODO
				(result, node) => (node ? [...result, node] : result),
				[],
			);

			return (
				<Table.Row key={item.id}>
					<Table.Cell size="large">{item.displayName}</Table.Cell>
					<Table.Cell size="large">{item.email}</Table.Cell>
					<Table.Cell size="large">
						{cards.length > 0 && (
							<CardsLayout>
								{cards.map((card) => (
									<Text key={card.id}>
										{card.name} ....{card.lastFour}
									</Text>
								))}
								{item.cards.totalCount > 2 && (
									<Link
										href={{
											pathname: '/cards',
											query: {
												tenantUserUid: item.id,
											},
										}}
									>
										他 {item.cards.totalCount - 2}枚
									</Link>
								)}
							</CardsLayout>
						)}
					</Table.Cell>
					<Table.Cell width="108px" size="large">
						{item.role === RoleEnum.Admin ? '管理者' : '一般'}
						{item.isManager && (
							<>
								<br />({isCorporate ? '取引担当者' : '事業者'})
							</>
						)}
					</Table.Cell>
					<Table.Cell width="60px" size="large">
						<Link href={`/settings/users/${item.id}`}>
							<LinkButton>
								<Button
									size="small"
									color="blue"
									variant="outline"
									type="button"
								>
									編集
								</Button>
							</LinkButton>
						</Link>
					</Table.Cell>
				</Table.Row>
			);
		}}
	/>
);
