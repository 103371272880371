import { Head } from '@/components/head';
import { PageLayout } from '@/components/screen';
import { DateTimeFormat } from '@/lib/i18n';
import { getDate } from '@/lib/query';
import { useRouter } from 'next/router';

export const Layout: React.FC = ({ children }) => {
	const router = useRouter();

	const date = getDate(router.query.targetMonth);
	const title = date
		? DateTimeFormat.jpYearMonthJst.format(new Date(date))
		: '-年-月';

	return (
		<PageLayout
			title={title}
			backHref="/postpay/transactions"
			backLinkText="利用履歴"
		>
			<Head title={`${title}利用履歴`} />
			<div>{children}</div>
		</PageLayout>
	);
};
