import type { XardCardRequestInput } from '@/graphql';
import { yup } from '@/lib/yup';

export const nameSchema: yup.ObjectSchema<Pick<XardCardRequestInput, 'name'>> =
	yup.object({
		name: yup
			.string()
			// remove leading and trailing spaces
			.trim()
			// replace full width spaces
			.transform((value: string) => value.replace(/\u3000/g, ' '))
			// replace multiple spaces in between words with single space
			.transform((value: string) => value.replace(/\s{2,}/g, ' '))
			.uppercase()
			// allow only uppercase letters, digits and spaces
			.matches(
				/^[A-Z0-9 ]+$/,
				'26文字以内の半角英数字または半角スペースで入力してください',
			)
			.max(26, '26文字以内の半角英数字または半角スペースで入力してください')
			.required(),
	});
