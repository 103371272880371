import { Button } from '@/components/new/Button';
import { Checkbox } from '@/components/new/Checkbox';
import { SearchPanel } from '@/components/new/SearchPanel';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import {
	CardState,
	type CardTypeCategory,
	type WalletCardsQueryVariables,
} from '@/graphql';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useDefaultOpen } from '../hooks/useDefaultOpen';
import { useFormDefaultValues } from '../hooks/useFormDefaultValues';
import { useTenantUserUid } from '../hooks/useTenantUserUid';
import { CARD_STATE_STOPPED, CARD_TYPE_CATEGORY_ALL } from '../types';
import { CardTypesSelect } from './CardTypesSelect';
import styles from './styles.module.css';

export const errorMessages = {
	lastFour: '4桁の半角数字を入力してください',
};

const SearchPanelWrapper = styled.div`
  /*
   * 画面のHeader下部に隙間なく設置するために PageLayout>Content のPaddingを打ち消す。
   * 表示箇所は固定されているので、margin等はここで指定してしまう。
   */
  margin: -20px -20px 48px -20px;

  div > div:not([aria-hidden='true']) {
    overflow-y: visible;
    row-gap: 15px;
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  gap: 16px;
  height: 34px;
  align-items: center;
`;
export type FormValues = Pick<
	WalletCardsQueryVariables,
	'keyword' | 'lastFour' | 'serialNumber'
> & {
	cardTypes: CardTypeCategory | typeof CARD_TYPE_CATEGORY_ALL;
	states: (
		| CardState.Active
		| CardState.Terminated
		| CardState.Unactivated
		| typeof CARD_STATE_STOPPED
	)[];
};

export type CardsSearchPanelProps = {
	isCorporate: boolean;
};
export const CardsSearchPanel = ({
	isCorporate,
}: CardsSearchPanelProps): JSX.Element => {
	const defaultValues = useFormDefaultValues();
	const {
		control,
		reset,
		handleSubmit,
		register,
		watch,
		resetField,
		formState: { errors },
	} = useForm<FormValues>({
		defaultValues,
	});
	const router = useRouter();
	const defaultOpen = useDefaultOpen();
	const tenantUserUid = useTenantUserUid();

	const onSubmit = ({
		keyword,
		lastFour,
		cardTypes,
		states,
		serialNumber,
	}: FormValues) =>
		router.push(
			{
				pathname: '/cards',
				query: {
					...(keyword && { keyword }),
					...(lastFour && { lastFour }),
					...(cardTypes !== CARD_TYPE_CATEGORY_ALL && { cardTypes }),
					...(!isDefaultStates(states) && { states }),
					...(tenantUserUid && { tenantUserUid }),
					...(serialNumber && { serialNumber }),
				},
			},
			undefined,
			{ shallow: true },
		);
	const handleReset = () => {
		reset({
			keyword: '',
			lastFour: '',
			serialNumber: '',
			cardTypes: CARD_TYPE_CATEGORY_ALL,
			states: [CardState.Active, CardState.Unactivated, CARD_STATE_STOPPED],
		});
		router.push(
			{
				pathname: '/cards',
				query: {
					...(tenantUserUid && { tenantUserUid }),
				},
			},
			undefined,
			{ shallow: true },
		);
	};

	if (watch('states').length === 0) {
		resetField('states', {
			defaultValue: [
				CardState.Active,
				CARD_STATE_STOPPED,
				CardState.Unactivated,
			],
		});
	}

	const validateSerialNumber = (
		value: string | undefined | null,
	): string | true => {
		const alphanumericRegex = /^[0-9A-Za-z]+$/;
		if (value && !alphanumericRegex.test(value)) {
			return '半角英数字を入力してください';
		}
		if (value && value.length > 9) {
			return '9文字以内で入力してください';
		}
		return true;
	};
	return (
		<SearchPanelWrapper>
			<SearchPanel.Base defaultOpen={defaultOpen}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<SearchPanel.Filters>
						<SearchPanel.Row>
							<div className={styles.column}>
								{/* biome-ignore lint/a11y/noLabelWithoutControl: TODO */}
								<label>
									キーワード
									<TextField
										{...register('keyword')}
										placeholder={
											isCorporate
												? '名義人 / 部署名 / 利用者 / メモ'
												: '名義人 / 利用者 / メモ'
										}
									/>
								</label>
							</div>

							<div className={styles.column}>
								{/* biome-ignore lint/a11y/noLabelWithoutControl: TODO */}
								<label>
									カード番号 下4桁
									<TextField
										{...register('lastFour', {
											pattern: {
												value: /^\d{4}$/,
												message: errorMessages.lastFour,
											},
										})}
										placeholder="半角数字4桁"
									/>
								</label>
								{errors.lastFour && (
									<FormError>{errors.lastFour.message}</FormError>
								)}
							</div>
							<div className={styles.column}>
								{/* biome-ignore lint/a11y/noLabelWithoutControl: TODO */}
								<label>
									シリアルナンバー (S/N)
									<TextField
										{...register('serialNumber', {
											validate: validateSerialNumber,
										})}
										placeholder="半角英数字9文字"
									/>
								</label>
								{errors.serialNumber && (
									<FormError>{errors.serialNumber.message}</FormError>
								)}
							</div>
							<div className={styles.column}>
								<CardTypesSelect control={control} />
							</div>

							<div>
								利用ステータス
								<CheckboxGroup>
									<Checkbox {...register('states')} value={CardState.Active}>
										利用中
									</Checkbox>
									<Checkbox {...register('states')} value={CARD_STATE_STOPPED}>
										停止中
									</Checkbox>
									<Checkbox
										{...register('states')}
										value={CardState.Unactivated}
									>
										有効化待ち
									</Checkbox>
									<Checkbox
										{...register('states')}
										value={CardState.Terminated}
									>
										解約済
									</Checkbox>
								</CheckboxGroup>
							</div>
						</SearchPanel.Row>
					</SearchPanel.Filters>

					<SearchPanel.Actions>
						<SearchPanel.ButtonGroup>
							<Button variant="outline" onClick={handleReset} type="button">
								クリア
							</Button>
							<Button>検索</Button>
						</SearchPanel.ButtonGroup>
					</SearchPanel.Actions>
				</form>
			</SearchPanel.Base>
		</SearchPanelWrapper>
	);
};

const isDefaultStates = (
	input: (
		| CardState.Active
		| CardState.Terminated
		| CardState.Unactivated
		| typeof CARD_STATE_STOPPED
	)[],
): boolean => {
	const stateSet = new Set(input);

	return (
		stateSet.has(CardState.Active) &&
		stateSet.has(CardState.Unactivated) &&
		stateSet.has(CARD_STATE_STOPPED) &&
		stateSet.size === 3
	);
};
