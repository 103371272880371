import { SelectBox } from '@/components/new/SelectBox';
import { FormError } from '@/components/ui/FormError';
import { AssetsAmountSelectOptions } from '@/lib/assetsAmount';
import { Controller, useFormContext } from 'react-hook-form';
import type { AssetsAmountSchemaData } from './schema';

export const AssetsAmountInput = (): JSX.Element => {
	const {
		control,
		formState: { errors },
	} = useFormContext<AssetsAmountSchemaData>();

	return (
		<>
			<Controller
				control={control}
				name="assetsAmount"
				render={({ field }) => (
					<SelectBox
						{...field}
						aria-label="法人資産額"
						placeholder="選択してください"
						options={AssetsAmountSelectOptions}
						value={AssetsAmountSelectOptions.find(
							(option) => option.value === field.value,
						)}
						onValueChange={(value) => field.onChange(value)}
						selectedTestId="selected-assetsAmount"
						ariaControlsId="assetsAmount"
					/>
				)}
			/>
			{errors.assetsAmount && (
				<FormError>{errors.assetsAmount.message}</FormError>
			)}
		</>
	);
};
