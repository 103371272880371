import {
	CurrentRequestStatus,
	useCurrentOfficeLoginStatusQuery,
} from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useLoginRedirect = (): void => {
	const router = useRouter();
	const { handleQueryError } = useApiError();
	const { data } = useCurrentOfficeLoginStatusQuery({
		fetchPolicy: 'network-only',
		onError: handleQueryError,
	});
	const tenantType = data?.currentOffice.tenantType;
	const currentRequestStatus = data?.currentOffice.currentRequestStatus;
	const conditionsChecked =
		!!data?.currentOffice.identificationVerificationRequest;

	const redirect = () => {
		if (tenantType && currentRequestStatus) {
			if (currentRequestStatus === CurrentRequestStatus.ActivatedCard) {
				// カードアクティベート済みの場合
				router.push('/');
			} else if (currentRequestStatus === CurrentRequestStatus.SentCard) {
				// カード送付済みの場合
				router.push('/registration/shipped');
			} else if (currentRequestStatus === CurrentRequestStatus.Applied) {
				// 申請済み
				router.push('/registration/applied');
			} else if (currentRequestStatus === CurrentRequestStatus.Denied) {
				// 申請却下
				router.push('/offices');
			} else if (!conditionsChecked) {
				// 注意事項が未チェックの場合
				router.push(`/registration/${tenantType}/conditions`);
			} else {
				// その他の場合は申込みステップ画面へ
				router.push(`/registration/${tenantType}`);
			}
		}
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	useEffect(() => {
		redirect();
	}, [tenantType, currentRequestStatus]);
};
