import { Checkbox } from '@/components/new/Checkbox';
import { Text } from '@/components/new/Text';
import { Image } from '@/components/ui/Image';
import { Cancel } from '@/components/ui/RegistrationButtons/Cancel';
import { useCorporateRegistrationImagesQuery } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { yupResolver } from '@hookform/resolvers/yup';
import certificateUpload from '@images/certificateUpload.svg';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useUploadCorporateRegistration } from '../hooks/useUploadCorporateRegistration';
import { type FormValues, schema } from '../schema';
import { DeleteUploadedFileButton, UploadButton } from './Buttons';
import { FileLayout, FileRow } from './FileRow';
import { Filename } from './Filename';
import { FormErrors } from './FormErrors';
import { Loading } from './Loading';
import { LocalFiles } from './LocalFiles';
import { CorporateRegistrationTable } from './Table';

const Box = styled.div`
  text-align: center;
  margin-top: 24px;
`;

const GuideContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 0;
  padding: 24px;
  background-color: #ffffff;
`;

const GuideTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-left: 12px;
`;

const GuideTextGrid = styled.div`
  display: grid;
  grid-template-columns: 16px auto;
`;

const WarningText = styled(Text)`
  font-size: 13px;
  font-weight: 700;
  line-height: 19.5px;
`;

const ConfirmContainer = styled.div`
  margin-top: 24px;
`;

export const Form = (): JSX.Element => {
	const { handleQueryError } = useApiError();
	const router = useRouter();
	const { data, loading: queryLoading } = useCorporateRegistrationImagesQuery({
		onError: handleQueryError,
	});
	const uploadedFiles =
		data?.currentOffice.identificationVerificationRequest
			?.corporateIdentification?.corporateRegistration
			?.corporateRegistrationImages ?? [];

	const [uploadCorporateRegistration, { loading: uploadLoading }] =
		useUploadCorporateRegistration();
	const { control, handleSubmit, clearErrors, reset, register, watch } =
		useForm<FormValues>({
			resolver: yupResolver(schema),
			context: {
				uploadedFilesCount: uploadedFiles.length,
			},
		});

	const onSubmit = async ({ corporateRegistrations }: FormValues) => {
		if (corporateRegistrations.length > 0) {
			await uploadCorporateRegistration(
				corporateRegistrations.map((cr) => cr.file),
				uploadedFiles.length,
			);

			reset();
		}

		router.push('/registration/corporate');
	};

	if (queryLoading) return <Loading />;

	return (
		<>
			<FormErrors control={control} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<CorporateRegistrationTable>
					{/* uploaded files */}
					{uploadedFiles.map((uploadedFile) => (
						<FileRow key={uploadedFile.uuid}>
							<FileLayout>
								<Filename>{uploadedFile.fileName}</Filename>
								<DeleteUploadedFileButton imageUuid={uploadedFile.uuid} />
							</FileLayout>
						</FileRow>
					))}
					{/* local files */}
					<LocalFiles
						uploadedFilesCount={uploadedFiles.length}
						control={control}
						clearErrors={clearErrors}
					/>
				</CorporateRegistrationTable>
				<GuideContainer>
					<Image src={certificateUpload} width={260} height={172} />
					<GuideTextWrapper>
						<GuideTextGrid>
							<Text>①</Text>
							<Text>
								現在事項全部証明書 / 履歴事項全部証明書 のいずれかの記載
							</Text>
							<Text>②</Text>
							<Text>法人名（商号）、本店所在地</Text>
							<Text>③</Text>
							<Text>事業内容（目的）</Text>
							<Text>④</Text>
							<Text>代表者氏名（役員）</Text>
							<Text>⑤</Text>
							<Text>取得日</Text>
						</GuideTextGrid>
					</GuideTextWrapper>
				</GuideContainer>

				<WarningText>
					ご確認ください
					<br />
				</WarningText>
				<Text>
					・①〜⑤の必須項目記載が含まれていない、または読み取れない場合は再提出をお願いしています。
					<br />
					・ご提出いただきます登記事項証明書は、原本を写真撮影またはスキャンした画像でアップロードしてください。
				</Text>

				<ConfirmContainer>
					<Checkbox {...register('isConfirmed')}>確認しました</Checkbox>
				</ConfirmContainer>

				<Box>
					<Cancel />
					<UploadButton
						loading={uploadLoading}
						disabled={!watch('isConfirmed')}
					/>
				</Box>
			</form>
		</>
	);
};
