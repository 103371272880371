import { useNationalitiesQuery } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { convertToNationalityOptions } from '@/lib/nationality';
import { Select } from '@moneyforward/cloud-react-ui';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormError } from '../../FormError';
import type { RepresentativeNationalityIdSchemaData } from './schema';

export const RepresentativeNationalityInput = () => {
	const {
		control,
		formState: { errors },
	} = useFormContext<RepresentativeNationalityIdSchemaData>();

	const { handleQueryError } = useApiError();

	const { data, loading } = useNationalitiesQuery({
		onError: handleQueryError,
	});

	const nationalities = data?.nationalities ?? [];

	const nationalityOptions = useMemo(
		() => convertToNationalityOptions(nationalities),
		[nationalities],
	);

	return (
		<>
			{!loading && (
				<Controller
					control={control}
					name={'representativeNationalityId'}
					render={({ field }) => (
						<Select
							{...field}
							aria-label={'国籍'}
							instanceId={'representativeNationalityId'}
							placeholder="選択してください"
							options={nationalityOptions}
							value={nationalityOptions.find(
								(option) => option.value === field.value,
							)}
							onChange={(e) => field.onChange(e?.value ?? '')}
						/>
					)}
				/>
			)}
			{errors.representativeNationalityId && (
				<FormError>{errors.representativeNationalityId.message}</FormError>
			)}
		</>
	);
};
