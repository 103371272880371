import { DataTable } from '@/components/new/DataTable';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { Image } from '@/components/ui/Image';
import type { PointTransactionDetailsFragment } from '@/graphql';
import type { ApolloError } from '@apollo/client';
import noPoints from '@images/noPoints.svg';
import { PointTransactionsTableRow } from './PointTransactionsTableRow';

export type PointTransactionsTableProps = {
	loading: boolean;
	error: ApolloError | undefined;
	pointTransactions: PointTransactionDetailsFragment[];
};

export const PointTransactionsTable = ({
	loading,
	error,
	pointTransactions,
}: PointTransactionsTableProps): JSX.Element => {
	return (
		<DataTable
			loading={loading}
			error={error}
			items={pointTransactions}
			noItemsIllustration={
				<Image src={noPoints} alt="no points" width={250} height={200} />
			}
			noItemsMessage={<Text color="notes">ポイント履歴はありません</Text>}
			thead={
				<Table.Row>
					<Table.ColumnHeaderCell>日時</Table.ColumnHeaderCell>
					<Table.ColumnHeaderCell>内容</Table.ColumnHeaderCell>
					<Table.ColumnHeaderCell justify="end">
						ポイント
					</Table.ColumnHeaderCell>
				</Table.Row>
			}
			renderItem={(item) => <PointTransactionsTableRow {...item} />}
		/>
	);
};
