import { Head } from '@/components/head';
import { PageLayout } from '@/components/screen';
import { Image } from '@/components/ui/Image';
import queryErrorIllustration from '@images/queryError.svg';
import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 800px;
`;

export const UserDoesNotExist = (): JSX.Element => (
	<PageLayout title="編集" backLinkText="ユーザー" backHref="/settings/users">
		<Head title="ユーザー編集" />
		<Layout>
			<Image
				src={queryErrorIllustration}
				alt="user does not exist"
				width={250}
				height={200}
			/>
			該当するユーザーがいません
		</Layout>
	</PageLayout>
);
