import { useWalletCardQuery } from '@/graphql';
import { useRouter } from 'next/router';

export const useCard = () => {
	const { id } = useRouter().query;
	return useWalletCardQuery({
		variables: {
			cardId: id as string,
		},
		skip: typeof id !== 'string',
	});
};
