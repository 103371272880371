import { Pagination } from '@/components/new/Pagination';
import {
	type PointTransactionDetailsFragment,
	useWalletPointTransactionsQuery,
} from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { usePaginationVariables } from '@/hooks/usePaginationVariables';
import styled from 'styled-components';
import { PointTransactionsTable } from './Table';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PointTransactionsTableWithPagination = (): JSX.Element => {
	const { handleQueryError } = useApiError();
	const paginationVariables = usePaginationVariables();
	const { data, loading, error } = useWalletPointTransactionsQuery({
		variables: {
			...paginationVariables,
		},
		onError: handleQueryError,
		fetchPolicy: 'cache-and-network',
	});

	const hasNextPage =
		!!data?.currentOffice.wallet?.pointTransactions?.pageInfo.hasNextPage;
	const hasPreviousPage =
		!!data?.currentOffice.wallet?.pointTransactions?.pageInfo.hasPreviousPage;
	const startCursor =
		data?.currentOffice.wallet?.pointTransactions?.pageInfo.startCursor;
	const endCursor =
		data?.currentOffice.wallet?.pointTransactions?.pageInfo.endCursor;
	const cardTransactions = (
		data?.currentOffice.wallet?.pointTransactions?.edges ?? []
	).reduce<PointTransactionDetailsFragment[]>(
		// biome-ignore lint/performance/noAccumulatingSpread: TODO
		(results, edge) => (edge?.node ? [...results, edge.node] : results),
		[],
	);

	return (
		<Container>
			{cardTransactions.length > 0 && (
				<Pagination
					endCursor={endCursor}
					startCursor={startCursor}
					hasPreviousPage={hasPreviousPage}
					hasNextPage={hasNextPage}
				/>
			)}

			<PointTransactionsTable
				loading={!data && loading}
				error={error}
				pointTransactions={cardTransactions}
			/>
		</Container>
	);
};
