import { FlashMessage } from '@/components/new/FlashMessage';
import {
	type PostPaidBillingFragment,
	usePostPaidBillingsQuery,
} from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import styled from 'styled-components';
import { Layout } from './Layout';
import { PostPayBillingsTable } from './PostPayBillingsTable';

const Box = styled.div`
  margin-bottom: 20px;
`;

const StatusFailureMessage = ({ reason }: { reason: string }): JSX.Element => {
	return (
		<Box>
			<FlashMessage color="error">{reason}</FlashMessage>
		</Box>
	);
};

export const PostPayBillingsIndex = (): JSX.Element => {
	const { handleQueryError } = useApiError();

	const { data, error, loading } = usePostPaidBillingsQuery({
		onError: handleQueryError,
	});

	const billings = (
		data?.currentOffice.wallet?.postPaidBillings?.nodes ?? []
	).reduce<PostPaidBillingFragment[]>(
		// biome-ignore lint/performance/noAccumulatingSpread: TODO
		(results, node) => (node ? [...results, node] : results),
		[],
	);

	const suspendedReason =
		data?.currentOffice.wallet?.postPaySetting?.suspendedReasonText;

	return (
		<Layout>
			{suspendedReason && <StatusFailureMessage reason={suspendedReason} />}
			<PostPayBillingsTable loading={loading} error={error} items={billings} />
		</Layout>
	);
};
