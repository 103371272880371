import { yup } from '@/lib/yup';

export type EstablishmentDateData = yup.Asserts<typeof establishmentDateSchema>;

export const establishmentDateSchema = yup.object({
	establishmentDate: yup
		.string()
		.iso8601Date()
		.required()
		.test('isPastDate', '正しい日付を入力してください', (value) => {
			const timestamp = Date.parse(value);
			// biome-ignore lint/suspicious/noGlobalIsNan: This is a valid case
			return !isNaN(timestamp) && timestamp <= Date.now();
		}),
});
