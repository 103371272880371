import styled from 'styled-components';

type Props = {
	children: React.ReactNode;
};

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  padding: 24px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d4d8dd;
`;

export const Root = ({ children }: Props): JSX.Element => {
	return <Wrapper>{children}</Wrapper>;
};
