import { usePointWidgetQuery } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import NextLink from 'next/link';
import styled from 'styled-components';
import { Card } from '../Card';

const FallbackSpace = styled.div`
  height: 72px;
`;

const Heading = styled.p`
  margin: 0;
  font-size: 14px;
  color: #666;
`;

const List = styled.ul`
  all: unset;
  list-style: none;
  display: flex;
  gap: 16px;
`;

const Link = styled.a`
  font-size: 13px;
  text-decoration: none;
  color: #3b7de9;
  &:hover {
    opacity: 0.8;
  }
`;

const BalanceWrapper = styled.div`
  height: 32px; // NOTE: for aligning with other widgets
`;

const Balance = styled.p`
  margin: 0;
  font-size: 32px;
  display: inline-flex;
  line-height: 1;
  align-items: baseline;
  font-weight: 500;
`;

const BalanceUnit = styled.span`
  font-size: 16px;
  position: relative;
  margin-left: 4px;
`;

export const PointWidget = (): JSX.Element => {
	const { handleQueryError } = useApiError();
	const { data, loading } = usePointWidgetQuery({
		onError: handleQueryError,
		fetchPolicy: 'cache-and-network',
	});
	const pointBalance = Number(data?.currentOffice.wallet?.point?.balance);

	if (loading) {
		return (
			<Card.Root>
				<FallbackSpace>
					<Card.Fallback />
				</FallbackSpace>
			</Card.Root>
		);
	}

	return (
		<Card.Root>
			<Card.Head>
				<Heading>ポイント残高</Heading>
				<List>
					<li>
						<NextLink href="/wallet/points" passHref>
							<Link>ポイント振替</Link>
						</NextLink>
					</li>
					<li>
						<NextLink href="/home/coupon/input" passHref>
							<Link>クーポン適用</Link>
						</NextLink>
					</li>
				</List>
			</Card.Head>
			<Card.Body>
				<BalanceWrapper>
					{/* biome-ignore lint/suspicious/noGlobalIsNan: This is a valid case */}
					{isNaN(pointBalance) ? (
						<Balance data-testid="pointBalanceError">
							-<BalanceUnit>ポイント</BalanceUnit>
						</Balance>
					) : (
						<Balance>
							{pointBalance.toLocaleString()}
							<BalanceUnit>ポイント</BalanceUnit>
						</Balance>
					)}
				</BalanceWrapper>
			</Card.Body>
		</Card.Root>
	);
};
