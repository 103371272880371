import { Table } from '@/components/new/Table';
import { CardDepartmentNameInput } from '@/components/ui/inputs';
import { type CardNoteFragment, CardState, RoleEnum } from '@/graphql';

export type DepartmentNameRowProps = Pick<
	CardNoteFragment,
	'departmentName'
> & {
	state: CardState;
	role: RoleEnum | undefined;
};

export const DepartmentNameRow = ({
	departmentName,
	state,
	role,
}: DepartmentNameRowProps): JSX.Element | null => {
	const isText =
		state === CardState.Terminated ||
		state === CardState.Unactivated ||
		role === RoleEnum.Employee;

	return (
		<Table.Row>
			<Table.RowHeaderCell>部署</Table.RowHeaderCell>
			<Table.Cell width="440px" size={isText ? 'large' : 'medium'}>
				{isText ? departmentName : <CardDepartmentNameInput />}
			</Table.Cell>
		</Table.Row>
	);
};
