import { Link } from '@/components/new/Link';
import { Text } from '@/components/new/Text';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
`;

const LinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px 16px;
  flex-wrap: wrap;
`;

const LinkText = styled.span`
  color: #666;
`;

const Box = styled.div`
  margin-top: 8px;
`;

export const Footer = (): JSX.Element => {
	return (
		<Wrapper>
			<LinksWrapper>
				<Link
					href="https://biz.moneyforward.com/biz-pay/agreement/"
					target="_blank"
				>
					<LinkText>サービス利用規約</LinkText>
				</Link>
				<Link href="https://corp.moneyforward.com/privacy/" target="_blank">
					<LinkText>個人情報保護方針</LinkText>
				</Link>
				<Link
					href="https://biz.moneyforward.com/biz-pay/settlement/"
					target="_blank"
				>
					<LinkText>資金決済法に基づく表示</LinkText>
				</Link>
			</LinksWrapper>
			<Box>
				<Text size="small">© Money Forward, Inc.</Text>
			</Box>
		</Wrapper>
	);
};
