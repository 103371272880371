import type { Props as RegistrationStepCardProps } from '@/components/ui/RegistrationStepCard/RegistrationStepCard';
import { type FirstCardRequestStepState, OpeningStepStatus } from '@/graphql';

export const convertToStepCardStatus = (
	stepStatus?: OpeningStepStatus | FirstCardRequestStepState,
): RegistrationStepCardProps['status'] => {
	if (!stepStatus) return 'waiting';

	switch (stepStatus) {
		case OpeningStepStatus.Completed:
			return 'completed';
		case OpeningStepStatus.Incompleted:
			return 'incomplete';
		case OpeningStepStatus.Unnecessary:
			return 'unnecessary';
		default:
			return 'waiting';
	}
};

export const getStepCardStatus = (
	identificationStatus: RegistrationStepCardProps['status'],
	stepStatus?: OpeningStepStatus | FirstCardRequestStepState,
): RegistrationStepCardProps['status'] => {
	// NOTE: Check if identification is submitted
	const isIdentificationCompleted =
		identificationStatus === 'completed' ||
		identificationStatus === 'incomplete';

	// NOTE: If identification is not submitted, steps other than identification are disabled
	if (isIdentificationCompleted) {
		return convertToStepCardStatus(stepStatus);
	}

	return 'disabled';
};
