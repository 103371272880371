import { Link } from '@/components/new/Link';
import { StatusLabel } from '@/components/new/StatusLabel';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { Tooltip } from '@/components/new/Tooltip';
import { Image } from '@/components/ui/Image';
import type { ValueTransactionDetailsFragment } from '@/graphql';
import receiptLong from '@images/icons/receiptLong.svg';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
`;

const LabelContainer = styled.div`
  flex-shrink: 0;
`;

const DetailContainer = styled.div`
  margin-left: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
`;

const LinkArea = styled.div`
  display: grid;
  width: 20px;
  height: 20px;
`;

const DepositLabel = (): JSX.Element => (
	<StatusLabel color="green" outline={true}>
		入金
	</StatusLabel>
);

const WithdrawalLabel = (): JSX.Element => (
	<StatusLabel color="orange" outline={true}>
		出金
	</StatusLabel>
);

export type TransactionContentCellProps = Pick<
	ValueTransactionDetailsFragment,
	'amount' | 'cardTransaction' | 'description'
>;
export const TransactionContentCell = ({
	amount,
	cardTransaction,
	description,
}: TransactionContentCellProps) => (
	<Table.Cell size="large">
		<Container>
			<LabelContainer>
				{Number(amount) > 0 ? <DepositLabel /> : <WithdrawalLabel />}
			</LabelContainer>
			<DetailContainer>
				<Text>{description}</Text>
				{cardTransaction && (
					<>
						<Text>
							({cardTransaction.card.name} ....{cardTransaction.card.lastFour})
						</Text>
						<Tooltip content="利用明細">
							<LinkArea>
								<Link
									href={{
										pathname: '/cards/transactions',
										query: {
											cardTransactionId: cardTransaction.id,
										},
									}}
								>
									<Image width={20} height={20} src={receiptLong} />
								</Link>
							</LinkArea>
						</Tooltip>
					</>
				)}
			</DetailContainer>
		</Container>
	</Table.Cell>
);
