import { Table } from '@/components/new/Table';
import { MaximumLimitInMonthInput } from '@/components/ui/inputs';
import { type CardControl, CardState, RoleEnum } from '@/graphql';
import { CurrencyFormat } from '@/lib/i18n';

export type MaximumLimitInMonthRowProps = Pick<
	CardControl,
	'maximumLimitInMonth'
> & {
	state: CardState;
	role: RoleEnum | undefined;
};

export const MaximumLimitInMonthRow = ({
	maximumLimitInMonth,
	state,
	role,
}: MaximumLimitInMonthRowProps): JSX.Element => {
	const isText =
		state === CardState.Terminated ||
		state === CardState.Unactivated ||
		role === RoleEnum.Employee;

	return (
		<Table.Row>
			<Table.RowHeaderCell>月ごとの上限金額</Table.RowHeaderCell>
			<Table.Cell width="440px" size={isText ? 'large' : 'medium'}>
				{isText ? (
					CurrencyFormat.jp.format(maximumLimitInMonth)
				) : (
					<MaximumLimitInMonthInput />
				)}
			</Table.Cell>
		</Table.Row>
	);
};
