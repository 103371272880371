import { yup } from '@/lib/yup';

export type PostPayLimitData = yup.Asserts<typeof postPayLimitSchema>;

export const postPayLimitSchema = yup.object({
	postPayLimit: yup
		.number()
		.when('$isEditMode', {
			is: true,
			// biome-ignore lint/suspicious/noThenProperty: TODO
			then: (schema) => schema.min(0, '0以上の数字を入力してください'),
			otherwise: (schema) =>
				schema.min(10, '10万円以上の金額を入力してください'),
		})
		.max(100_000, '10億円を超える金額は設定できません')
		.typeError('半角数字を入力してください')
		.required('半角数字を入力してください'),
});
