import { HighlightAnnouncement as HighlightAnnouncementUi } from '@/components/ui/HighlightAnnouncement';
import { useHighlightAnnouncementQuery } from '@/graphql';

export const HighlightAnnouncement = (): JSX.Element | null => {
	const { data } = useHighlightAnnouncementQuery({
		onError: () => {
			// do nothing
		},
	});

	// hide when loading, error and null data
	if (!data?.highlightAnnouncement) return null;

	const { id, message, destinationUrl } = { ...data.highlightAnnouncement };

	return (
		<HighlightAnnouncementUi
			id={id}
			message={message}
			destinationUrl={destinationUrl}
		/>
	);
};
