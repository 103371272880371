import styled from 'styled-components';

type Props = {
	children: React.ReactNode;
};

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const Head = ({ children }: Props): JSX.Element => {
	return <Wrapper>{children}</Wrapper>;
};
