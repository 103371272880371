import { Head } from '@/components/head';
import { Button } from '@/components/new/Button';
import { Link } from '@/components/new/Link';
import { Loading } from '@/components/screen/loading';
import {
	LoginAction,
	LoginBlock,
	LoginDescription,
	LoginLayout,
	LoginTitle,
} from '@/components/ui/Login';
import { useCurrentUserIdQuery } from '@/graphql';
import { setOauthStateCookie } from '@/lib/cookie';
import { getLoginUrl, getSignUpUrl } from '@/lib/mfid';
import { generateNonceString } from '@/lib/string';
import { useRouter } from 'next/router';
import { parseCookies } from 'nookies';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSetRedirectPath } from './hooks/useSetRedirectPath';

const Box = styled.div`
  padding: 16px 0;
`;
export const Login = (): JSX.Element => {
	const [oauthState, setOauthState] = useState('');
	const router = useRouter();
	// get redirectPath from query params
	useSetRedirectPath();
	const { loading } = useCurrentUserIdQuery({
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			// redirect to an appropriate page if user signed in
			if (data.currentUser.id) {
				router.push('/login/redirect');
			}
		},
		onError: () => {
			// do nothing
			// if session was expired, server removes a token from user's cookie
		},
		// skip this query since having the redirectPath in query params means no session
		skip: router.query.redirectPath !== undefined,
	});

	useEffect(() => {
		// set state to login url for oauth
		const randomState = parseCookies().state || generateNonceString(32);
		setOauthState(randomState);
		setOauthStateCookie(randomState);
	}, []);

	if (loading) return <Loading />;

	return (
		<LoginLayout>
			<LoginBlock>
				<Head title="ログイン" />
				<LoginTitle>マネーフォワード Pay for Business にログイン</LoginTitle>
				<LoginDescription>
					続けるにはマネーフォワード IDでのログインが必要です。
					<br />
					ログインページに遷移します。
				</LoginDescription>
				<LoginAction>
					<Link
						href={getLoginUrl({
							prompt: 'login',
							state: oauthState,
							loginHint: '',
							maxAge: 0,
						})}
					>
						<Button size="large">マネーフォワード IDでログイン</Button>
					</Link>
					<Box>
						<Link href={getSignUpUrl({ state: oauthState })}>
							マネーフォワード IDに登録
						</Link>
					</Box>
				</LoginAction>
			</LoginBlock>
		</LoginLayout>
	);
};
