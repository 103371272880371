import { TabsLayout } from '@/components/ui/TabsLayout/TabsLayout';
import { PointTransactionsTableWithPagination } from './PointTransactionsTableWithPagination';
import { ScheduledPointsTable } from './ScheduledPointsTable';

export const PointTabs = (): JSX.Element => (
	<TabsLayout
		tabs={[
			{
				label: 'ポイント履歴',
				content: <PointTransactionsTableWithPagination />,
			},
			{
				label: '付与予定のポイント',
				content: <ScheduledPointsTable />,
			},
		]}
	/>
);
