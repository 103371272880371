import Script from 'next/script';
import styled from 'styled-components';

const StorylaneBox = styled.div({
	marginTop: '128px',
});

const StorylaneDescription = styled.h3({
	margin: 0,
	fontWeight: 500,
	fontSize: '16px',
	lineHeight: '24px',
	letterSpacing: '0.15px',
	textAlign: 'center',
});

const StorylaneWrapper = styled.div({
	marginTop: '32px',
	width: '800px',
});

const Embed = styled.div({
	position: 'relative',
	paddingBottom: 'calc(54.66% + 27px)',
	width: '100%',
	transform: 'scale(1)',
});

const StyledIframe = styled.iframe({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	border: '1px solid rgba(63, 95, 172, 0.35)',
	boxShadow: '0px 0px 18px rgba(26, 19, 72, 0.15)',
	borderRadius: '10px',
});

export const Storylane = () => {
	return (
		<StorylaneBox>
			<StorylaneDescription>与信審査の手順を確認</StorylaneDescription>
			<StorylaneWrapper>
				<Script src="https://js.storylane.io/js/v1/storylane.js" />
				<Embed className="sl-embed">
					<StyledIframe
						className="sl-demo"
						src="https://moneyforward.storylane.io/demo/9ocn1rofcfcr"
						name="sl-embed"
						allow="fullscreen; camera; microphone"
					/>
				</Embed>
			</StorylaneWrapper>
		</StorylaneBox>
	);
};
