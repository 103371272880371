import styled from 'styled-components';

export type Props = {
	children: React.ReactNode;
};

const StyledContent = styled.div`
  padding-top: 40px;
`;

export const Content = (props: Props): JSX.Element => {
	return <StyledContent {...props} />;
};
