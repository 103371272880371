import { Button } from '@/components/new/Button';
import { CircularProgress } from '@/components/new/CircularProgress';
import styled from 'styled-components';

const ButtonWrapper = styled.div({
	'> button': {
		width: '160px',
	},
});

type ApplicationButtonProps = {
	loading: boolean;
};

export const ApplicationButton = ({
	loading,
}: ApplicationButtonProps): JSX.Element => (
	<ButtonWrapper>
		<Button size="large" disabled={loading}>
			{loading ? <CircularProgress size="medium" /> : 'この内容で申込'}
		</Button>
	</ButtonWrapper>
);
