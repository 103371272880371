import {
	type ActivateOfficeMemberMutation,
	useActivateOfficeMemberMutation,
} from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { setLoginCookie } from '@/lib/cookie';
import type { FetchResult } from '@apollo/client';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

type Props = {
	isAgree: boolean;
};

export const useRegistrationTerms = ({
	isAgree,
}: Props): {
	handleAgreeClick: () => Promise<FetchResult<ActivateOfficeMemberMutation>>;
} => {
	const router = useRouter();
	const { handleMutationError } = useApiError();
	const [activateOfficeMember] = useActivateOfficeMemberMutation({
		variables: { isAgree },
		onCompleted: (data) => {
			const officeId = data.activateOfficeMember?.officeMember.office.id;
			if (officeId) {
				setLoginCookie(officeId);
				router.push('/login/redirect').then(() => {
					router.reload();
				});
			}
		},
		onError: (error) => handleMutationError(error),
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	const handleAgreeClick = useCallback(() => activateOfficeMember(), [isAgree]);

	return { handleAgreeClick };
};
