import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { PostPayLimitInput } from '@/components/ui/inputs/PostPayLimit';
import { transformQueryResult } from '@/lib/transform';
import styled from 'styled-components';

type PostPayLimitEditTableProps = {
	creditLine?: number;
};

const Spacer = styled.span`
  margin: 0 4px;
`;

const Box = styled.div`
  margin-top: 8px;
`;

export const PostPayLimitEditTable = ({
	creditLine,
}: PostPayLimitEditTableProps): JSX.Element => {
	return (
		<Table.Root>
			<Table.Head>
				<Table.Row>
					<Table.ColumnHeaderCell colSpan={2}>
						与信限度額の変更申請
					</Table.ColumnHeaderCell>
				</Table.Row>
			</Table.Head>
			<Table.Body>
				<Table.Row>
					<Table.RowHeaderCell>希望金額</Table.RowHeaderCell>
					<Table.Cell width="440px">
						<Text size="small">
							現在の与信限度額<Spacer>:</Spacer>
							{creditLine !== undefined
								? `${transformQueryResult.tenThousandLocaleString(creditLine)}万円`
								: '-'}
						</Text>
						<Box>
							<PostPayLimitInput />
						</Box>
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Table.Root>
	);
};
