import { Head } from '@/components/head';
import { Text } from '@/components/new/Text';
import { Loading } from '@/components/screen';
import { Image } from '@/components/ui/Image';
import { RegistrationNote } from '@/components/ui/RegistrationNote';
import { RegistrationRejectedReason } from '@/components/ui/RegistrationRejectedReason';
import { RegistrationStepCard } from '@/components/ui/RegistrationStepCard';
import { RegistrationStepLayout } from '@/components/ui/RegistrationStepLayout';
import { RegistrationTitle } from '@/components/ui/RegistrationTitle';
import { useRegistrationIndividualStepQuery } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import queryError from '@images/queryError.svg';
import styled from 'styled-components';
import { getIndividualRejectReasons } from '../../shared/Steps/utils/getIndividualRejectReasons';
import { getIndividualStepStatuses } from '../../shared/Steps/utils/getIndividualStepStatuses';

const Spacer = styled.div`
  height: 16px;
`;

const ErrorWrapper = styled.div`
  --headerHeight: 40px;
  display: flex;
  height: calc(100vh - var(--headerHeight));
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const RegistrationIndividualSteps = (): JSX.Element => {
	const { handleQueryError } = useApiError();
	const { data, loading, error } = useRegistrationIndividualStepQuery({
		fetchPolicy: 'network-only',
		onError: handleQueryError,
	});

	if (loading) return <Loading />;

	if (error || !data)
		return (
			<ErrorWrapper>
				<Image src={queryError} alt="" width={250} height={200} />
				<Text color="notes">読み込みエラー</Text>
			</ErrorWrapper>
		);

	const { firstCardRequestStepState, identificationVerificationRequest } =
		data.currentOffice;
	const {
		individualIdentificationState,
		appearanceAndDocumentState,
		rejectedReason,
		reapplicationRequestMessage,
	} = {
		...identificationVerificationRequest?.individualStep,
	};

	const { stepStatuses } = getIndividualStepStatuses({
		individualIdentificationState,
		firstCardRequestStepState,
		appearanceAndDocumentState,
	});
	const { rejectedReasons, rejectedComment } = getIndividualRejectReasons({
		reapplicationRequestMessage,
		rejectedReason,
	});

	return (
		<RegistrationStepLayout>
			<Head title="利用申込" />
			<RegistrationTitle
				title="マネーフォワード Pay for Business 利用申込"
				center
			/>
			<RegistrationNote center>
				以下の情報をすべて入力し、「申請内容の確認・提出」へお進みください。
			</RegistrationNote>

			<RegistrationRejectedReason
				rejectedReasons={rejectedReasons}
				rejectedComment={rejectedComment}
			/>

			<RegistrationStepCard
				step="applicant"
				status={stepStatuses.identification}
				title="本人情報の入力"
				subTitle="本人確認書類と同じ情報を入力してください。"
			/>

			<Spacer />

			<RegistrationStepCard
				step="first-card"
				status={stepStatuses.firstCard}
				title="新規カード設定"
				subTitle="新規作成するカードの設定をしてください。"
			/>

			<Spacer />

			<RegistrationStepCard
				step="identification"
				status={stepStatuses.appearanceAndDocument}
				title="本人確認"
				subTitle="アプリにて、本人確認書類および容貌の撮影を行ってください。"
				warning="Webサイトからは本人確認書類・本人容貌写真のアップロードはできません。マネーフォワード Pay for Business アプリにて撮影・アップロードをお願いいたします。"
			/>

			<Spacer />

			<RegistrationStepCard
				step="confirmation"
				status={stepStatuses.confirmation}
				title="申請内容の確認・提出"
				subTitle="申請後、オペレーターが審査を行います。"
			/>
		</RegistrationStepLayout>
	);
};
