import { ValueTransactionsTable } from '@/components/screen/wallet/shared';
import {
	type ValueTransactionDetailsFragment,
	useValueTransactionsWidgetQuery,
} from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { CLICK_VIEW_ALL_TRANSACTION_HISTORY_BUTTON_HOME_SCREEN } from '@/lib/gtm';
import styled from 'styled-components';

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px;
`;

const Heading = styled.h3`
  font-size: 14px;
  margin: 0;
  font-weight: 500;
  color: #666;
`;

const StyledLink = styled.a`
  font-size: 13px;
  text-decoration: none;
  color: #3b7de9;
  &:hover {
    opacity: 0.8;
  }
`;

export const ValueTransactionsWidget = (): JSX.Element | null => {
	const { handleQueryError } = useApiError();
	const { data, loading } = useValueTransactionsWidgetQuery({
		onError: handleQueryError,
		fetchPolicy: 'cache-and-network',
	});

	if (loading) return null;

	if (
		!data ||
		(data.currentOffice.wallet?.valueTransactions?.edges ?? []).length === 0
	)
		return <div data-testid="emptyValueTransactionsWidget" />;

	const valueTransactions = (
		data?.currentOffice.wallet?.valueTransactions?.edges ?? []
	).reduce<ValueTransactionDetailsFragment[]>(
		// biome-ignore lint/performance/noAccumulatingSpread: TODO
		(results, edge) => (edge?.node ? [...results, edge.node] : results),
		[],
	);

	return (
		<div>
			<HeadingContainer>
				<Heading>入出金履歴</Heading>
				<StyledLink
					href="/wallet/transactions"
					data-ga-bizpay={CLICK_VIEW_ALL_TRANSACTION_HISTORY_BUTTON_HOME_SCREEN}
				>
					すべての履歴を見る
				</StyledLink>
			</HeadingContainer>

			<ValueTransactionsTable
				items={valueTransactions}
				loading={false}
				error={undefined}
				noItemsMessage={undefined}
			/>
		</div>
	);
};
