import type { ProofOfPurchaseSetting } from '@/graphql';
import { yup } from '@/lib/yup';

export type FormValues = Pick<
	ProofOfPurchaseSetting,
	'isActive' | 'isEdocActive'
>;

export const schema: yup.ObjectSchema<FormValues> = yup.object({
	isActive: yup.boolean().required(),
	isEdocActive: yup.boolean().required(),
});
