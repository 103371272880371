import { setRedirectPathCookie } from '@/lib/cookie';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useSetRedirectPath = (): void => {
	const router = useRouter();
	const redirectPath = router.query.redirectPath;

	useEffect(() => {
		if (!redirectPath) return;

		setRedirectPathCookie(JSON.stringify({ redirectPath }));
	}, [redirectPath]);
};
