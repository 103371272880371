import type { ParsedUrlQuery } from 'node:querystring';
import { DepositWithdrawal } from '@/graphql';
import { getDate, getString } from '@/lib/query';
import { useRouter } from 'next/router';
import type { DepositWithdrawalType } from '../DepositWithdrawalTypeSelect';
import type { FormValues } from '../SearchPanel';

export const useFormDefaultValues = (): Partial<FormValues> => {
	const router = useRouter();

	const depositWithdrawalType = getDepositWithdrawalType(
		router.query.depositWithdrawalType,
	);
	const keyword = getString(router.query.keyword);
	const transactionTimeGte = getDate(router.query.transactionTimeGte);
	const transactionTimeLte = getDate(router.query.transactionTimeLte);

	return {
		depositWithdrawalType,
		...(keyword && { keyword }),
		...(transactionTimeGte && { transactionTimeGte }),
		...(transactionTimeLte && { transactionTimeLte }),
	};
};

const getDepositWithdrawalType = (
	query: ParsedUrlQuery[string],
): DepositWithdrawalType['depositWithdrawalType'] => {
	switch (query) {
		case DepositWithdrawal.Deposit:
		case DepositWithdrawal.Withdrawal:
			return query;
		default:
			return DepositWithdrawal.Both;
	}
};
