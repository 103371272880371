import { Button } from '@/components/new/Button';
import { paginationLayouts } from '@/components/screen/layouts/PaginationAndExportLayout';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import {
	type PostPaidTransactionDetailsFragment,
	usePostPaidTransactionsQuery,
} from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import {
	getDate,
	getIso8601EndOfMonthDateTimeJst,
	getIso8601StartOfMonthDateTimeJst,
} from '@/lib/query';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Layout } from './Layout';
import { PostPaidValueTransactionsTable } from './PostPaidValueTransactionsTable';
import { PostPayUsageDetailWidget } from './PostPayUsageDetailWidget';
import { useExportPostPaidTransactions } from './hooks/useExportPostPaidTransactions';

const WidgetLayout = styled.div`
  margin-bottom: 20px;
`;

const ButtonLayout = styled.div({
	display: 'flex',
	justifyContent: 'end',
	marginBottom: '8px',
	'> button': {
		width: '160px',
	},
});

export const PostPayTransactionsDetail = (): JSX.Element => {
	const router = useRouter();
	const { handleQueryError } = useApiError();

	const start =
		getIso8601StartOfMonthDateTimeJst(router.query.targetMonth) ?? '';
	const end = getIso8601EndOfMonthDateTimeJst(router.query.targetMonth) ?? '';
	const targetMonthDate = getDate(router.query.targetMonth) ?? '';
	const isValidRange = !!(start && end);

	const { data, loading, error } = usePostPaidTransactionsQuery({
		variables: {
			createdAtGte: start,
			createdAtLte: end,
			targetMonthDate,
		},
		onError: handleQueryError,
		skip: !isValidRange,
	});

	const postPaidBilling = data?.currentOffice.wallet?.postPaidBilling;
	const account = data?.currentOffice.wallet?.postPaySetting?.ownerSubAccount;
	const office = data?.currentOffice;
	const canShowUsageDetailWidget = postPaidBilling && office;

	const transactions = (
		data?.currentOffice.wallet?.postPaidTransactions?.nodes ?? []
	).reduce<PostPaidTransactionDetailsFragment[]>(
		// biome-ignore lint/performance/noAccumulatingSpread: TODO
		(results, node) => (node ? [...results, node] : results),
		[],
	);

	const PaginationLayout = paginationLayouts.onlyExport;

	const [
		exportPostPaidTransactions,
		{ loading: exportPostPaidTransactionsLoading },
	] = useExportPostPaidTransactions();
	return (
		<Layout>
			{transactions.length > 0 && (
				<>
					{canShowUsageDetailWidget && (
						<WidgetLayout>
							<PostPayUsageDetailWidget
								postPaidBilling={postPaidBilling}
								account={account}
								office={office}
								managerUserIdentification={office.managerUserIdentification}
							/>
						</WidgetLayout>
					)}
					<PaginationLayout>
						<ButtonLayout>
							<Button
								onClick={() =>
									exportPostPaidTransactions({
										createdAtGte: start,
										createdAtLte: end,
									})
								}
								size="small"
								variant="outline"
								loading={exportPostPaidTransactionsLoading}
							>
								CSVエクスポート
							</Button>
						</ButtonLayout>
					</PaginationLayout>
				</>
			)}

			<GlobalMessage />

			<PostPaidValueTransactionsTable
				loading={loading}
				error={error}
				items={transactions}
			/>
		</Layout>
	);
};
