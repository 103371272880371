import { Button } from '@/components/new/Button';
import styled from 'styled-components';

const ButtonWrapper = styled.section({
	textAlign: 'center',
	marginTop: '24px',
	'> button': {
		width: '160px',
	},
});

export const Buttons = (): JSX.Element => (
	<ButtonWrapper>
		<Button size="large">申込へ進む</Button>
	</ButtonWrapper>
);
