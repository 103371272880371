import { SelectBox } from '@/components/new/SelectBox';
import type { Account } from '@/graphql';
import { Controller, useFormContext } from 'react-hook-form';
import { FormError } from '../../FormError';

type InputProps = {
	accounts: Account[];
};

export const PostPayAccountInput = ({ accounts }: InputProps): JSX.Element => {
	const {
		formState: { errors },
		control,
		setValue,
	} = useFormContext();

	const options = accounts.flatMap((account) => {
		return account.subAccounts.map((subAccount) => {
			return {
				value: subAccount.id,
				label: `${account.serviceName} ${subAccount.name} ${subAccount.type} ${subAccount.number}`,
			};
		});
	});

	const onPostPayAccountChanged = (value: string) => {
		setValue('subAccountId', value);
	};

	return (
		<>
			<Controller
				control={control}
				name={'subAccountId'}
				render={({ field }) => (
					<SelectBox
						{...field}
						aria-label="subAccountId"
						options={options}
						value={options.find((option) => option.value === field.value)}
						onValueChange={(value) => {
							field.onChange(value);
							onPostPayAccountChanged(value);
						}}
						placeholder={'選択してください'}
						selectedTestId="selected-subAccountId"
						ariaControlsId="subAccount"
					/>
				)}
			/>
			{errors.subAccountId && (
				<section data-testid="subAccountId">
					<FormError>{errors.subAccountId.message}</FormError>
				</section>
			)}
		</>
	);
};
