import { Root, type SwitchProps, Thumb } from '@radix-ui/react-switch';
import { forwardRef } from 'react';
import styles from './styles.module.css';

type Props = SwitchProps;

export const Switch = forwardRef<HTMLButtonElement, Props>((props, ref) => (
	<Root ref={ref} className={styles.root} {...props}>
		<Thumb className={styles.thumb} />
	</Root>
));
Switch.displayName = 'Switch';
