import { SelectBox } from '@/components/new/SelectBox';
import { CardTypeCategory } from '@/graphql';
import type { Option } from '@moneyforward/cloud-react-ui/lib/components/Select/Select';
import { type Control, useController } from 'react-hook-form';
import { CARD_TYPE_CATEGORY_ALL } from '../types';
import type { FormValues } from './SearchPanel';

const options: Option[] = [
	{ label: 'すべて', value: CARD_TYPE_CATEGORY_ALL },
	{ label: 'リアルカード', value: CardTypeCategory.Physical },
	{ label: 'バーチャルカード', value: CardTypeCategory.Virtual },
];

export type CardTypesSelectProps = {
	control: Control<FormValues>;
};
export const CardTypesSelect = ({
	control,
}: CardTypesSelectProps): JSX.Element => {
	const { field } = useController({
		name: 'cardTypes',
		control,
	});

	return (
		// biome-ignore lint/a11y/noLabelWithoutControl: TODO
		<label>
			カード形状
			<SelectBox
				{...field}
				aria-label="カード形状"
				onValueChange={(value) => field.onChange(value)}
				value={options.find((element) => element.value === field.value)}
				options={options}
				selectedTestId="selected-card-types"
				ariaControlsId="cardTypes"
			/>
		</label>
	);
};
