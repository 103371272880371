import { SelectBox } from '@/components/new/SelectBox';
import { CardsOrder } from '@/graphql';
import type { SelectProps } from '@moneyforward/cloud-react-ui';
import { useState } from 'react';
import styled from 'styled-components';

const SelectWrapper = styled.div`
  min-height: 34px;
  width: 160px;
`;

const options: SelectProps['options'] = [
	{ label: '発行日時が古い順', value: CardsOrder.CreatedAtAsc },
	{ label: '発行日時が新しい順', value: CardsOrder.CreatedAtDesc },
];
export const LOCAL_STORAGE_SORT_ORDER_KEY = 'sortOrder';
export type SortSelectProps = {
	onOrderChange: (order: CardsOrder | null) => void;
};
export const getSortOrderFromLocalStorage = (
	localStorageValue: string | null,
): CardsOrder => {
	return localStorageValue &&
		Object.values(CardsOrder).includes(localStorageValue as CardsOrder)
		? (localStorageValue as CardsOrder)
		: CardsOrder.CreatedAtAsc;
};
export const CardSortSelect = ({ onOrderChange }: SortSelectProps) => {
	const localStorageValue = window.localStorage.getItem(
		LOCAL_STORAGE_SORT_ORDER_KEY,
	);

	const initialSortOrder = getSortOrderFromLocalStorage(localStorageValue);

	const [sortOrderState, setSortOrderState] = useState(initialSortOrder);

	const handleOrderChange = (value: string) => {
		const newSortOrder = value as CardsOrder;
		setSortOrderState(newSortOrder);
		window.localStorage.setItem(LOCAL_STORAGE_SORT_ORDER_KEY, newSortOrder);
		onOrderChange(newSortOrder);
	};

	return (
		<SelectWrapper>
			<SelectBox
				options={options}
				value={options.find((option) => option.value === sortOrderState)}
				onValueChange={(value) => handleOrderChange(value)}
				aria-label="発行日時"
				ariaControlsId="cardOrder"
				selectedTestId="selected-cardOrder"
			/>
		</SelectWrapper>
	);
};
