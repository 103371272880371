import { Body } from './Body';
import { Fallback } from './Fallback';
import { Head } from './Head';
import { Root } from './Root';

export const Card = {
	Root,
	Head,
	Body,
	Fallback,
};
