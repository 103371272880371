import { SelectBox } from '@/components/new/SelectBox';
import { FormError } from '@/components/ui/FormError';
import { SalesAmountSelectOptions } from '@/lib/salesAmount';
import { Controller, useFormContext } from 'react-hook-form';
import type { SalesAmountSchemaData } from './schema';

export const SalesAmountInput = (): JSX.Element => {
	const {
		control,
		formState: { errors },
	} = useFormContext<SalesAmountSchemaData>();

	return (
		<>
			<Controller
				control={control}
				name="salesAmount"
				render={({ field }) => (
					<SelectBox
						{...field}
						aria-label="法人売上額"
						placeholder="選択してください"
						options={SalesAmountSelectOptions}
						value={SalesAmountSelectOptions.find(
							(option) => option.value === field.value,
						)}
						onValueChange={(value) => field.onChange(value)}
						selectedTestId="selected-salesAmount"
						ariaControlsId="salesAmount"
					/>
				)}
			/>
			{errors.salesAmount && (
				<FormError>{errors.salesAmount.message}</FormError>
			)}
		</>
	);
};
