import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { useFormContext } from 'react-hook-form';
import type { RepresentativeFirstNameKanaData } from './schema';

export const RepresentativeFirstNameKanaInput = (): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<RepresentativeFirstNameKanaData>();

	return (
		<div>
			<TextField
				aria-label="representativeFirstNameKana"
				{...register('representativeFirstNameKana')}
				placeholder="例）タロウ"
			/>
			{errors.representativeFirstNameKana && (
				<FormError>{errors.representativeFirstNameKana.message}</FormError>
			)}
		</div>
	);
};
