import { Head } from '@/components/head';
import { Button } from '@/components/new/Button';
import { LoginBlock, LoginLayout, LoginTitle } from '@/components/ui/Login';
import {
	type LoginOfficesTable_UserBelongingFragment,
	useCurrentUserTenantsQuery,
} from '@/graphql';
import { useLogout } from '@/hooks';
import { useApiError } from '@/hooks/useApiError';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Loading } from '../../loading';
import { LoginOfficesTable } from './LoginOfficesTable';

const LoginFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const LoginOffices = (): JSX.Element => {
	const { handleQueryError } = useApiError();
	const { logout } = useLogout();
	const router = useRouter();
	const { data, loading } = useCurrentUserTenantsQuery({
		onError: handleQueryError,
		onCompleted: (data) => {
			// NOTE: Redirect to /offices/register if the user has no tenants.
			if (data.currentUser.userBelongings.length === 0) {
				router.push('/offices/register');
			}
		},
	});

	if (loading) return <Loading />;

	const userBelongings = (data?.currentUser.userBelongings ?? []).reduce<
		LoginOfficesTable_UserBelongingFragment[]
		// biome-ignore lint/performance/noAccumulatingSpread: TODO
	>((result, item) => (item ? [...result, item] : result), []);

	return (
		<LoginLayout>
			<LoginBlock>
				<Head title="事業者選択" />
				<LoginTitle>事業者を選択</LoginTitle>
				<LoginOfficesTable userBelongings={userBelongings} />
			</LoginBlock>
			<LoginFooter>
				<Button type="button" variant="ghost" onClick={logout}>
					ログアウト
				</Button>
			</LoginFooter>
		</LoginLayout>
	);
};
