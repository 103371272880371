import { UserIndustryEnum } from '@/graphql';

export const UserIndustrySelectOptions: {
	label: string;
	value: UserIndustryEnum;
}[] = [
	{
		label: '農業・林業・漁業',
		value: UserIndustryEnum.AgricultureAndForestryAndFisheries,
	},
	{
		label: '鉱業・採石業・砂利採取業',
		value: UserIndustryEnum.MiningAndQuarryingOfStoneAndGravel,
	},
	{
		label: '建設業（設備等工事業含む）',
		value: UserIndustryEnum.Construction,
	},
	{
		label: '製造業',
		value: UserIndustryEnum.Manufacturing,
	},
	{
		label: '電気・ガス・熱供給・水道業',
		value: UserIndustryEnum.ElectricityGasHeatSupplyAndWater,
	},
	{
		label: '情報通信業',
		value: UserIndustryEnum.InformationAndCommunications,
	},
	{
		label: '運輸業',
		value: UserIndustryEnum.Transport,
	},
	{
		label: '小売業・卸売業（貴金属・宝石・美術品・古物・中古乗物）',
		value:
			UserIndustryEnum.RetailAndWholesaleTradeOfPreciousMetalsJewelryArtworksAntiquesUsedVehicles,
	},
	{
		label: '小売業・卸売業（上記以外）',
		value: UserIndustryEnum.OtherRetailAndWholesaleTrade,
	},
	{
		label: '金融業・保険業（暗号資産交換業・質屋）',
		value:
			UserIndustryEnum.FinancialAndInsuranceBusinessesCryptoAssetExchangePawnshop,
	},
	{
		label: '金融業・保険業（上記以外）',
		value: UserIndustryEnum.OtherFinancialAndInsuranceBusinesses,
	},
	{
		label: '不動産業・物品賃貸業',
		value: UserIndustryEnum.RealEstateAndGoodsRentalAndLeasing,
	},
	{
		label: '学術研究・専門・技術サービス業',
		value: UserIndustryEnum.ScientificResearchProfessionalAndTechnicalServices,
	},
	{
		label: '宿泊業・飲食サービス業',
		value: UserIndustryEnum.AccommodationsEatingAndDrinkingServices,
	},
	{
		label: '生活関連サービス業・娯楽業',
		value:
			UserIndustryEnum.LivingRelatedAndPersonalServicesAndAmusementServices,
	},
	{
		label: '教育・学習支援業',
		value: UserIndustryEnum.EducationLearningSupport,
	},
	{
		label: 'デジタルコンテンツ・情報商材販売業',
		value: UserIndustryEnum.DigitalContentsAndInformationProductSales,
	},
	{
		label: '医療・福祉',
		value: UserIndustryEnum.MedicalHealthCareAndWelfare,
	},
	{
		label: '複合サービス事業（協同組合等）',
		value: UserIndustryEnum.CompoundServices,
	},
	{
		label: 'サービス業（郵便物受取・電話受付代行・電話転送サービス）',
		value:
			UserIndustryEnum.PostalItemReceptionTelephoneReceptionAndTransferServices,
	},
	{
		label: 'サービス業（上記以外）',
		value: UserIndustryEnum.Services,
	},
];

export const getUserIndustryLabel = (
	Userindustry?: UserIndustryEnum | null,
): string => {
	const result = UserIndustrySelectOptions.find((option) => {
		return Userindustry === option.value;
	});
	return result?.label ?? '';
};
