import { Link } from '@/components/new/Link';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1em 0;
  line-height: 21px;
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const Item = styled.li`
  &::before {
    content: '・';
    display: inline;
  }
`;

export const Links = (): JSX.Element => (
	<Wrapper>
		以下に同意の上お進みください。
		<List>
			<Item>
				<Link
					href="https://biz.moneyforward.com/biz-pay/agreement/"
					target="_blank"
				>
					利用規約
				</Link>
			</Item>
			<Item>
				<Link
					href="https://biz.moneyforward.com/biz-pay/settlement/"
					target="_blank"
				>
					資金決済法に基づく表示
				</Link>
			</Item>
			<Item>
				<Link
					href="https://biz.moneyforward.com/biz-pay/agreement/point/"
					target="_blank"
				>
					ポイント規約
				</Link>
			</Item>
			<Item>
				<Link href="https://corp.moneyforward.com/privacy/" target="_blank">
					個人情報の取り扱いについて
				</Link>
			</Item>
		</List>
		マネーフォワード
		IDのメールアドレス宛にキャンペーン等のご案内をお送りする場合があります。
	</Wrapper>
);
