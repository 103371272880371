import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { useFormContext } from 'react-hook-form';
import type { CorporateNameEnData } from './schema';

export const CorporateNameEnInput = (): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<CorporateNameEnData>();

	return (
		<>
			<TextField aria-label="法人名（英語表記）" {...register('nameEn')} />
			{errors.nameEn && <FormError>{errors.nameEn.message}</FormError>}
		</>
	);
};
