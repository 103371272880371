import { Table } from '@/components/new/Table';
import type { PointTransactionDetailsFragment } from '@/graphql';
import { DateTimeFormat, PointFormat } from '@/lib/i18n';

export const PointTransactionsTableRow = ({
	amount,
	description,
	transactionTime,
}: PointTransactionDetailsFragment): JSX.Element => {
	return (
		<Table.Row>
			<Table.Cell width="140px" size="large">
				{DateTimeFormat.jp.format(new Date(transactionTime))}
			</Table.Cell>
			<Table.Cell size="large">{description}</Table.Cell>
			<Table.Cell width="156px" justify="end" size="large">
				{PointFormat.jp.format(Number(amount)).replace(/-/, '- ')} P
			</Table.Cell>
		</Table.Row>
	);
};
