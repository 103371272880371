import { Loading } from '@/components/screen/loading';
import { DestinationForm } from '@/components/ui/DestinationForm/DestinationForm';
import {
	type CardDestinationMasterInput,
	useCreateCardDestinationMasterMutation,
	useTradeNameQuery,
} from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import { useRouter } from 'next/router';
import { Layout } from './Layout';

export const SettingsDestinationsNew = (): JSX.Element => {
	const router = useRouter();
	const { handleMutationError } = useApiError();
	const { showSuccessNotification } = useGlobalNotification();

	const { data, loading } = useTradeNameQuery();
	const defaultValues = {
		tradeName:
			data?.currentOffice.identificationVerificationRequest
				?.corporateIdentification?.name,
	};

	const [createDestination, { loading: saving }] =
		useCreateCardDestinationMasterMutation({
			onCompleted() {
				router
					.push('/settings/destinations')
					.then(() => showSuccessNotification('カード送付先を保存しました'));
			},
			onError(error) {
				handleMutationError(error);
			},
		});

	const resetMessageAndNotification = useResetMessageAndNotification();

	const onSubmit = (data: CardDestinationMasterInput) => {
		if (saving) return;
		resetMessageAndNotification();
		createDestination({ variables: { ...data } });
	};

	if (loading) return <Loading />;

	return (
		<Layout>
			<DestinationForm
				defaultValues={defaultValues}
				saving={saving}
				onSubmit={onSubmit}
			/>
		</Layout>
	);
};
