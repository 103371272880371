import { Table } from '@/components/new/Table';
import type { SelectOption } from '@/components/ui/inputs/TenantUsers/TenantUsersInput';
import type {
	CardHolderFragment,
	CardNoteFragment,
	CardState,
	RoleEnum,
} from '@/graphql';
import { useTenantType } from '@/hooks/useTenantType';
import { Form } from './Form';
import { DepartmentNameRow } from './components/DepartmentNameRow';
import { MemoRow } from './components/MemoRow';
import { TenantUsersRow } from './components/TenantUsersRow';
import type { FormData } from './schema';

export type CardAdministrationTableProps = Pick<
	CardNoteFragment,
	'departmentName' | 'memo'
> & {
	cardId: string;
	cardHolders: CardHolderFragment[];
	state: CardState;
	role: RoleEnum;
};
export const CardAdministrationTable = ({
	cardId,
	cardHolders,
	departmentName,
	memo,
	state,
	role,
}: CardAdministrationTableProps): JSX.Element | null => {
	// TODO use for validation errors
	const { isCorporate } = useTenantType();

	const defaultValues: Partial<FormData> = {
		...(departmentName && {
			departmentName,
		}),
		tenantUsers: cardHolders.reduce<SelectOption[]>(
			(results, item) =>
				item.officeMember
					? [
							// biome-ignore lint/performance/noAccumulatingSpread: TODO
							...results,
							{
								label: item.officeMember.displayName,
								value: item.tenantUserUid,
							},
						]
					: results,
			[],
		),
		...(memo && {
			memo,
		}),
	};

	return (
		<div>
			<Form
				defaultValues={defaultValues}
				cardId={cardId}
				cardState={state}
				role={role}
			>
				<Table.Root>
					<Table.Head>
						<Table.Row>
							<Table.ColumnHeaderCell colSpan={2}>管理</Table.ColumnHeaderCell>
						</Table.Row>
					</Table.Head>
					<Table.Body>
						{isCorporate && (
							// When the tenant type is individual, there is no department section
							<DepartmentNameRow {...{ departmentName, state, role }} />
						)}
						<TenantUsersRow {...{ cardHolders, state, role }} />
						<MemoRow {...{ memo, state, role }} />
					</Table.Body>
				</Table.Root>
			</Form>
		</div>
	);
};
