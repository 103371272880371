import { DataTable, type DataTableProps } from '@/components/new/DataTable';
import { Link } from '@/components/new/Link';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { Image } from '@/components/ui/Image';
import { TextWithQuestionIcon } from '@/components/ui/TextWithQuestionIcon';
import {
	type PostPaidBillingFragment,
	PostPaidBillingPaymentStatusEnum,
} from '@/graphql';
import { CurrencyFormat, DateTimeFormat } from '@/lib/i18n';
import noDocuments from '@images/noDocuments.svg';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  max-width: 980px;
`;

type PaymentStatusTextProps = {
	paymentStatus: PostPaidBillingPaymentStatusEnum;
};

const PaymentStatusText = styled.span<PaymentStatusTextProps>`
  ${({ paymentStatus }) => {
		if (paymentStatus === PostPaidBillingPaymentStatusEnum.Failure) {
			return css`
        color: #ec4949;
      `;
		}
	}};
`;

const paymentStatusToString = (
	paymentStatus: PostPaidBillingPaymentStatusEnum,
): string | null => {
	switch (paymentStatus) {
		case PostPaidBillingPaymentStatusEnum.Estimating:
			return '支払待ち';
		case PostPaidBillingPaymentStatusEnum.Failure:
			return '未払い';
		case PostPaidBillingPaymentStatusEnum.InProgress:
			return '支払待ち';
		case PostPaidBillingPaymentStatusEnum.NotUsed:
			return null;
		case PostPaidBillingPaymentStatusEnum.Pending:
			return '支払待ち';
		case PostPaidBillingPaymentStatusEnum.Success:
			return '支払完了';
		case PostPaidBillingPaymentStatusEnum.Confirming:
			return '支払確認中';
	}
};

export type PostPayBillingsTableProps = Pick<
	DataTableProps<PostPaidBillingFragment>,
	'loading' | 'error' | 'items'
>;
export const PostPayBillingsTable = ({
	loading,
	error,
	items,
}: PostPayBillingsTableProps) => (
	<Wrapper>
		<DataTable
			loading={loading}
			error={error}
			items={items}
			noItemsIllustration={
				<Image src={noDocuments} alt="" width={250} height={200} />
			}
			noItemsMessage={<Text color="notes">利用履歴はありません</Text>}
			thead={
				<Table.Row>
					<Table.ColumnHeaderCell>利用月</Table.ColumnHeaderCell>
					<Table.ColumnHeaderCell justify="end">利用額</Table.ColumnHeaderCell>
					<Table.ColumnHeaderCell justify="end">
						ウォレット残高引落額
					</Table.ColumnHeaderCell>
					<Table.ColumnHeaderCell justify="end">
						引落確定金額
					</Table.ColumnHeaderCell>
					<Table.ColumnHeaderCell>引落日</Table.ColumnHeaderCell>
					<Table.ColumnHeaderCell>
						<TextWithQuestionIcon
							label="ステータス"
							content="引落結果の反映には4営業日かかります"
						/>
					</Table.ColumnHeaderCell>
					<Table.ColumnHeaderCell />
				</Table.Row>
			}
			renderItem={(item) => {
				// NOTE: 引落確定金額が0円以下かつ利用額、ウォレット残高引落額が0円の場合はリンクを無効にする
				const isDisabledLink =
					item.paymentStatus === PostPaidBillingPaymentStatusEnum.NotUsed &&
					item.originalAmount === 0 &&
					item.valueDepositedAmount === 0;

				return (
					<Table.Row>
						<Table.Cell width="118px" size="large">
							{DateTimeFormat.jpYearMonthJst.format(new Date(item.targetMonth))}
						</Table.Cell>
						<Table.Cell justify="end" width="176px" size="large">
							{/* 利用額 */}
							{CurrencyFormat.jp.format(item.originalAmount)}
						</Table.Cell>
						<Table.Cell justify="end" width="176px" size="large">
							{/* ウォレット残高引落楽 */}
							{CurrencyFormat.jp.format(item.valueDepositedAmount)}
						</Table.Cell>
						<Table.Cell justify="end" width="176px" size="large">
							{/* 引落確定金額 */}
							{CurrencyFormat.jp.format(item.amount)}
						</Table.Cell>
						<Table.Cell width="144px" size="large">
							{/* 引落日 */}
							{isDisabledLink || item.valueDepositedAmount < 0
								? ''
								: DateTimeFormat.jpYearMonthDayJst.format(
										new Date(item.dueDate),
									)}
						</Table.Cell>
						<Table.Cell width="128px" size="large">
							<PaymentStatusText paymentStatus={item.paymentStatus}>
								{paymentStatusToString(item.paymentStatus)}
							</PaymentStatusText>
						</Table.Cell>
						<Table.Cell width="64px" size="large">
							<Link
								href={{
									pathname: '/postpay/transactions/detail',
									query: { targetMonth: item.targetMonth },
								}}
								disabled={isDisabledLink}
							>
								明細
							</Link>
						</Table.Cell>
					</Table.Row>
				);
			}}
		/>
	</Wrapper>
);
