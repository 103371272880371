import { Head } from '@/components/head';
import { RegistrationButtons } from '@/components/ui/RegistrationButtons';
import { RegistrationIdentificationApp } from '@/components/ui/RegistrationIdentificationApp';
import { RegistrationLayout } from '@/components/ui/RegistrationLayout';
import { RegistrationNote } from '@/components/ui/RegistrationNote';
import { RegistrationTitle } from '@/components/ui/RegistrationTitle';

export const RegistrationIndividualIdentification = (): JSX.Element => {
	return (
		<RegistrationLayout>
			<Head title="本人確認" />
			<RegistrationTitle title="本人確認" center />
			<RegistrationNote center>
				マネーフォワード Pay for Businessアプリをダウンロードし、
				<br />
				本人確認書類（運転免許証、運転履歴経歴書、マイナンバーカード、特別永住者証明書、在留カード
				のうちいずれか1つ）とご自身の顔を、アプリ画面に表示される説明に沿って撮影してください。
			</RegistrationNote>
			<RegistrationIdentificationApp />
			<RegistrationButtons pattern="back" />
		</RegistrationLayout>
	);
};
