import { Head } from '@/components/head';
import { CurrentOfficeContext } from '@/contexts/CurrentOfficeProvider';
import { KycStatus, useCurrentOfficeIdQuery } from '@/graphql';
import { ErrorCode, useApiError } from '@/hooks/useApiError';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Buttons } from './Buttons';
import { Inputs } from './Inputs';
import { Introduction } from './Introduction';
import { Links } from './Links';
import type { FormData } from './types';

const Layout = styled.div`
  padding-top: 32px;
  padding-bottom: 62px; /* footer height 30px + spacing */
  width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const Block = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-content: start;
  align-items: center;
  margin-bottom: 24px;
  padding: 16px;
  border-radius: 4px;
  background-color: #fff;
`;

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 32px 0;
`;

export const RegistrationTerms = (): JSX.Element => {
	const router = useRouter();
	const { handleQueryError } = useApiError();
	// 規約同意画面でsetされている情報
	// NOTE: The office isEkycApproved is true is treated as which has already activated.
	const { name, tenantType, ekycStatus } = useContext(CurrentOfficeContext);
	const isEkycApproved = ekycStatus === KycStatus.Approval;

	const isVisibleRegistration = !!tenantType && !!name;
	useCurrentOfficeIdQuery({
		// 規約同意画面でcurrentOfficeが取れた場合は
		// すでに同意済みなのでリダイレクト
		onCompleted: () => {
			router.push('/login/redirect');
		},
		onError: (e) => {
			if (e.graphQLErrors[0].extensions.code !== ErrorCode.NotActivatedError) {
				// 利用規約以外のエラーはハンドリング
				handleQueryError(e);
			}
			// 規約同意画面の表示に必要な情報がなければ、事業者選択をしなおしてもらう
			if (!isVisibleRegistration) router.push('/offices');
		},
	});

	const methods = useForm<FormData>({
		defaultValues: {
			isAccountManager: isEkycApproved,
		},
	});

	return (
		<Layout>
			<Head title="利用規約等への同意" />
			<Wrapper>
				{!isEkycApproved && <Introduction tenantType={tenantType} />}
				<FormProvider {...methods}>
					<Block>
						<Links />
						{!isEkycApproved && <Inputs />}
					</Block>
					<Buttons />
				</FormProvider>
			</Wrapper>
		</Layout>
	);
};
