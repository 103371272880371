import {
	type UpdateNotificationSettingsMutationFn,
	type UpdateNotificationSettingsMutationOptions,
	type UpdateNotificationSettingsMutationResult,
	type UpdateNotificationSettingsMutationVariables,
	useUpdateNotificationSettingsMutation,
} from '@/graphql';
import { useApiError } from '@/hooks/useApiError';

export type UseUpdateNotifiationsReturn = [
	(
		notificationSettings: UpdateNotificationSettingsMutationVariables['notificationSettings'],
	) => ReturnType<UpdateNotificationSettingsMutationFn>,
	UpdateNotificationSettingsMutationResult,
];
export const useUpdateNotifications = (
	options: UpdateNotificationSettingsMutationOptions = {},
): UseUpdateNotifiationsReturn => {
	const { handleMutationError } = useApiError();
	const [update, mutationResult] = useUpdateNotificationSettingsMutation({
		onError: handleMutationError,
		...options,
	});

	return [
		(notificationSettings) => update({ variables: { notificationSettings } }),
		mutationResult,
	];
};
