import { Loading } from '@/components/screen/loading';
import { setOauthStateCookie } from '@/lib/cookie';
import { getSignUpUrl } from '@/lib/mfid';
import { generateNonceString } from '@/lib/string';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const Register = (): JSX.Element => {
	const router = useRouter();

	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	useEffect(() => {
		if (!router.isReady) return;

		const randomState = generateNonceString(32);
		setOauthStateCookie(randomState);

		router.push(getSignUpUrl({ state: randomState }));
	}, []);

	return <Loading />;
};
