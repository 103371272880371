import { AssetsAmountEnum } from '@/graphql';
import { yup } from '@/lib/yup';

export type AssetsAmountSchemaData = yup.Asserts<typeof assetsAmountSchema>;

export const assetsAmountSchema = yup.object({
	assetsAmount: yup
		.mixed<AssetsAmountEnum>()
		.oneOf(Object.values(AssetsAmountEnum), '選択してください')
		.required('選択してください'),
});
