import { Radio } from '@/components/new/Radio';
import { Table } from '@/components/new/Table';
import { FormError } from '@/components/ui/FormError';
import { CardTypeCategory } from '@/graphql';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import type { FormValues } from '../../../schema';

const RadioGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LockedMessage = styled.p`
  color: #ec4949;
  margin: 0;
`;

export type CardTypeTableProps = {
	isLocked: boolean;
	hasInsufficientBalance: boolean;
};
export const CardTypeTable = ({
	isLocked,
	hasInsufficientBalance,
}: CardTypeTableProps): JSX.Element => {
	const { register } = useFormContext<FormValues>();

	return (
		<Table.Root>
			<Table.Head>
				<Table.Row>
					<Table.ColumnHeaderCell>
						発行するカードの形状を選択してください
					</Table.ColumnHeaderCell>
				</Table.Row>
			</Table.Head>
			<Table.Body>
				<Table.Row>
					<Table.Cell size="large">
						<RadioGroupWrapper>
							リアルカード申込時、発行手数料990円がウォレット残高から引き落とされます。
							<Radio
								{...register('cardType')}
								value={CardTypeCategory.Virtual}
								disabled={isLocked}
							>
								バーチャルカード
							</Radio>
							<Radio
								{...register('cardType')}
								value={CardTypeCategory.Physical}
								disabled={hasInsufficientBalance || isLocked}
							>
								リアルカード（発行手数料 990円）
								{hasInsufficientBalance && (
									<FormError>残高が足りません</FormError>
								)}
							</Radio>
							{isLocked && (
								<LockedMessage>
									お客さまのウォレットは利用が制限されており、ただいま取引・操作できない状態にあります。詳しくはメールをご確認ください。
								</LockedMessage>
							)}
						</RadioGroupWrapper>
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Table.Root>
	);
};
