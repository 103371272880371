import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { useFormContext } from 'react-hook-form';
import type { RepresentativeLastNameEnData } from './schema';

export const RepresentativeLastNameEnInput = (): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<RepresentativeLastNameEnData>();

	return (
		<div>
			<TextField
				aria-label="代表者姓（英語表記）"
				{...register('representativeLastNameEn')}
				placeholder="例）Yamada"
			/>
			{errors.representativeLastNameEn && (
				<FormError>{errors.representativeLastNameEn.message}</FormError>
			)}
		</div>
	);
};
