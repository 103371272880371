import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Button } from '@/components/new/Button';
import { SelectBox } from '@/components/new/SelectBox';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError/FormError';
import { InputGroup } from '@/components/ui/inputs/InputGroup/InputGroup';
import { useAddressesLazyQuery } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { PrefectureSelectOptions } from '@/lib/prefecture';
import type { AddressData } from './schema';

const AddressWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 6px;
`;

export const RepresentativeAddressInputs = (): JSX.Element => {
	const {
		register,
		formState: { errors },
		control,
		getValues,
		setValue,
		trigger,
		watch,
	} = useFormContext<AddressData>();
	const { handleQueryError } = useApiError();
	const [search] = useAddressesLazyQuery({
		onError: handleQueryError,
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	const handleAddressSearch = useCallback(async () => {
		const valid = await trigger('representativePostalCode');

		if (!valid) return;

		const postalCode = getValues('representativePostalCode');
		const res = await search({
			variables: {
				postcode: postalCode,
			},
		});
		if (res.data && res.data.addresses.length > 0) {
			// REVIEW: 2つ以上ある場合はモーダルを表示
			const address = res.data.addresses[0];
			setValue('representativePrefectureCode', address.prefectureCode);
			setValue('representativeCity', address.city);
			setValue('representativeStreet', address.town + (address.street ?? ''));
			setValue('representativeBuildingName', '');
		}
	}, [getValues('representativePostalCode')]);

	return (
		<AddressWrapper>
			<InputGroup>
				<div>
					<TextField
						aria-label="郵便番号"
						{...register('representativePostalCode')}
						placeholder="郵便番号"
					/>
					{errors.representativePostalCode && (
						<FormError>{errors.representativePostalCode.message}</FormError>
					)}
				</div>
				<div>
					<Button type="button" variant="outline" onClick={handleAddressSearch}>
						郵便番号から住所を表示
					</Button>
				</div>
			</InputGroup>
			<InputGroup>
				<div>
					<Controller
						control={control}
						name={'representativePrefectureCode'}
						render={({ field }) => (
							<SelectBox
								{...field}
								aria-label="都道府県"
								options={PrefectureSelectOptions}
								// @ts-ignore: type of [Option|undefined] but has to accept null to reset
								value={
									watch('representativePrefectureCode')
										? PrefectureSelectOptions.find(
												(option) => option.value === field.value,
											)
										: null
								}
								onValueChange={(value) => field.onChange(value)}
								placeholder="都道府県"
								selectedTestId="selected-representativePrefectureCode"
								ariaControlsId="representativePrefectureCode"
							/>
						)}
					/>
					{errors.representativePrefectureCode && (
						<FormError>{errors.representativePrefectureCode.message}</FormError>
					)}
				</div>
				<div>
					<TextField
						aria-label="市区町村"
						{...register('representativeCity')}
						placeholder="市区町村"
					/>
					{errors.representativeCity && (
						<FormError>{errors.representativeCity.message}</FormError>
					)}
				</div>
			</InputGroup>
			<div>
				<TextField
					aria-label="町名・番地"
					{...register('representativeStreet')}
					placeholder="町名・番地"
				/>
				{errors.representativeStreet && (
					<FormError>{errors.representativeStreet.message}</FormError>
				)}
			</div>
			<div>
				<TextField
					aria-label="建物名・部屋番号（任意）"
					{...register('representativeBuildingName')}
					placeholder="建物名・部屋番号（任意）"
				/>
				{errors.representativeBuildingName && (
					<FormError>{errors.representativeBuildingName.message}</FormError>
				)}
			</div>
		</AddressWrapper>
	);
};
