import { Button } from '@/components/new/Button';
import { CircularProgress } from '@/components/new/CircularProgress';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { useAsyncCsvExportQuery } from '@/graphql';
import { ErrorCode, useApiError } from '@/hooks/useApiError';
import { getString } from '@/lib/query';
import { useRouter } from 'next/router';
import { useAsyncExport } from './hooks';
import styles from './styles.module.css';

const Loading = () => (
	<div className={styles.loading}>
		<CircularProgress size="large" />
	</div>
);

export const WalletExportsContent = (): JSX.Element => {
	const { handleQueryError } = useApiError();
	const router = useRouter();
	const id = getString(router.query.id);

	const { data, loading: queryLoading } = useAsyncCsvExportQuery({
		variables: {
			id: id ?? '',
		},
		onError: (apolloError) => {
			const { graphQLErrors } = apolloError;
			const { extensions } = graphQLErrors[0];

			if (extensions.code === ErrorCode.AuthorizationError) {
				router.push('/403');
			} else if (extensions.code === ErrorCode.NotFound) {
				router.push('/404');
			} else {
				handleQueryError(apolloError);
			}
		},
	});

	const [exportAsyncronously, { loading: mutationLoading }] = useAsyncExport();

	return (
		<div className={styles.tableLayout}>
			<GlobalMessage />
			{queryLoading ? (
				<Loading />
			) : (
				<Table.Root aria-label="CSVダウンロード">
					<Table.Head>
						<Table.Row>
							<Table.ColumnHeaderCell>CSVダウンロード</Table.ColumnHeaderCell>
						</Table.Row>
					</Table.Head>
					<Table.Body>
						<Table.Row>
							<Table.Cell>
								<Text>{data?.asyncCsvExport.fileName}</Text>
								<div className={styles.buttonWrapper}>
									<Button
										type="button"
										onClick={() => {
											exportAsyncronously({
												id: id ?? '',
												fileName: data?.asyncCsvExport.fileName ?? 'export',
											});
										}}
										loading={mutationLoading}
										disabled={mutationLoading}
									>
										ダウンロード
									</Button>
								</div>
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table.Root>
			)}
		</div>
	);
};
