import { Button } from '@/components/new/Button';
import { Link } from '@/components/new/Link';
import { Table } from '@/components/new/Table';
import { RegistrationTable } from '@/components/ui/RegistrationTable';
import { RegistrationTableTextRow } from '@/components/ui/RegistrationTableTextRow';
import { useRegistrationPageState } from '@/contexts/RegistrationProvider';
import type { Corporation } from '@/graphql';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import styled from 'styled-components';

const InputCell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Address = styled.div`
  width: 300px;
`;

type Props = {
	results: Corporation[];
	resultTotalCount: number;
};

export const Result = ({ results, resultTotalCount }: Props): JSX.Element => {
	const router = useRouter();
	const { setFieldsState } = useRegistrationPageState();

	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	const onSelect = useCallback((corporation: Corporation) => {
		setFieldsState({ selectedCorporation: corporation });
		router.push('/registration/corporate/applicant');
	}, []);

	return (
		<RegistrationTable header="検索結果">
			{resultTotalCount === 0 ? (
				<Table.Row>
					<Table.Cell>
						<p>該当する法人が存在しません。</p>
						<p>
							検索しても見つからない場合は、
							<Link href="/registration/corporate/applicant">
								法人情報を手動で入力
							</Link>
							してください。
						</p>
					</Table.Cell>
				</Table.Row>
			) : (
				<>
					<Table.Row>
						<Table.Cell colSpan={2}>
							{resultTotalCount >= 101
								? '検索結果を100件まで表示しています。見つからない場合は所在地で絞り込んでください。'
								: `${resultTotalCount}件見つかりました`}
						</Table.Cell>
					</Table.Row>

					{results.map((corporation, index) => (
						<RegistrationTableTextRow
							key={`${corporation.corporateNumber}-${index}`}
							title={corporation.name}
						>
							<InputCell>
								<Address>
									{corporation.prefectureName}
									{corporation.cityName}&nbsp;
									{corporation.streetNumber}
								</Address>

								<Button
									aria-label={`${corporation.name}を選択`}
									variant="outline"
									size="small"
									onClick={() => onSelect(corporation)}
								>
									選択
								</Button>
							</InputCell>
						</RegistrationTableTextRow>
					))}
				</>
			)}
		</RegistrationTable>
	);
};
