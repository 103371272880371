import { Link } from '@/components/new/Link';
import { useBannersQuery } from '@/graphql';
import styled from 'styled-components';

const BannerList = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 30px;
`;

const Item = styled.li`
  line-height: 1;
`;

const StyledImg = styled.img`
  display: block;
  width: 280px;
  height: 100px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const BannerArea = (): JSX.Element | null => {
	const { data, loading, error } = useBannersQuery({
		onError: () => {
			// do nothing
		},
	});

	if (!data || loading || error) return null;

	// API returns visible and ordered banners by priority
	const items = data.banners.slice(0, 3);

	if (items.length > 0) {
		return (
			<BannerList>
				{items.map((item) => (
					<Item key={item.id}>
						<Link href={item.destinationUrl ?? ''} target="_blank">
							{/* next/image can't use an external image url in prod enviroments */}
							<StyledImg src={item.s3UrlWeb} alt={item.alternateText} />
						</Link>
					</Item>
				))}
			</BannerList>
		);
	}
	return null;
};
