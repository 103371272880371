import { Head } from '@/components/head';
import { PageLayout } from '@/components/screen';
import styled from 'styled-components';
import { useBackHref } from '../shared/hooks/useBackHref';

const Box = styled.div`
  max-width: 800px;
`;

export const Layout: React.FC = ({ children }) => {
	const backHref = useBackHref();

	return (
		<PageLayout title="招待" backHref={backHref} backLinkText="ユーザー">
			<Head title="ユーザー招待" />
			<Box>{children}</Box>
		</PageLayout>
	);
};
