import { Button } from '@/components/new/Button';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { LimitErrorMessage } from '@/components/ui/LimitErrorMessage';
import {
	CardTypeCategory,
	Prefecture,
	useCreateCardRequestMutation,
} from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { useGlobalMessage } from '@/hooks/useGlobalMessage';
import { useLogger } from '@/hooks/useLogger';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import { useTenantType } from '@/hooks/useTenantType';
import { useCallback } from 'react';
import styled from 'styled-components';
import { useStepper } from '../../New';
import type { FormValues } from '../schema';
import { Tables } from './components/Tables';

export type Step2Props = {
	savedValues: FormValues;
};

const ButtonWrapper = styled.div({
	textAlign: 'center',
	'> button': {
		width: '160px',

		'@media (max-width: 767px)': {
			width: '120px',
		},
	},
});

const BoxWrapper = styled.div({
	display: 'inline-block',
	marginRight: '16px',

	'> button': {
		width: '160px',

		'@media (max-width: 767px)': {
			width: '120px',
		},
	},
});

export const Step2 = ({ savedValues }: Step2Props): JSX.Element => {
	const { isCorporate } = useTenantType();
	const { handleMutationError } = useApiError();
	const { setErrorMessage } = useGlobalMessage();
	const { nextStep, previousStep } = useStepper();
	const { logError } = useLogger();
	const [
		cardRequest,
		{ loading: cardRequestLoading, error: cardRequestError },
	] = useCreateCardRequestMutation({
		onCompleted: (data) => {
			if (!data.createCardRequest?.success) {
				const errorMessage = 'カード追加発行API実行に失敗しました';
				logError(errorMessage, savedValues);
				setErrorMessage(errorMessage);
				return;
			}
			nextStep();
		},
		onError: (error) => {
			if (
				error?.graphQLErrors[0]?.extensions?.code ===
				'CREATABLE_CARD_LIMIT_EXCEED_ERROR'
			) {
				logError(error.message, error?.graphQLErrors[0]?.extensions?.code);
			} else {
				handleMutationError(error);
			}
		},
	});

	const resetMessageAndNotification = useResetMessageAndNotification();

	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	const handleApply = useCallback(() => {
		resetMessageAndNotification();

		if (cardRequestLoading) return;

		const {
			tenantUsers,
			destinationName,
			buildingName,
			city,
			destinationDepartmentName,
			postalCode,
			prefectureCode,
			street,
			tradeName,
			pin,
			cardType,
			color,
			...values
		} = savedValues;
		cardRequest({
			variables: {
				...values,
				cardType,
				color: cardType === CardTypeCategory.Physical ? color : null,
				destinationAddress:
					isCorporate && cardType === CardTypeCategory.Physical
						? {
								buildingName: buildingName,
								city: city || '',
								destinationDepartmentName: destinationDepartmentName,
								postalCode: postalCode || '',
								prefectureCode: prefectureCode || Prefecture.Tokyo,
								street: street || '',
								tradeName: tradeName,
							}
						: null,
				destinationName:
					isCorporate && cardType === CardTypeCategory.Physical
						? destinationName
						: null,
				tenantUserUids: tenantUsers.map((user) => user.value),
				pin: cardType === CardTypeCategory.Physical ? pin : null,
			},
		});
	}, [cardRequestLoading]);

	const handlePreviousStep = () => {
		resetMessageAndNotification();
		previousStep();
	};

	const isExceedError =
		cardRequestError?.graphQLErrors[0]?.extensions?.code ===
		'CREATABLE_CARD_LIMIT_EXCEED_ERROR';

	return (
		<>
			{isExceedError ? <LimitErrorMessage /> : <GlobalMessage />}
			<Tables {...{ isCorporate, savedValues }} />
			<ButtonWrapper>
				<BoxWrapper>
					<Button
						variant="outline"
						size="large"
						type="button"
						onClick={handlePreviousStep}
					>
						修正する
					</Button>
				</BoxWrapper>
				<Button
					size="large"
					type="button"
					disabled={isExceedError}
					onClick={handleApply}
				>
					カード申込
				</Button>
			</ButtonWrapper>
		</>
	);
};
