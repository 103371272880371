import ImageInvalid from '@/assets/images/verificationInvalid.svg';
import ImageValid from '@/assets/images/verificationValid.svg';
import { Head } from '@/components/head';
import { Loading } from '@/components/screen/loading';
import { Image } from '@/components/ui/Image';
import type { VerifyAccountManagerMutationResult } from '@/graphql';
import styled from 'styled-components';

const StyledCenterLayout = styled.div`
  width: 600px;
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 80px;
`;

const Heading = styled.h1`
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 0;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  * {
    text-align: center;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const Stack = styled.div`
  > * + * {
    margin-top: 16px;
  }
`;

const Box = styled.div`
  max-width: 800px;
`;

const Valid = (): JSX.Element => (
	<FlexWrapper>
		<Image src={ImageValid} alt="" width={146} height={126} />
		<Heading>取引担当者認証が完了しました</Heading>
		<Stack>
			<p>
				引き続き、申請内容の審査を行っております。
				<br />
				完了次第メールにてご連絡いたしますので、今しばらくお待ちください。
			</p>
		</Stack>
	</FlexWrapper>
);

const Invalid = (): JSX.Element => (
	<FlexWrapper>
		<Image src={ImageInvalid} alt="" width={146} height={126} />
		<Heading>無効なリンクです</Heading>
		<Stack>
			<p>
				すでに認証済みか、有効期限切れのリンクです。
				<br />
				有効期限切れの場合、オペレーターからの電話確認をお待ちください。
			</p>
			{/* TODO: サポートページのURLが決まったら挿入する */}
			{/* <div>
        <Link href="">サポートサイトへ</Link>
      </div> */}
		</Stack>
	</FlexWrapper>
);

export type FeedbackProps = {
	res: VerifyAccountManagerMutationResult['data'];
	loading?: boolean;
};

export const Feedback = ({
	res: data,
	loading,
}: FeedbackProps): JSX.Element => {
	if (loading) return <Loading />;

	return (
		<StyledCenterLayout>
			<Head title="取引担当者認証" />
			<Box>{data?.verifyAccountManager?.success ? <Valid /> : <Invalid />}</Box>
		</StyledCenterLayout>
	);
};
