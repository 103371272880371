import { Button } from '@/components/new/Button';
import { Dialog } from '@/components/new/Dialog';
import type { GmoTransferAccount } from '@/graphql';
import styled from 'styled-components';
import styles from './styles.module.css';

type GmoTransferAccountSlim = Pick<
	GmoTransferAccount,
	'accountNumber' | 'branchNameKana' | 'holderNameKana'
>;

type Props = {
	accountDetails?: GmoTransferAccountSlim | null;
	specifiedRemitterName?: string;
};

const ContentWrapper = styled.div`
  text-align: center;
  padding: 24px 0 40px;
`;

const Heading = styled.h1`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  line-height: 1;
`;

const AccountDetails = styled.p`
  background-color: #eff1f4;
  font-size: 16px;
  font-weight: 700;
  margin: 24px 16px 16px;
  padding: 16px;
  line-height: 1.6;
  span {
    margin: 0 2px;
  }
`;

const NoteWrapper = styled.div`
  text-align: left;
  padding: 0 20px;
`;

const NoteHeading = styled.p`
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 0;
`;

const Note = styled.p`
  font-size: 13px;
  margin: 0;
  line-height: 1.6;
  margin-top: 4px;
`;

const Link = styled.a`
  font-size: 13px;
  text-decoration: none;
  color: #3b7de9;
  &:hover {
    opacity: 0.8;
  }
`;

const AccountNote = styled.p`
  font-size: 13px;
  font-weight: normal;
  margin: 16px 0 0;
  line-height: 20px;
  color: #666;
`;

export const AccountDetailsModal = ({
	accountDetails,
	specifiedRemitterName,
}: Props): JSX.Element => {
	return (
		<Dialog.Window
			size="medium"
			onInteractOutside={(e) => e.preventDefault()}
			onEscapeKeyDown={(e) => e.preventDefault()}
			onPointerDownOutside={(e) => e.preventDefault()}
		>
			<Dialog.Contents>
				<ContentWrapper>
					<Heading>入金用口座</Heading>
					{accountDetails ? (
						<AccountDetails>
							<span>GMOあおぞらネット銀行</span>
							<span>{accountDetails.branchNameKana}支店</span>
							<br />
							<span>普通</span>
							<span>{accountDetails.accountNumber}</span>
							<span>{accountDetails.holderNameKana}</span>
							<br />
							<AccountNote>
								振込依頼人名には必ず以下を含めてご入金ください
							</AccountNote>
							{specifiedRemitterName ?? ''}
						</AccountDetails>
					) : (
						<AccountDetails data-testid="accountDetailsError">-</AccountDetails>
					)}
					<NoteWrapper>
						<NoteHeading>ご注意ください</NoteHeading>
						<Note>
							・月中にチャージした場合、ウォレット残高に加算され、月が変わるタイミングで未清算金額と相殺されます。ウォレット残高の払い戻し（返金）はできません。
						</Note>
						<Note>
							・補助金や助成金の対象経費のお支払いをウォレット残高から行った場合、対象経費の支払いとして認められない可能性がございます。
							<Link
								href="https://biz.moneyforward.com/support/biz-pay/faq/transaction/f113.html"
								target="_blank"
							>
								詳しくはこちら
							</Link>
						</Note>
					</NoteWrapper>
				</ContentWrapper>
			</Dialog.Contents>
			<Dialog.Footer>
				<div className={styles.footer}>
					<Dialog.Close asChild>
						<Button type="button" variant="outline" size="large">
							閉じる
						</Button>
					</Dialog.Close>
				</div>
			</Dialog.Footer>
		</Dialog.Window>
	);
};
