import { Head } from '@/components/head';
import { RegistrationFirstCardDesign } from '@/components/ui/RegistrationFirstCardDesign';
import { RegistrationFirstCardLimits } from '@/components/ui/RegistrationFirstCardLimits';
import { RegistrationFirstCardOptions } from '@/components/ui/RegistrationFirstCardOptions';
import { RegistrationFirstCardPlans } from '@/components/ui/RegistrationFirstCardPlans';
import { RegistrationFirstCardSettings } from '@/components/ui/RegistrationFirstCardSettings';
import { RegistrationLayout } from '@/components/ui/RegistrationLayout';
import { RegistrationNote } from '@/components/ui/RegistrationNote';
import { RegistrationTitle } from '@/components/ui/RegistrationTitle';
import { RegistrationFirstCardForm } from '../../shared/FirstCard';

export const RegistrationIndividualFirstCard = (): JSX.Element => (
	<RegistrationLayout>
		<Head title="新規カード設定" />
		<RegistrationTitle title="新規カード設定" center />
		<RegistrationNote center>
			新しく作成するカードの情報を入力してください。
			<br />
			2枚目以降のカードは利用開始後に発行することができます。
		</RegistrationNote>
		<RegistrationFirstCardForm>
			<RegistrationFirstCardDesign />
			<RegistrationFirstCardSettings />
			<RegistrationFirstCardLimits />
			<RegistrationFirstCardOptions />
			<RegistrationFirstCardPlans />
		</RegistrationFirstCardForm>
	</RegistrationLayout>
);
