import { FinancialAssetsEnum } from '@/graphql';
import { yup } from '@/lib/yup';

export type FinancialAssetsSchemaData = yup.Asserts<
	typeof financialAssetsSchema
>;

export const financialAssetsSchema = yup.object({
	financialAssets: yup
		.mixed<FinancialAssetsEnum>()
		.oneOf(Object.values(FinancialAssetsEnum), '選択してください')
		.required('選択してください'),
});
