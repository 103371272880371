import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import type { XardCardRequestInput } from '@/graphql';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

const NoteAndErrorLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const CardTable = (): JSX.Element => {
	const { field, fieldState } = useController<
		Pick<XardCardRequestInput, 'name'>
	>({
		name: 'name',
	});

	return (
		<Table.Root>
			<Table.Head>
				<Table.Row>
					<Table.ColumnHeaderCell colSpan={2}>
						カード設定
					</Table.ColumnHeaderCell>
				</Table.Row>
			</Table.Head>
			<Table.Body>
				<Table.Row>
					<Table.RowHeaderCell width="200px">カード名義人</Table.RowHeaderCell>
					<Table.Cell>
						<TextField
							aria-label="name"
							placeholder="例）TARO YAMADA"
							{...field}
							onChange={(e) => field.onChange(e.target.value.toUpperCase())}
							value={field.value ?? ''}
						/>
						<NoteAndErrorLayout>
							<Text color="notes">半角英数字、半角スペースが使用可</Text>
							{fieldState.error && (
								<FormError>{fieldState.error.message}</FormError>
							)}
						</NoteAndErrorLayout>
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Table.Root>
	);
};
