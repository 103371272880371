import { IndustryEnum } from '@/graphql';
import { yup } from '@/lib/yup';

export type IndustrySchemaData = yup.Asserts<typeof industrySchema>;

export const industrySchema = yup.object({
	industry: yup
		.mixed<IndustryEnum>()
		.oneOf(Object.values(IndustryEnum), '選択してください')
		.required('選択してください'),
	industryText: yup.string().when('industry', {
		is: IndustryEnum.IndustriesUnableToClassify,
		// biome-ignore lint/suspicious/noThenProperty: TODO
		then: (schema) =>
			schema.max(128, '128文字以内で入力してください').required(),
		otherwise: (schema) => schema.nullable().default(null),
	}),
});
