import { CardTerminatedReasonEnum, type TerminateCardInput } from '@/graphql';
import { yup } from '@/lib/yup';

export type FormValues = Pick<
	TerminateCardInput,
	'terminatedReason' | 'otherTerminatedReason'
>;

export const schema: yup.ObjectSchema<FormValues> = yup.object({
	terminatedReason: yup
		.mixed<CardTerminatedReasonEnum>()
		.oneOf(Object.values(CardTerminatedReasonEnum), '選択してください')
		.required('選択してください'),
	otherTerminatedReason: yup.string().when('terminatedReason', {
		is: CardTerminatedReasonEnum.Other,
		// biome-ignore lint/suspicious/noThenProperty: TODO
		then: (schema) =>
			schema.max(100, '100文字以内で入力してください').required(),
		otherwise: (schema) => schema.nullable().default(null),
	}),
});
