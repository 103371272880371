import { SelectBox } from '@/components/new/SelectBox';
import { FormError } from '@/components/ui/FormError';
import { UserIndustrySelectOptions } from '@/lib/userIndustry';
import { Controller, useFormContext } from 'react-hook-form';
import type { UserIndustrySchemaData } from './schema';

export const UserIndustryInput = (): JSX.Element => {
	const {
		control,
		formState: { errors },
	} = useFormContext<UserIndustrySchemaData>();

	return (
		<>
			<Controller
				control={control}
				name="userIndustry"
				render={({ field }) => (
					<SelectBox
						{...field}
						aria-label="主な事業内容"
						placeholder="選択してください"
						options={UserIndustrySelectOptions}
						value={UserIndustrySelectOptions.find(
							(option) => option.value === field.value,
						)}
						onValueChange={(value) => field.onChange(value)}
						selectedTestId="selected-userIndustry"
						ariaControlsId="userIndustry"
					/>
				)}
			/>
			{errors.userIndustry && (
				<FormError>{errors.userIndustry.message}</FormError>
			)}
		</>
	);
};
