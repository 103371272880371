import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { useAmountField } from '@/hooks/useAmountField';
import {
	Controller,
	type ControllerRenderProps,
	type FieldPath,
	type FieldValues,
	useFormContext,
} from 'react-hook-form';
import styled from 'styled-components';
import type { CardLimitsData } from './schema';

const FieldWrapper = styled.div`
  display: inline-block;
  width: 40%;
  margin-right: 2%;

  @media (max-width: 767px) {
    width: 80%;
  }
`;

const AmountField = <TF extends FieldValues, TN extends FieldPath<TF>>({
	field,
}: {
	field: ControllerRenderProps<TF, TN>;
}) => {
	const amountField = useAmountField(field);
	return (
		<FieldWrapper>
			<TextField
				ref={field.ref}
				name={field.name}
				{...amountField}
				aria-label="maximumPaymentLimit"
			/>
		</FieldWrapper>
	);
};

export const MaximumPaymentLimitInput = (): JSX.Element => {
	const {
		formState: { errors },
		control,
	} = useFormContext<CardLimitsData>();

	return (
		<>
			<Controller
				control={control}
				name="maximumPaymentLimit"
				render={({ field }) => {
					return <AmountField field={field} />;
				}}
			/>
			円
			{errors.maximumPaymentLimit && (
				<section data-testid="maximumPaymentLimitError">
					<FormError>{errors.maximumPaymentLimit.message}</FormError>
				</section>
			)}
		</>
	);
};
