import {
	CorporateRegistrationImagesDocument,
	type CorporateRegistrationImagesQuery,
	type CorporateRegistrationImagesQueryVariables,
	type DeleteCorporateRegistrationImageMutationVariables,
	useDeleteCorporateRegistrationImageMutation,
} from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { useHandleUnknownError } from '@/hooks/useHandleUnknownError';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import { produce } from 'immer';

export type UseDeleteCorporateRegistrationProps =
	DeleteCorporateRegistrationImageMutationVariables;
export type UseDeleteCorporationRegistrationReturn = [
	VoidFunction,
	{ loading: boolean },
];
export const useDeleteCorporateRegistration = ({
	imageUuid,
}: UseDeleteCorporateRegistrationProps): UseDeleteCorporationRegistrationReturn => {
	const handleUnkownError = useHandleUnknownError();
	const { handleMutationError } = useApiError();
	const resetMessageAndNotification = useResetMessageAndNotification();
	const [deleteMutation, { loading }] =
		useDeleteCorporateRegistrationImageMutation({
			variables: {
				imageUuid,
			},
			onError: (error) => handleMutationError(error),
			update(cache, { data: mutationResult }) {
				cache.updateQuery<
					CorporateRegistrationImagesQuery,
					CorporateRegistrationImagesQueryVariables
				>({ query: CorporateRegistrationImagesDocument }, (previousResult) =>
					produce(previousResult, (draft) => {
						try {
							const corporateIdentification =
								draft?.currentOffice.identificationVerificationRequest
									?.corporateIdentification;
							const updatedCorporateRegistrationImages =
								mutationResult?.deleteCorporateRegistrationImage
									?.corporateRegistrationImages;

							if (
								!corporateIdentification ||
								!updatedCorporateRegistrationImages
							) {
								const error = new Error(
									'unsuccessful CorporateRegistrationImages update query error',
								);
								error.name = 'UpdateQueryError';

								throw error;
							}

							corporateIdentification.corporateRegistration = {
								corporateRegistrationImages: updatedCorporateRegistrationImages,
							};
						} catch (error) {
							handleUnkownError(error);
						}
					}),
				);
			},
		});

	const deleteCorporateRegistration = () => {
		if (loading) return;

		const confirmed = confirm('削除してもよろしいですか？');
		if (confirmed) {
			resetMessageAndNotification();
			deleteMutation();
		}
	};

	return [deleteCorporateRegistration, { loading }];
};
