import { Table } from '@/components/new/Table';
import { ShippingMethodEnum } from '@/graphql';

export type ShippingMethodTableProps = {
	shippingMethod: ShippingMethodEnum;
	isAutoActivated: boolean;
};

export const ShippingMethodTable = ({
	shippingMethod,
	isAutoActivated,
}: ShippingMethodTableProps): JSX.Element => {
	const renderShippingMethodAndActivation = (
		shippingMethod: ShippingMethodEnum,
		isAutoActivated: boolean,
	): string => {
		const card = isAutoActivated ? '有効化済カード' : '未有効化カード';
		switch (shippingMethod) {
			case ShippingMethodEnum.Registered:
				return `転送不要 簡易書留 / ${card}でのお届け`;
			case ShippingMethodEnum.Regular:
				return `普通郵便 / ${card}でのお届け`;
		}
	};

	return (
		<Table.Root aria-label="shippingMethod">
			<Table.Head>
				<Table.Row>
					<Table.ColumnHeaderCell colSpan={2}>
						お届け方法
					</Table.ColumnHeaderCell>
				</Table.Row>
			</Table.Head>
			<Table.Body>
				<Table.Row>
					<Table.Cell>
						{renderShippingMethodAndActivation(shippingMethod, isAutoActivated)}
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Table.Root>
	);
};
