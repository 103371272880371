import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { RegistrationAlert } from '@/components/ui/RegistrationAlert';
import { RegistrationButtons } from '@/components/ui/RegistrationButtons';
import {
	IndividualIdentificationDocument,
	type IndividualIdentificationQuery,
	PurposeEnum,
	useUpdateIndividualIdentificationMutation,
} from '@/graphql';
import { useGoToStepsOrConfirmation } from '@/hooks';
import { useApiError } from '@/hooks/useApiError';
import { transformQueryResult } from '@/lib/transform';
import { yupResolver } from '@/lib/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { FormDetails } from './FormDetails';
import { type FormData, schema } from './types';

type Props = {
	data?: IndividualIdentificationQuery;
};

export const Form = ({ data }: Props): JSX.Element => {
	const { handleMutationError } = useApiError();
	const goToStepsOrConfirmation = useGoToStepsOrConfirmation();
	const [saveInfo, { loading }] = useUpdateIndividualIdentificationMutation({
		refetchQueries: [{ query: IndividualIdentificationDocument }],
		onCompleted: () => goToStepsOrConfirmation(),
		onError: (error) => handleMutationError(error),
	});
	const identificationPurposes =
		data?.currentOffice?.identificationVerificationRequest
			?.identificationPurposes;

	const userIdentification =
		data?.currentOffice?.identificationVerificationRequest
			?.individualIdentification?.userIdentification;
	const usagePurposes =
		data?.currentOffice?.identificationVerificationRequest?.usagePurposes ?? [];

	const applicant = {
		...userIdentification,
		...(userIdentification && {
			birthDate: transformQueryResult.iso8601Date(userIdentification.birthDate),
			postalCode: transformQueryResult.postalCode(
				userIdentification.postalCode,
			),
		}),
		firstNameEn: userIdentification?.firstNameEn ?? '',
		lastNameEn: userIdentification?.lastNameEn ?? '',
		middleNameEn: userIdentification?.middleNameEn ?? '',
		phoneNumber: userIdentification?.phoneNumber ?? '',
		income: userIdentification?.income ?? undefined,
		financialAssets: userIdentification?.financialAssets ?? undefined,
		userIndustry: userIdentification?.industry ?? undefined,
		usagePurposes: usagePurposes.map((item) => item.purpose),
		nationalityId: userIdentification?.nationality?.id ?? 'JP',
		residentialStatus: userIdentification?.residentialStatus ?? undefined,
		residencePeriodEndDate:
			userIdentification?.residencePeriodEndDate ?? undefined,
		// NOTE: Update these values if multiple purposes are added
		...(identificationPurposes &&
			identificationPurposes.length > 0 && {
				purpose: PurposeEnum.SettlementOfBusinessExpenses,
				purposeItemId: identificationPurposes[0].itemId,
			}),
	};

	const methods = useForm<FormData>({
		resolver: yupResolver(schema),
		context: { confirmAge: true },
		...(applicant && {
			defaultValues: {
				...applicant,
			},
		}),
	});

	const onSubmit = ({ usagePurposes, ...inputs }: FormData) => {
		if (loading) return;

		saveInfo({
			variables: {
				...inputs,
				usagePurposes: usagePurposes.map((purpose) => ({ purpose })),
			},
		});
	};

	const isJapanese = methods.watch('nationalityId') === 'JP';

	return (
		<FormProvider {...methods}>
			<GlobalMessage />
			<RegistrationAlert />
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<FormDetails isJapanese={isJapanese} />
				<RegistrationButtons />
			</form>
		</FormProvider>
	);
};
