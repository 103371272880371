import { Button } from '@/components/new/Button';
import { Link } from '@/components/new/Link';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { Image } from '@/components/ui/Image';
import meIcon from '@images/meIcon.svg';
import styled from 'styled-components';

const TableWrapper = styled.div`
  max-width: 800px;
`;

const Heading = styled.h4`
  font-size: 14px;
  margin: 0;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextBox = styled.div`
  margin-top: 8px;
`;

const ButtonBox = styled.div`
  margin-top: 16px;
`;

type MeTableProps = {
	meSsoUrl: string;
};

export const MeTable = ({ meSsoUrl }: MeTableProps): JSX.Element => {
	return (
		<TableWrapper>
			<Table.Root>
				<Table.Head>
					<Table.Row>
						<Table.ColumnHeaderCell colSpan={2}>
							家計簿アプリとの連携
						</Table.ColumnHeaderCell>
					</Table.Row>
				</Table.Head>
				<Table.Body>
					<Table.Row>
						<Table.Cell width={230}>
							<ImageWrapper>
								<Image src={meIcon} alt="" width={120} height={100} />
							</ImageWrapper>
						</Table.Cell>
						<Table.Cell>
							<Heading>マネーフォワード ME</Heading>

							<TextBox>
								<Text>
									ウォレット残高を資産管理に追加
									<br />
									入出金時の通知設定で残高確認をさらに便利に
								</Text>
							</TextBox>

							<ButtonBox>
								<Link href={meSsoUrl} target="_blank">
									<Button type="button" variant="outline">
										マネーフォワード MEと連携する
									</Button>
								</Link>
							</ButtonBox>
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table.Root>
		</TableWrapper>
	);
};
