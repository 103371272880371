import { FormError } from '@/components/ui/FormError';
import { type Control, useFieldArray, useFormState } from 'react-hook-form';
import { type FormValues, MAX_COUNT } from '../schema';
import { AppendFilesButton, DeleteButton } from './Buttons';
import { FileLayout, FileRow } from './FileRow';
import { Filename } from './Filename';

export type LocalFilesProps = {
	control: Control<FormValues>;
	uploadedFilesCount: number;
	clearErrors: VoidFunction;
};
export const LocalFiles = ({
	control,
	uploadedFilesCount,
	clearErrors,
}: LocalFilesProps): JSX.Element => {
	const { errors } = useFormState({ control });
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'corporateRegistrations',
	});

	const totalFiles = uploadedFilesCount + fields.length;

	return (
		<>
			{fields.map((item, index) => (
				<FileRow key={item.id}>
					<FileLayout>
						<Filename>{item.file.name}</Filename>
						<DeleteButton onClick={() => remove(index)} />
					</FileLayout>
					{Array.isArray(errors.corporateRegistrations) &&
						errors.corporateRegistrations?.[index]?.file && (
							<FormError>
								{errors.corporateRegistrations[index].file?.message}
							</FormError>
						)}
				</FileRow>
			))}
			{totalFiles < 5 && (
				<FileRow>
					<AppendFilesButton
						handleAppend={(e) => {
							const fileList = e.target.files;

							if (!fileList) return;

							const uniqueFiles = [...fileList].filter(
								(f) =>
									!fields.some(
										(field) =>
											field.file.name === f.name &&
											field.file.lastModified === f.lastModified &&
											field.file.type === f.type,
									),
							);

							const allowedFiles = uniqueFiles.slice(0, MAX_COUNT - totalFiles);

							append(allowedFiles.map((f) => ({ file: f })));

							if (
								errors.corporateRegistrations &&
								!Array.isArray(errors.corporateRegistrations)
							) {
								clearErrors();
							}
							e.target.value = '';
						}}
					/>
				</FileRow>
			)}
		</>
	);
};
