import {
	type ToggleCardSensitiveInformationResult,
	useToggleCardSensitiveInformation,
} from './sensitiveInformation';

const fetchCardNumber = async (
	cardToken: string,
	codeVerifier: string,
): Promise<string> => {
	const res = await fetch(
		`${process.env.NEXT_PUBLIC_XARD_API_URL}/api/v1/sensitive_data`,
		{
			method: 'POST',
			headers: {
				'content-type': 'application/json;charset=UTF-8',
			},
			body: JSON.stringify({
				require_pan: true,
				card_token: cardToken,
				code_verifier: codeVerifier,
			}),
		},
	);

	if (!res.ok) {
		const data: { error: string } = await res.json();
		return Promise.reject(new Error(data.error));
	}

	const data: { pan: string } = await res.json();
	return data.pan;
};

export const useToggleCardNumber = (
	cardId: string,
	maskedCardNumber: string,
): ToggleCardSensitiveInformationResult =>
	useToggleCardSensitiveInformation(cardId, maskedCardNumber, fetchCardNumber);
