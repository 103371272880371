import { Head } from '@/components/head';
import { PageLayout } from '@/components/screen/layouts/PageLayout';
import { Image } from '@/components/ui/Image';
import cardIllustration from '@images/cardIllustration.svg';
import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 800px;
`;

export const CardDoesNotExist = (): JSX.Element => (
	<PageLayout title="カード詳細" backLinkText="カード" backHref="/cards">
		<Head title="カード詳細" />
		<Layout>
			<Image
				src={cardIllustration}
				alt="card does not exist"
				width={250}
				height={200}
			/>
			該当するカードがありません
		</Layout>
	</PageLayout>
);
