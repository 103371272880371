import { Button } from '@/components/new/Button';
import { DropdownIcon } from '@/components/new/icon/DropdownIcon';
import {
	ClickAwayListener,
	Divider,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
} from '@material-ui/core';
import NextLink from 'next/link';
import { type PropsWithChildren, useRef, useState } from 'react';
import styled from 'styled-components';

// adjust z-index since conflict search panel in cloud-react-ui
const Wrapper = styled.div`
  && {
    z-index: var(--z-index-create-card-button);
  }
`;

// make text and icon centered
const ButtonLabelWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  & > svg {
    position: relative;
    top: -1px;
  }
`;

// adjust margin button and popper
const StyledPopper = styled(Popper)`
  && {
    margin-top: 4px;
  }
`;

// remove hover interaction
const StyledMenuItem = styled(MenuItem)`
  && {
    width: 136px;
    padding: 8px;
  }
  &&:hover {
    background: transparent;
  }
  &&:first-of-type {
    padding-top: 10px;
    padding-bottom: 4px;
  }
  &&:last-of-type {
    padding-bottom: 10px;
  }
`;

// match color with header menus
const Anchor = styled.a`
  text-decoration: none;
  display: block;
  width: 100%;
  font-size: 13px;
  line-height: 1;
  color: #000;
  &:hover {
    color: #3b7de9;
  }
`;

const MenuLink = ({ href, children }: PropsWithChildren<{ href: string }>) => (
	<NextLink href={href} passHref>
		<Anchor>{children}</Anchor>
	</NextLink>
);

export const CreateCardButton = () => {
	const [open, setOpen] = useState(false);
	const anchorRef = useRef<HTMLButtonElement>(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Wrapper>
			<Button
				size="small"
				ref={anchorRef}
				aria-controls={open ? 'create-card-button-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-label="create card button"
				aria-haspopup="menu"
				onClick={handleToggle}
			>
				<ButtonLabelWrapper>
					カード追加発行
					<DropdownIcon size={18} color="#ffffff" />
				</ButtonLabelWrapper>
			</Button>
			<StyledPopper
				open={open}
				anchorEl={anchorRef.current}
				transition
				disablePortal
				placement="bottom-end"
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={() => handleClose()}>
								<MenuList id="create-card-button-menu" disablePadding>
									<StyledMenuItem disableRipple disableGutters>
										<MenuLink href="/cards/new">1枚ずつ発行</MenuLink>
									</StyledMenuItem>
									<StyledMenuItem disableRipple disableGutters>
										<MenuLink href="/cards/bulk-create">CSV一括発行</MenuLink>
									</StyledMenuItem>
									<Divider />
									<StyledMenuItem disableRipple disableGutters>
										<MenuLink href="/cards/raise-limit">
											上限枚数の引き上げ
										</MenuLink>
									</StyledMenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</StyledPopper>
		</Wrapper>
	);
};
