import { Head } from '@/components/head';
import { Link } from '@/components/new/Link';
import { Loading } from '@/components/screen/loading';
import { RegistrationLayout } from '@/components/ui/RegistrationLayout';
import { RegistrationNote } from '@/components/ui/RegistrationNote';
import { RegistrationTitle } from '@/components/ui/RegistrationTitle';
import { useRegistrationCorporateBeneficialOwnersQuery } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { Form } from './Form';

export const RegistrationCorporateBeneficialOwners = (): JSX.Element => {
	const { handleQueryError } = useApiError();
	const { data, loading } = useRegistrationCorporateBeneficialOwnersQuery({
		onError: handleQueryError,
		fetchPolicy: 'cache-and-network',
	});

	if (loading) return <Loading />;

	const { beneficialOwners, prefectureCode } = {
		...data?.currentOffice.identificationVerificationRequest
			?.corporateIdentification,
	};

	return (
		<RegistrationLayout>
			<Head title="実質的支配者情報の入力" />
			<RegistrationTitle title="実質的支配者情報の入力" center />
			<RegistrationNote center>
				非上場企業の場合、実質的支配者情報の入力が必要です。
				<Link
					href="https://support.biz.moneyforward.com/biz-pay/faq/word/f023.html"
					target="_blank"
				>
					実質的支配者とは？
				</Link>
			</RegistrationNote>
			<Form
				beneficialOwners={beneficialOwners ?? []}
				prefectureCode={prefectureCode}
			/>
		</RegistrationLayout>
	);
};
