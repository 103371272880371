import { useFormContext } from 'react-hook-form';

import { Checkbox } from '@/components/new/Checkbox';
import { FormError } from '@/components/ui/FormError';
import { UsagePurposeOptions } from '@/lib/usagePurpose';
import styled from 'styled-components';
import type { UsagePurposesData } from './schema';

const Wrapper = styled.div`
  display: grid;
  gap: 8px;
  grid-auto-flow: row;
`;

export const UsagePurposesInput = (): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<UsagePurposesData>();

	return (
		<Wrapper>
			{UsagePurposeOptions.map((option) => (
				<div key={option.value}>
					<Checkbox {...register('usagePurposes')} value={option.value}>
						{option.label}
					</Checkbox>
				</div>
			))}
			{errors.usagePurposes && (
				<FormError>{errors.usagePurposes.message}</FormError>
			)}
		</Wrapper>
	);
};
