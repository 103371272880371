import { CircularProgress } from '@/components/new/CircularProgress';
import { Text } from '@/components/new/Text';
import { Image } from '@/components/ui/Image';
import {
	type CardColorEnum,
	CardState,
	type CardTypeCategory,
} from '@/graphql';
import cardImageLocked from '@images/cardImageLocked.svg';
import cardImageTerminated from '@images/cardImageTerminated.svg';
import refresh from '@images/icons/refresh.svg';
import styled from 'styled-components';
import { Card } from './Card';

export type Props = {
	state: CardState;
	loading: boolean;
	hasError: boolean;
	pan: string;
	name: string;
	expiration: string;
	securityCode: string;
	cardType: CardTypeCategory;
	cardLock: boolean;
	isCreditCard: boolean;
	color: CardColorEnum;
	toggleShowNumber: VoidFunction;
	toggleShowSecurityCode: VoidFunction;
};

const StyledCardPlaceholder = styled.div`
  width: 353px;
  height: 223px;
  background: #444;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`;

const StyledRetryButton = styled.button`
  background: transparent;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  display: inline-flex;
  gap: 2px;
  align-items: center;
  &:hover {
    opacity: 0.8;
  }
`;

const CardPlaceHolder = ({
	hasError,
	loading,
	toggleShowNumber,
	toggleShowSecurityCode,
}: Pick<
	Props,
	'hasError' | 'loading' | 'toggleShowNumber' | 'toggleShowSecurityCode'
>): JSX.Element => {
	const handleToggle = () => {
		toggleShowNumber();
		toggleShowSecurityCode();
	};

	return (
		<StyledCardPlaceholder>
			{loading ? (
				<CircularProgress size="medium" />
			) : (
				hasError && (
					<>
						<Text color="notes" size="xlarge">
							通信エラーが発生しました
						</Text>
						<StyledRetryButton onClick={handleToggle}>
							再試行
							<Image src={refresh} width={16} height={16} />
						</StyledRetryButton>
					</>
				)
			)}
		</StyledCardPlaceholder>
	);
};

const LockedCardImage = () => (
	<Image
		src={cardImageLocked}
		width={353}
		height={223}
		aria-label="lockedCard"
	/>
);

export const CardImage = ({
	state,
	loading,
	hasError,
	pan,
	name,
	expiration,
	securityCode,
	cardType,
	cardLock,
	isCreditCard,
	color,
	toggleShowNumber,
	toggleShowSecurityCode,
}: Props): JSX.Element => {
	if (loading || hasError)
		return (
			<CardPlaceHolder
				loading={loading}
				hasError
				toggleShowNumber={toggleShowNumber}
				toggleShowSecurityCode={toggleShowSecurityCode}
			/>
		);

	switch (state) {
		case CardState.Locked:
		case CardState.Suspended:
		case CardState.Frozen:
			return <LockedCardImage />;
		case CardState.Terminated:
		case CardState.Unactivated:
			return (
				<Image
					src={cardImageTerminated}
					width={353}
					height={223}
					aria-label="terminatedCard"
				/>
			);
		case CardState.Active:
			// state が即時反映されない場合があるので、一時利用停止中フラグをみて画像を出し分ける
			if (cardLock) return <LockedCardImage />;

			return (
				<Card
					cardType={cardType}
					pan={pan}
					name={name}
					expiration={expiration}
					securityCode={securityCode}
					isCreditCard={isCreditCard}
					color={color}
				/>
			);
	}
};
