import type { TenantTypeEnum } from '@/graphql';

type DelightedScriptArgs = {
	officeId: string | undefined;
	officeMemberId: string | undefined;
	userId: string | undefined;
	tenantType: TenantTypeEnum | undefined;
};

export const delightedScript = ({
	officeId,
	officeMemberId,
	userId,
	tenantType,
}: DelightedScriptArgs) => `!function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],c=0;c<i.length;c++){var s=i[c];a[s]=a[s]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(s)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(o,p)}}(window,document, "${process.env.NEXT_PUBLIC_DELIGHTED_QUESTIONNAIRE}","delighted");   
delighted.survey({
  name: "${officeId}",
  email: "${officeMemberId}@example.com",
  properties: {
    userId: "${userId}",
    officeId: "${officeId}",
    officeMemberId: "${officeMemberId}",
    tenantType: "${tenantType}"
  }
});`;
