import {
	addressSchema,
	birthDateSchema,
	firstNameKanaSchema,
	firstNameSchema,
	lastNameKanaSchema,
	lastNameSchema,
	phoneNumberSchema,
	purposeSchema,
} from '@/components/ui/inputs';
import { financialAssetsSchema } from '@/components/ui/inputs/FinancialAssets';
import { firstNameEnSchema } from '@/components/ui/inputs/FirstNameEn';
import { incomeSchema } from '@/components/ui/inputs/Income';
import { lastNameEnSchema } from '@/components/ui/inputs/LastNameEn';
import { middleNameEnSchema } from '@/components/ui/inputs/MiddleNameEn';
import { residencePeriodEndDateFieldSchema } from '@/components/ui/inputs/ResidencePeriodEndDate/schema';
import { residentialStatusFieldSchema } from '@/components/ui/inputs/ResidentialStatus/schema';
import { usagePurposesSchema } from '@/components/ui/inputs/UsagePurpose';
import { userIndustrySchema } from '@/components/ui/inputs/UserIndustry';
import type { ResidentialStatusEnum } from '@/graphql';
import { yup } from '@/lib/yup';

export type FormData = yup.Asserts<typeof schema>;

export const schema = yup
	.object({})
	.concat(firstNameSchema)
	.concat(lastNameSchema)
	.concat(firstNameKanaSchema)
	.concat(lastNameKanaSchema)
	.concat(firstNameEnSchema)
	.concat(lastNameEnSchema)
	.concat(middleNameEnSchema)
	.concat(birthDateSchema)
	.concat(addressSchema)
	.concat(phoneNumberSchema)
	.concat(
		yup.object({
			nationalityId: yup.string().required('選択してください'),
			residentialStatus: yup
				.mixed<ResidentialStatusEnum>()
				.when('nationalityId', {
					is: 'JP',
					// NOTE: force transform to null because of the API spec
					// biome-ignore lint/suspicious/noThenProperty: TODO
					then: (schema) => schema.nullable().transform(() => null),
					otherwise: () => residentialStatusFieldSchema,
				}),
			residencePeriodEndDate: yup.string().when('nationalityId', {
				is: 'JP',
				// NOTE: force transform to null because of the API spec
				// biome-ignore lint/suspicious/noThenProperty: TODO
				then: (schema) => schema.nullable().transform(() => null),
				otherwise: () => residencePeriodEndDateFieldSchema,
			}),
		}),
	)
	.concat(incomeSchema)
	.concat(financialAssetsSchema)
	.concat(userIndustrySchema)
	.concat(purposeSchema)
	.concat(usagePurposesSchema);
