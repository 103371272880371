import { TextWithQuestionIcon } from '@/components/ui/TextWithQuestionIcon';
import { useAvailableAmountWidgetQuery } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import styled from 'styled-components';
import { Card } from '../Card';

const FallbackSpace = styled.div`
  height: 86px;
`;

const Heading = styled.div`
  font-size: 14px;
`;

const Amount = styled.p`
  margin: 0;
  font-size: 48px;
  font-weight: 500;
  display: inline-flex;
  line-height: 1;
  align-items: baseline;
`;

const Unit = styled.span`
  font-size: 24px;
  position: relative;
  margin-left: 4px;
`;

export const AvailableAmountWidget = (): JSX.Element => {
	const { handleQueryError } = useApiError();
	const { data, loading } = useAvailableAmountWidgetQuery({
		fetchPolicy: 'cache-and-network',
		onError: handleQueryError,
	});

	const amount = Number(data?.currentOffice.wallet?.value?.availableAmount);

	if (loading) {
		return (
			<Card.Root>
				<FallbackSpace>
					<Card.Fallback />
				</FallbackSpace>
			</Card.Root>
		);
	}

	return (
		<Card.Root>
			<Card.Head>
				<Heading>
					<TextWithQuestionIcon
						label="利用可能額"
						content={<>利用可能額＝与信限度額＋ウォレット残高ー未清算金額</>}
					/>
				</Heading>
			</Card.Head>
			<Card.Body>
				{/* biome-ignore lint/suspicious/noGlobalIsNan: This is a valid case */}
				{isNaN(amount) ? (
					<Amount data-testid="availableAmountError">
						-<Unit>円</Unit>
					</Amount>
				) : (
					<Amount>
						{amount.toLocaleString()}
						<Unit>円</Unit>
					</Amount>
				)}
			</Card.Body>
		</Card.Root>
	);
};
