import { CircularProgress } from '@/components/new/CircularProgress';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Fallback = (): JSX.Element => {
	return (
		<Wrapper>
			<CircularProgress size="medium" />
		</Wrapper>
	);
};
