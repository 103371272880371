import { Head } from '@/components/head';
import { RegistrationNote } from '@/components/ui/RegistrationNote';
import { RegistrationStepLayout } from '@/components/ui/RegistrationStepLayout';
import { RegistrationTitle } from '@/components/ui/RegistrationTitle';
import { Form } from '../../shared/Conditions/Form';

export const RegistrationCorporateConditions = (): JSX.Element => (
	<RegistrationStepLayout>
		<Head title="申込に関する注意事項" />
		<RegistrationTitle title="申込に関する注意事項" center />
		<RegistrationNote center>
			以下に該当する方はサービスをご利用いただくことができません。
		</RegistrationNote>

		<Form tenantType="corporate" />
	</RegistrationStepLayout>
);
