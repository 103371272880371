import { useFormContext } from 'react-hook-form';

import { Checkbox } from '@/components/new/Checkbox';
import { FormError } from '@/components/ui/FormError';
import { PurposeEnum } from '@/graphql';
import type { PurposeData } from './schema';

export const PurposeInput = (): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<PurposeData>();

	// NOTE: purpose増やす場合は要修正
	return (
		<div>
			<div>
				<Checkbox
					{...register('purpose')}
					value={PurposeEnum.SettlementOfBusinessExpenses}
				>
					事業費決済
				</Checkbox>
			</div>
			{errors.purpose && <FormError>{errors.purpose.message}</FormError>}
		</div>
	);
};
