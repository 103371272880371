import { Link } from '@/components/new/Link';
import { Radio } from '@/components/new/Radio';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { FormError } from '@/components/ui/FormError';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import type { FormData } from './types';

type Props = {
	tenantType: 'corporate' | 'individual';
};

const Note = styled.h2`
  font-size: 15px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 14px;
`;
const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
`;
const StyledFormError = styled(FormError)`
  display: block;
`;

export const Inputs = ({ tenantType }: Props): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<FormData>();

	const isCorporate = tenantType === 'corporate';
	return (
		<Table.Root>
			<Table.Head>
				<Table.Row>
					<Table.ColumnHeaderCell colSpan={2}>
						以下をご確認ください
					</Table.ColumnHeaderCell>
				</Table.Row>
			</Table.Head>
			<Table.Body>
				<Table.Row>
					<Table.Cell size="large">
						<Note>
							{isCorporate
								? '日本国内で法人登記されていない'
								: '日本国内に居住していない'}
						</Note>
						<Text>
							{isCorporate
								? '日本国内で法人登記されていないお客様はご利用いただくことができません。'
								: 'サービスをご利用いただけるのは、日本国内に居住している方のみとなります。日本国内に居住している外国籍の方もご利用いただけます。'}
						</Text>
					</Table.Cell>
					<Table.Cell width="148px">
						<RadioWrapper>
							<Radio
								{...register('isRegisteredInJapan')}
								value="false"
								data-testid="isNotRegisteredInJapan"
							>
								{isCorporate ? '登記していない' : '居住していない'}
							</Radio>
							<Radio
								{...register('isRegisteredInJapan')}
								value="true"
								data-testid="isRegisteredInJapan"
							>
								{isCorporate ? '登記している' : '居住している'}
							</Radio>
						</RadioWrapper>
						{errors.isRegisteredInJapan && (
							<StyledFormError>
								{errors.isRegisteredInJapan.message}
							</StyledFormError>
						)}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell size="large">
						<Note>
							{isCorporate
								? '米国で設立された法人等（FATCA）である'
								: '米国への納税義務がある'}
						</Note>
						<Text>
							{isCorporate
								? '米国で設立された法人等（FATCA）の場合はご利用いただくことができません。'
								: '米国籍保有者・グリーンカード保有者など米国への納税義務がある方（FATCA）はご利用いただくことができません。'}

							<Link
								href="https://support.biz.moneyforward.com/biz-pay/faq/word/f019.html"
								target="_blank"
							>
								FATCAとは
							</Link>
						</Text>
					</Table.Cell>
					<Table.Cell width="148px">
						<RadioWrapper>
							<Radio
								{...register('isNotFatca')}
								value="false"
								data-testid="isFatca"
							>
								該当する
							</Radio>
							<Radio
								{...register('isNotFatca')}
								value="true"
								data-testid="isNotFatca"
							>
								該当しない
							</Radio>
						</RadioWrapper>
						{errors.isNotFatca && (
							<StyledFormError>{errors.isNotFatca.message}</StyledFormError>
						)}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell size="large">
						<Note>
							{isCorporate
								? '法人の役員、取引担当者、実質的支配者が外国PEPsである'
								: '外国PEPsに該当する'}
						</Note>
						<Text>
							{isCorporate
								? '法人の役員、取引担当者、実質的支配者が外国PEPs（外国における公的地位にある方、およびその家族）にあたる場合、サービスをご利用いただくことができません。'
								: '外国PEPs（外国における公的地位にある方、およびその家族）にあたる方はご利用いただくことができません。'}
							<Link
								href="https://support.biz.moneyforward.com/biz-pay/faq/word/f020.html"
								target="_blank"
							>
								外国PEPsとは
							</Link>
							{isCorporate && (
								<Link
									href="https://biz.moneyforward.com/support/biz-pay/faq/word/f023.html"
									target="_blank"
								>
									実質的支配者とは
								</Link>
							)}
						</Text>
					</Table.Cell>
					<Table.Cell width="148px">
						<RadioWrapper>
							<Radio
								{...register('isNotPeps')}
								value="false"
								data-testid="isPeps"
							>
								該当する
							</Radio>
							<Radio
								{...register('isNotPeps')}
								value="true"
								data-testid="isNotPeps"
							>
								該当しない
							</Radio>
						</RadioWrapper>
						{errors.isNotPeps && (
							<StyledFormError>{errors.isNotPeps.message}</StyledFormError>
						)}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell size="large">
						<Note>
							{isCorporate
								? '法人の役員、取引担当者、実質的支配者が反社会的勢力である'
								: '反社会的勢力である'}
						</Note>
						<Text>
							{isCorporate
								? '法人の代表者、役員、取引担当者、実質的支配者が暴力団員・暴力団準構成員・総会屋等の反社会的勢力に該当せず、かつ将来にわたっても該当しないことを確約していただきます。'
								: '暴力団員・暴力団準構成員・総会屋等の反社会的勢力に該当せず、かつ将来にわたっても該当しないことを確約していただきます。'}
							<Link
								href="https://support.biz.moneyforward.com/biz-pay/faq/word/f021.html"
								target="_blank"
							>
								反社会的勢力とは
							</Link>
						</Text>
					</Table.Cell>
					<Table.Cell width="148px">
						<RadioWrapper>
							<Radio
								{...register('isNotAntisocialForce')}
								value="false"
								data-testid="isAntisocialForce"
							>
								該当する
							</Radio>
							<Radio
								{...register('isNotAntisocialForce')}
								value="true"
								data-testid="isNotAntisocialForce"
							>
								該当しない
							</Radio>
						</RadioWrapper>
						{errors.isNotAntisocialForce && (
							<StyledFormError>
								{errors.isNotAntisocialForce.message}
							</StyledFormError>
						)}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell size="large">
						<Note>経済制裁対象国等との取引・資産がある</Note>
						<Text>
							本邦財務省や米国財務省外国資産管理局（通称「OFAC」）等が実施している経済制裁の対象となる取引に該当する場合、ご利用いただくことができません。
							<Link
								href="https://www.mof.go.jp/policy/international_policy/gaitame_kawase/gaitame/economic_sanctions/list.html"
								target="_blank"
							>
								経済制裁対象はこちら
							</Link>
						</Text>
					</Table.Cell>
					<Table.Cell width="148px">
						<RadioWrapper>
							<Radio
								{...register('isNotRelatedToSanctionedCountries')}
								value="false"
								data-testid="isRelatedToSanctionedCountries"
							>
								該当する
							</Radio>
							<Radio
								{...register('isNotRelatedToSanctionedCountries')}
								value="true"
								data-testid="isNotRelatedToSanctionedCountries"
							>
								該当しない
							</Radio>
						</RadioWrapper>
						{errors.isNotRelatedToSanctionedCountries && (
							<StyledFormError>
								{errors.isNotRelatedToSanctionedCountries.message}
							</StyledFormError>
						)}
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Table.Root>
	);
};
