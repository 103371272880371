import { Head } from '@/components/head';
import { Link } from '@/components/new/Link';
import { Tooltip } from '@/components/new/Tooltip';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { Image } from '@/components/ui/Image';
import { RegistrationLayout } from '@/components/ui/RegistrationLayout';
import { RegistrationNote } from '@/components/ui/RegistrationNote';
import { RegistrationTitle } from '@/components/ui/RegistrationTitle';
import question from '@images/icons/question.svg';
import styled from 'styled-components';
import { Form } from './components/Form';

const IconContainer = styled.div`
  position: relative;
  bottom: -1px;
`;

export const CertificateUpload = (): JSX.Element => (
	<RegistrationLayout>
		<Head title="登記事項証明書アップロード" />
		<RegistrationTitle title="登記事項証明書をアップロード" center />
		<RegistrationNote center>
			6ヶ月以内に取得した登記事項証明書&thinsp;
			<Tooltip content="現在事項/履歴事項どちらでも可">
				<IconContainer>
					<Image src={question} width={12} height={12} />
				</IconContainer>
			</Tooltip>
			を、写真またはPDFでアップロードしてください。
			<br />
			最大5点までアップロード可能です。
			<br />
			<Link
				href="https://biz.moneyforward.com/support/biz-pay/faq/application/f063.html"
				target="_blank"
			>
				登記事項証明書の取得方法
			</Link>
		</RegistrationNote>
		<GlobalMessage />
		<Form />
	</RegistrationLayout>
);
