import { PlannedUsageAmountEnum } from '@/graphql';

export const PlannedUsageAmountSelectOptions = [
	{ value: PlannedUsageAmountEnum.Lt100Thousand, label: '10万円未満' },
	{
		value: PlannedUsageAmountEnum.Gte100ThousandLt300Thousand,
		label: '10万円～30万円未満',
	},
	{
		value: PlannedUsageAmountEnum.Gte300ThousandLt1000Thousand,
		label: '30万円～100万円未満',
	},
	{
		value: PlannedUsageAmountEnum.Gte1000ThousandLt3000Thousand,
		label: '100万円～300万円未満',
	},
	{
		value: PlannedUsageAmountEnum.Gte3000ThousandLt5000Thousand,
		label: '300万円～500万円未満',
	},
	{
		value: PlannedUsageAmountEnum.Gte5000ThousandLt10000Thousand,
		label: '500万円～1000万円未満',
	},
	{
		value: PlannedUsageAmountEnum.Gte10000ThousandLt25000Thousand,
		label: '1000万円～2500万円未満',
	},
	{
		value: PlannedUsageAmountEnum.Gte25000ThousandLt50000Thousand,
		label: '2500万円～5000万円未満',
	},
	{
		value: PlannedUsageAmountEnum.Gte50000ThousandLt100000Thousand,
		label: '5000万円～1億円未満',
	},
	{
		value: PlannedUsageAmountEnum.Gte100000ThousandLt500000Thousand,
		label: '1億円～5億円未満',
	},
	{
		value: PlannedUsageAmountEnum.Gte500000ThousandLt1000000Thousand,
		label: '5億円～10億円未満',
	},
	{
		value: PlannedUsageAmountEnum.Gte1000000Thousand,
		label: '10億円以上',
	},
];

export const getUsageAmountDescription = (
	amount?: PlannedUsageAmountEnum | null,
): string => {
	const value = PlannedUsageAmountSelectOptions.find(
		(option) => option.value === amount,
	);
	return value ? value.label : '';
};
