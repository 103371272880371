import type { XardCardRequestInput } from '@/graphql';
import { yup } from '@/lib/yup';
import { produce } from 'immer';

type DepartmentName = Pick<XardCardRequestInput, 'departmentName'>;
export const departmentNameSchema: yup.ObjectSchema<DepartmentName> = yup
	.object({
		departmentName: yup.string().when('$isCorporate', {
			is: true,
			// biome-ignore lint/suspicious/noThenProperty: TODO
			then: (schema) => schema.max(50).trim().optional(),
			otherwise: (schema) => schema.strip(),
		}),
	})
	.transform((value: DepartmentName) =>
		produce(value, (draft) => {
			if ([null, undefined, ''].includes(draft.departmentName)) {
				draft.departmentName = undefined;
			}
		}),
	);
