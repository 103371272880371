import { registrationFirstCardDesignSchema } from '@/components/ui/RegistrationFirstCardDesign';
import { registrationFirstCardLimitsSchema } from '@/components/ui/RegistrationFirstCardLimits';
import { registrationFirstCardOptionsSchema } from '@/components/ui/RegistrationFirstCardOptions';
import { registrationFirstCardPlansSchema } from '@/components/ui/RegistrationFirstCardPlans/schema';
import { registrationFirstCardSettingsSchema } from '@/components/ui/RegistrationFirstCardSettings';
import { yup } from '@/lib/yup';

export type FormData = yup.Asserts<typeof schema>;

export const schema = yup
	.object({})
	.concat(registrationFirstCardDesignSchema)
	.concat(registrationFirstCardSettingsSchema)
	.concat(registrationFirstCardLimitsSchema)
	.concat(registrationFirstCardOptionsSchema)
	.concat(registrationFirstCardPlansSchema);
