import { PlannedUsageAmountEnum } from '@/graphql';
import { yup } from '@/lib/yup';

export type PlannedUsageAmountSchemaData = yup.Asserts<
	typeof plannedUsageAmountSchema
>;

export const plannedUsageAmountSchema = yup.object({
	plannedUsageAmount: yup
		.mixed<PlannedUsageAmountEnum>()
		.oneOf(Object.values(PlannedUsageAmountEnum), '選択してください')
		.required('選択してください'),
});
