import { Head } from '@/components/head';
import { Loading, PageLayout } from '@/components/screen';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { useCurrentOfficeMemberQuery, useOfficeMemberQuery } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { useBackHref } from '../shared/hooks/useBackHref';
import { AccountManagerMessage, UserDoesNotExist } from './components';
import { UserInfoTable, UserRoleTable, UserTerminateTable } from './tables';

const TablesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Box = styled.div`
  max-width: 800px;
`;

const TableWrapper = styled.div<{ isManager: boolean }>`
  margin-top: ${(props) => (props.isManager ? '0' : '24px')};
`;
export const SettingsUsersIdIndex = (): JSX.Element => {
	const { id } = useRouter().query;
	const { handleQueryError } = useApiError();
	const {
		data: omData,
		loading: omLoading,
		error: omError,
	} = useOfficeMemberQuery({
		variables: {
			officeMemberId: id as string,
		},
		skip: typeof id !== 'string',
		onError: handleQueryError,
	});
	const { data: comData, loading: comLoading } = useCurrentOfficeMemberQuery({
		onError: handleQueryError,
	});
	const backHref = useBackHref();

	if (omLoading || comLoading) return <Loading />;

	const officeMember = omData?.currentOffice.officeMember;
	const currentOfficeMemberId = comData?.currentOfficeMember.id;

	if (!!omError || !officeMember) return <UserDoesNotExist />;

	const {
		id: officeMemberId,
		displayName,
		role,
		email,
		isManager,
		activatedAt,
	} = officeMember;

	return (
		<PageLayout title="編集" backLinkText="ユーザー" backHref={backHref}>
			<Head title="ユーザー編集" />
			<Box>
				<GlobalMessage />
				<TablesWrapper>
					{isManager && <AccountManagerMessage />}
					<UserInfoTable {...{ displayName, activatedAt, email, isManager }} />
					<UserRoleTable
						{...{ role, isManager, officeMemberId }}
						isChangeMyself={officeMemberId === currentOfficeMemberId}
					/>
					<TableWrapper isManager={isManager}>
						<UserTerminateTable
							officeMemberId={officeMemberId}
							isDeleteMyself={officeMemberId === currentOfficeMemberId}
							disabled={isManager}
						/>
					</TableWrapper>
				</TablesWrapper>
			</Box>
		</PageLayout>
	);
};
