import {
	CardColorEnum,
	CardTypeCategory,
	type XardCardRequestInput,
} from '@/graphql';
import { yup } from '@/lib/yup';

export const cardDesignSchema: yup.ObjectSchema<
	Pick<XardCardRequestInput, 'color'>
> = yup.object({
	color: yup
		.mixed<CardColorEnum>()
		.oneOf(Object.values(CardColorEnum))
		.when('cardType', {
			is: CardTypeCategory.Physical,
			// biome-ignore lint/suspicious/noThenProperty: TODO
			then: (schema) => schema.required(),
			// NOTE: Transform BLACK to set as defaultValue and omit before mutation
			otherwise: (schema) => schema.transform(() => CardColorEnum.Black),
		}),
});
