import { yup } from '@/lib/yup';

export type CardLimitsData = yup.Asserts<typeof cardLimitsSchema>;

export const cardLimitsSchema = yup.object({
	maximumLimitInMonth: yup
		.number()
		.integer('整数で入力してください')
		.positive('0円より大きい金額を入力してください')
		.max(1000000000, '1,000,000,000円を超える金額は設定できません')
		.typeError('金額を入力してください')
		.stripCommas()
		.required('金額を入力してください'),
	maximumPaymentLimit: yup
		.number()
		.positive('0円より大きい金額を入力してください')
		.integer('整数で入力してください')
		.test('lessThanLimit', function (value, { createError }) {
			const { maximumLimitInMonth } = this.parent;
			const isLessThanMaximumLimit = maximumLimitInMonth < 100000000;
			const maxValue = isLessThanMaximumLimit ? maximumLimitInMonth : 100000000;
			const maxMessage = isLessThanMaximumLimit
				? '月ごとの上限金額より小さい金額を入力してください'
				: '100,000,000円を超える金額は設定できません';

			if (!value)
				return createError({
					message: '金額を入力してください',
				});

			return value <= maxValue || createError({ message: maxMessage });
		})
		.typeError('金額を入力してください')
		.stripCommas()
		.required('金額を入力してください'),
});
