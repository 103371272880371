import type { ParsedUrlQuery } from 'node:querystring';
import { CardState } from '@/graphql';
import { CARD_STATE_STOPPED } from '../types';

type VALID_STATES =
	| CardState.Active
	| CardState.Terminated
	| CardState.Unactivated
	| typeof CARD_STATE_STOPPED;
export const stripInvalidStates = (
	query: ParsedUrlQuery[string],
): VALID_STATES[] | undefined => {
	if (query) {
		const input = Array.isArray(query) ? query : [query];

		const states = input.reduce<VALID_STATES[]>((results, i) => {
			switch (i) {
				case CardState.Active:
				case CardState.Terminated:
				case CardState.Unactivated:
				case CARD_STATE_STOPPED:
					// biome-ignore lint/performance/noAccumulatingSpread: TODO
					return [...results, i];
				default:
					return results;
			}
		}, []);

		if (states.length > 0) return states;
	}

	return undefined;
};
