import { FlashMessage } from '@/components/new/FlashMessage';
import styled from 'styled-components';
import { ClearTenantUser } from './ClearTenantUser';

const ClearCardText = styled.span`
  color: #ec4949;
  text-decoration: underline;
  text-underline-offset: 1px;
  &:hover {
    color: #ec4949;
  }
`;

export const TenantUserError = (): JSX.Element => (
	<FlashMessage color="error">
		カードの絞り込みでエラーが発生しました。
		<ClearTenantUser>
			<ClearCardText>絞込解除</ClearCardText>
		</ClearTenantUser>
	</FlashMessage>
);
