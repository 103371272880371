import { Button } from '@/components/new/Button';
import styled from 'styled-components';
import { useCard } from '../../hooks';

const ButtonLayout = styled.div({
	textAlign: 'center',
	'> button': {
		width: '160px',
	},
});

export const Buttons = (): JSX.Element => {
	const { data } = useCard();
	const card = data?.currentOffice?.wallet?.card;

	return (
		<ButtonLayout>
			<Button size="large" disabled={!card}>
				確認画面へ
			</Button>
		</ButtonLayout>
	);
};
