import { addressSchema } from '@/components/ui/inputs/Address/schema';
import type { CardDestinationMasterInput } from '@/graphql';
import { yup } from '@/lib/yup';

export const schema: yup.ObjectSchema<CardDestinationMasterInput> = yup
	.object({
		departmentName: yup.string().nullable().default(null).max(25),
		destinationName: yup.string().required().max(23),
		label: yup.string().required().max(30),
		tradeName: yup.string().nullable().default(null).max(25),
	})
	.concat(addressSchema);
