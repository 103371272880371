import type { NotificationSettingInput } from '@/graphql';
import { yup } from '@/lib/yup';

export type ThresholdData = yup.Asserts<typeof thresholdSchema>;

export const thresholdSchema: yup.ObjectSchema<
	Pick<NotificationSettingInput, 'threshold'>
> = yup.object({
	threshold: yup
		.number()
		.nullable()
		.min(0, '0円以上の金額を入力してください')
		.max(100000000, '100,000,000円を超える金額は設定できません')
		.typeError('金額を入力してください')
		.stripCommas(),
});
