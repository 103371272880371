import { Button } from '@/components/new/Button';
import { CircularProgress } from '@/components/new/CircularProgress';
import type { CardDestinationMasterInput } from '@/graphql';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { GlobalMessage } from '../GlobalMessage';
import { DestinationInputs } from './DestinationInputs';
import { schema } from './schema';

const ButtonWrapper = styled.div({
	textAlign: 'center',
	'> button': {
		width: '160px',
	},
});

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

type DestinationFormProps = {
	defaultValues: Partial<CardDestinationMasterInput> | null | undefined;
	saving: boolean;
	onSubmit: (data: CardDestinationMasterInput) => void;
};

export const DestinationForm = ({
	defaultValues,
	saving,
	onSubmit,
}: DestinationFormProps): JSX.Element => {
	const methods = useForm<CardDestinationMasterInput>({
		resolver: yupResolver(schema),
		defaultValues: { ...defaultValues },
	});
	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	const callback = useCallback(
		(data: CardDestinationMasterInput) => {
			onSubmit(data);

			methods.reset({
				buildingName: data.buildingName,
				city: data.city,
				departmentName: data.departmentName,
				destinationName: data.destinationName,
				label: data.label,
				postalCode: data.postalCode,
				prefectureCode: data.prefectureCode,
				street: data.street,
				tradeName: data.tradeName,
			});
		},
		[saving],
	);

	return (
		<FormProvider {...methods}>
			<GlobalMessage />
			<StyledForm onSubmit={methods.handleSubmit(callback)}>
				<DestinationInputs />
				<ButtonWrapper>
					<Button size="large" disabled={saving}>
						{saving ? <CircularProgress size="medium" /> : '保存'}
					</Button>
				</ButtonWrapper>
			</StyledForm>
		</FormProvider>
	);
};
