import { Head } from '@/components/head';
import { PageLayout } from '@/components/screen';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 800px;
`;

export const Layout: React.FC = ({ children }) => (
	<PageLayout title="事業者">
		<Head title="事業者" />
		<Container>{children}</Container>
	</PageLayout>
);
