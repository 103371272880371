import { FormError } from '@/components/ui/FormError';
import { useFormState } from 'react-hook-form';
import styled from 'styled-components';
import { RoleRadio, type RoleRadioProps } from './RoleRadio';
import type { InviteRoleSchemaData, UpdateRoleSchemaData } from './schema';

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

interface RoleInputComposition {
	Radio: React.FC<RoleRadioProps>;
}
type RoleInputProps = {
	children: React.ReactNode;
};
const RoleInput: React.FC<RoleInputProps> & RoleInputComposition = ({
	children,
}) => {
	const { errors } = useFormState<
		InviteRoleSchemaData | UpdateRoleSchemaData
	>();

	return (
		<>
			<RadioGroup>{children}</RadioGroup>
			{errors.role && <FormError>{errors.role.message}</FormError>}
		</>
	);
};

RoleInput.Radio = RoleRadio;

export { RoleInput };
