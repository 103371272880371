import { Head } from '@/components/head';
import { Button } from '@/components/new/Button';
import { CSVExportDialog } from '@/components/new/CSVExportDialog';
import { Dialog } from '@/components/new/Dialog';
import { Notification } from '@/components/new/Notification';
import { Pagination } from '@/components/new/Pagination';
import { Tooltip } from '@/components/new/Tooltip';
import { PageLayout } from '@/components/screen/layouts/PageLayout';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { CardsOrder, RoleEnum, useWalletCardsQuery } from '@/graphql';
import { usePaginationVariables } from '@/hooks/usePaginationVariables';
import { useRole } from '@/hooks/useRole';
import { useTenantType } from '@/hooks/useTenantType';
import { useRouter } from 'next/router';
import { useState } from 'react';
import styled from 'styled-components';
import { HighlightAnnouncement } from '../../layouts/HighlightAnnouncement';
import {
	type PaginationLayoutType,
	paginationLayouts,
} from '../../layouts/PaginationAndExportLayout';
import {
	CardSortSelect,
	LOCAL_STORAGE_SORT_ORDER_KEY,
	getSortOrderFromLocalStorage,
} from './components/CardSortSelect';
import { CardsInformationMessage } from './components/CardsInformationMessage';
import { CreateCardButton } from './components/CreateCardButton';
import { NumberOfCardLimitText } from './components/NumberOfCardLimitText';
import { CardsSearchPanel } from './components/SearchPanel';

import { CardsTable } from './components/Table';
import { TenantUserError } from './components/TenantUserError';
import { TenantUserTitle } from './components/TenantUserTitle';
import { useExportCards } from './hooks/useExportCards';
import {
	isInvalidLastFour,
	isInvalidSerialCardNumber,
	useHandleCardsError,
} from './hooks/useHandleCardsError';
import { useSearchVariables } from './hooks/useSearchVariables';
import { useTenantUser } from './hooks/useTenantUser';
import { useTenantUserUid } from './hooks/useTenantUserUid';
import { useTransactionTimeVariables } from './hooks/useTransactionTimeVariables';
import type { CardRowDetails } from './types';

const TitleLayout = styled.div`
  display: flex;
  gap: 21px;
  align-items: center;
`;

const LeftActionsLayout = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const RightActionsLayout = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const BottomPaginationLayout = styled.div`
  margin-top: 8px;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ButtonWrapper = styled.div({
	'> button': {
		width: 'max-content',
	},
});

export const CardsIndex = (): JSX.Element => {
	const router = useRouter();
	const [open, setOpen] = useState(false);
	const handleCardsError = useHandleCardsError();
	const tenantUserUid = useTenantUserUid();
	const paginationVariables = usePaginationVariables();
	const searchVariables = useSearchVariables();
	const transactionTimeVariables = useTransactionTimeVariables();

	const getStoredOrder = (): CardsOrder => {
		if (typeof window === 'undefined') {
			return CardsOrder.CreatedAtAsc;
		}
		return getSortOrderFromLocalStorage(
			window.localStorage.getItem(LOCAL_STORAGE_SORT_ORDER_KEY),
		);
	};
	const {
		isCorporate,
		loading: tenantTypeLoading,
		error: tenantTypeError,
	} = useTenantType();
	const {
		data,
		refetch,
		loading: cardsLoading,
		error: cardsError,
	} = useWalletCardsQuery({
		notifyOnNetworkStatusChange: true,
		variables: {
			...paginationVariables,
			...searchVariables,
			...(tenantUserUid && { tenantUserUid }),
			...transactionTimeVariables,
			order: getStoredOrder(),
		},
		onError: (error) => handleCardsError(error),
	});
	const {
		tenantUser,
		loading: tenantUserLoading,
		error: tenantUserError,
	} = useTenantUser();
	const [exportCards, { loading: exportCardsLoading }] = useExportCards({
		onAsyncExport: () => setOpen(true),
	});
	const { role, loading: roleLoading } = useRole();
	// REVIEW: `cards` is non nullable but sometimes page blank occurs, so those have optional chaining to avoid it.
	const hasNextPage =
		!!data?.currentOffice.wallet?.cards?.pageInfo?.hasNextPage;
	const hasPreviousPage =
		!!data?.currentOffice.wallet?.cards?.pageInfo?.hasPreviousPage;
	const startCursor = data?.currentOffice.wallet?.cards?.pageInfo?.startCursor;
	const endCursor = data?.currentOffice.wallet?.cards?.pageInfo?.endCursor;
	const cards = (data?.currentOffice.wallet?.cards?.edges ?? []).reduce<
		CardRowDetails[]
		// biome-ignore lint/performance/noAccumulatingSpread: TODO
	>((results, edge) => (edge?.node ? [...results, edge.node] : results), []);
	const isAdmin = role === RoleEnum.Admin;
	const isPaginationVisible = hasPreviousPage || hasNextPage;
	const totalCount = data?.currentOffice.wallet?.totalCards?.totalCount ?? 0;
	const maximumCreatableCardLimit =
		data?.currentOffice.wallet?.maximumCreatableCardLimit ?? 0;

	const paginationStatus = (): PaginationLayoutType => {
		return cards.length > 0 ? 'both' : 'onlyExport';
	};
	const PaginationLayout = paginationLayouts[paginationStatus()];
	const handleOrderChange = (newSortOrder: CardsOrder | null) => {
		refetch({ order: newSortOrder });
	};

	return (
		<>
			{cardsError && isInvalidLastFour(cardsError) && (
				<Notification isOpen showSidebar color="error" align="center">
					4桁の半角数字を入力してください
				</Notification>
			)}
			{cardsError && isInvalidSerialCardNumber(cardsError) && (
				<Notification isOpen showSidebar color="error" align="center">
					9文字以内の半角英数字を入力してください
				</Notification>
			)}
			<PageLayout
				title={
					<TitleLayout>
						カード
						{tenantUser && (
							<TenantUserTitle displayName={tenantUser.displayName} />
						)}
					</TitleLayout>
				}
				{...(isAdmin && {
					buttons: <CreateCardButton />,
				})}
			>
				<Head title="カード" />

				{isAdmin && <CardsSearchPanel isCorporate={isCorporate} />}

				<CardsInformationMessage />

				{cards.length > 0 && (
					<PaginationLayout>
						<LeftActionsLayout>
							{isPaginationVisible && (
								<Pagination
									endCursor={endCursor}
									startCursor={startCursor}
									hasPreviousPage={hasPreviousPage}
									hasNextPage={hasNextPage}
								/>
							)}
							<CardSortSelect onOrderChange={handleOrderChange} />
							{isAdmin && (
								<NumberOfCardLimitText
									maximumCreatableCardLimit={maximumCreatableCardLimit}
									totalCount={totalCount}
								/>
							)}
						</LeftActionsLayout>

						<RightActionsLayout>
							{isAdmin && (
								<Tooltip
									content={
										<>
											ダウンロードしたCSVファイルを編集し
											<br />
											アップロードすると一括更新が行えます
										</>
									}
								>
									<ButtonWrapper>
										<Button
											type="button"
											size="small"
											variant="outline"
											onClick={() => router.push('/cards/bulk-update')}
										>
											CSV一括更新
										</Button>
									</ButtonWrapper>
								</Tooltip>
							)}
							<Dialog open={open}>
								<Dialog.Trigger asChild>
									<ButtonWrapper>
										<Button
											variant="outline"
											size="small"
											onClick={() =>
												exportCards({
													...searchVariables,
													...(tenantUserUid && { tenantUserUid }),
													order: getStoredOrder(),
												})
											}
											loading={exportCardsLoading}
										>
											CSVエクスポート
										</Button>
									</ButtonWrapper>
								</Dialog.Trigger>
								<CSVExportDialog onClose={() => setOpen(false)} />
							</Dialog>
						</RightActionsLayout>
					</PaginationLayout>
				)}

				<ErrorContainer>
					{tenantUserError && <TenantUserError />}
					<GlobalMessage />
				</ErrorContainer>

				<CardsTable
					loading={
						cardsLoading ||
						tenantTypeLoading ||
						tenantUserLoading ||
						roleLoading
					}
					error={cardsError || tenantTypeError || tenantUserError}
					cards={cards}
					isCorporate={isCorporate}
					role={role}
				/>
				{isPaginationVisible && (
					<BottomPaginationLayout>
						<Pagination
							endCursor={endCursor}
							startCursor={startCursor}
							hasPreviousPage={hasPreviousPage}
							hasNextPage={hasNextPage}
						/>
					</BottomPaginationLayout>
				)}
				<HighlightAnnouncement />
			</PageLayout>
		</>
	);
};
