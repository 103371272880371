import { PointBalance as PointBalanceUi } from '@/components/ui/PointBalance';
import { useWalletPointBalanceQuery } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';

export const PointBalance = (): JSX.Element => {
	const { handleQueryError } = useApiError();
	const { data, loading } = useWalletPointBalanceQuery({
		onError: handleQueryError,
	});

	const balance = Number(data?.currentOffice.wallet?.point?.balance);

	return (
		<PointBalanceUi
			loading={loading}
			// biome-ignore lint/suspicious/noGlobalIsNan: This is a valid case
			pointBalance={isNaN(balance) ? undefined : balance}
		/>
	);
};
