import { Table } from '@/components/new/Table';
import type { Account } from '@/graphql';
import { PostPayAccountRow } from './PostPayAccountRow';
import { PostPayLimitRow } from './PostPayLimitRow';
import { PostPayPhoneNumberRow } from './PostPayPhoneNumberRow';

type TableProps = {
	accounts: Account[];
};

export const PostPayApplicationTable = ({
	accounts,
}: TableProps): JSX.Element => {
	return (
		<Table.Root>
			<Table.Head>
				<Table.Row>
					<Table.ColumnHeaderCell colSpan={2}>利用申込</Table.ColumnHeaderCell>
				</Table.Row>
			</Table.Head>
			<Table.Body>
				<PostPayLimitRow />
				<PostPayAccountRow accounts={accounts} />
				<PostPayPhoneNumberRow />
			</Table.Body>
		</Table.Root>
	);
};
