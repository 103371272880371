import {
	type Card,
	type OfficeMemberCardsFragment,
	type OfficeMemberDetailsFragment,
	RoleEnum,
} from '@/graphql';

export const ROLE_ALL = `${RoleEnum.Admin},${RoleEnum.Employee}` as const;

// TODO refactor after api update
export type FormValues = {
	displayName?: string;
	role: RoleEnum | typeof ROLE_ALL;
};

export type Users = (OfficeMemberDetailsFragment & OfficeMemberCardsFragment)[];
export type OfficeMemberCard = Pick<Card, 'id' | 'name' | 'lastFour'>;
