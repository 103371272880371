import { Table } from '@/components/new/Table';
import type { Maybe, Prefecture } from '@/graphql';
import { getPrefectureLabel } from '@/lib/prefecture';
import { transformQueryResult } from '@/lib/transform';

type Props = {
	buildingName?: Maybe<string>;
	city: string;
	postalCode: string;
	prefectureCode: Prefecture;
	street: string;
};

export const AddressRow = ({
	buildingName,
	city,
	postalCode,
	prefectureCode,
	street,
}: Props): JSX.Element => {
	return (
		<Table.Row>
			<Table.RowHeaderCell>住所</Table.RowHeaderCell>
			<Table.Cell>
				<span>
					{`${transformQueryResult.postalCode(postalCode)} ${getPrefectureLabel(prefectureCode)}`}
					{city}&nbsp;
					{street}
				</span>

				{buildingName && (
					<>
						<br />
						{buildingName}
					</>
				)}
			</Table.Cell>
		</Table.Row>
	);
};
