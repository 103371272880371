import { Link } from '@/components/new/Link';
import type { LoginOfficesTable_UserBelongingFragment } from '@/graphql';
import { getCreateOfficeUrl } from '@/lib/navis';
import styled from 'styled-components';
import { LoginOfficesTableRow } from './LoginOfficesTableRow';

type Props = {
	userBelongings: LoginOfficesTable_UserBelongingFragment[];
};

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  tr:last-child td {
    border: none;
  }
`;

const Row = styled.tr`
  border-bottom: 1px solid #dce0e6;
`;

const OfficeCreateLinkCell = styled.td`
  padding: 16px;
`;

export const LoginOfficesTable = ({ userBelongings }: Props): JSX.Element => {
	// TODO: currentUser.emailがとれるようになったら対応
	const email = '';
	const returnPath = '/registration/terms';

	return (
		<Table>
			<tbody>
				{userBelongings.map((userBelonging) => (
					<LoginOfficesTableRow
						key={userBelonging.tenant.uid}
						tenant={userBelonging.tenant}
					/>
				))}
				<Row>
					<OfficeCreateLinkCell>
						<Link href={getCreateOfficeUrl(email, returnPath)}>
							新しく事業者を追加
						</Link>
					</OfficeCreateLinkCell>
				</Row>
			</tbody>
		</Table>
	);
};
