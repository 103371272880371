import { cardDepartmentNameSchema } from '@/components/ui/inputs';
import { tenantUsersSchema } from '@/components/ui/inputs/TenantUsers/schema';
import type { UpdateCardHoldersInput } from '@/graphql';
import { yup } from '@/lib/yup';

export type FormData = Pick<
	UpdateCardHoldersInput,
	'departmentName' | 'memo'
> & {
	tenantUsers: {
		label: string;
		value: string;
	}[];
};
export const schema: yup.ObjectSchema<FormData> = yup
	.object({})
	.concat(tenantUsersSchema)
	.concat(cardDepartmentNameSchema)
	.concat(
		yup.object({
			memo: yup.string().max(100, '100文字以内で入力してください').optional(),
		}),
	);
