import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { NavisSsoLink } from '@/components/ui/NavisSsoLink';
import { TenantUsersInput } from '@/components/ui/inputs/TenantUsers';
import { addUserUrl } from '@/lib/navis';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import type { FormData } from '../schema';

const Box = styled.div`
  margin-bottom: 8px;
`;

export const CardMemoInput = (): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<FormData>();

	return (
		<>
			<TextField aria-label="memo" {...register('memo')} />
			{errors.memo && <FormError>{errors.memo.message}</FormError>}
		</>
	);
};

export const CardTenantUserInput = (): JSX.Element => {
	const { trigger } = useFormContext<FormData>();
	const link = new URL(addUserUrl);

	// Perform validation on tenant users as it might be empty
	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	useEffect(() => {
		trigger('tenantUsers');
	}, []);

	return (
		<>
			<Box>
				ユーザーが存在しない場合は
				<NavisSsoLink link={link} />
				から追加してください。
			</Box>
			<TenantUsersInput />
		</>
	);
};
