import { StatusLabel } from '@/components/new/StatusLabel';
import { Table } from '@/components/new/Table';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError/FormError';
import { AddressInputs } from '@/components/ui/inputs/Address/AddressInputs';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Title = styled.span`
  margin-right: 8px;
  font-weight: bold;
`;

const TextFieldWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 180px 1fr;
  background-color: #fff;
  border: 1px solid #d4d8dd;
  border-radius: 4px;
`;

const Column = styled.div`
  padding: 8px;
  align-self: center;
`;

export const DestinationInputs = (): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<TableWrapper>
			<Wrapper>
				<Column>
					<Title>管理名称</Title>
				</Column>
				<Column>
					<TextFieldWrapper>
						<TextField
							aria-label="label"
							{...register('label')}
							placeholder="支社名など"
						/>
						{errors.label && <FormError>{errors.label.message}</FormError>}
					</TextFieldWrapper>
				</Column>
			</Wrapper>
			<Table.Root>
				<Table.Head>
					<Table.Row>
						<Table.ColumnHeaderCell colSpan={2}>宛名</Table.ColumnHeaderCell>
					</Table.Row>
				</Table.Head>
				<Table.Body>
					<Table.Row>
						<Table.RowHeaderCell width="180px" bold>
							<Title>会社名</Title>
							<StatusLabel color="gray">任意</StatusLabel>
						</Table.RowHeaderCell>
						<Table.Cell>
							<TextFieldWrapper>
								<TextField aria-label="tradeName" {...register('tradeName')} />
								{errors.tradeName && (
									<FormError>{errors.tradeName.message}</FormError>
								)}
							</TextFieldWrapper>
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.RowHeaderCell width="180px" bold>
							<Title>部署名</Title>
							<StatusLabel color="gray">任意</StatusLabel>
						</Table.RowHeaderCell>
						<Table.Cell>
							<TextFieldWrapper>
								<TextField
									aria-label="departmentName"
									{...register('departmentName')}
								/>
								{errors.departmentName && (
									<FormError>{errors.departmentName.message}</FormError>
								)}
							</TextFieldWrapper>
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.RowHeaderCell width="180px" bold>
							<Title>担当者名</Title>
						</Table.RowHeaderCell>
						<Table.Cell>
							<TextFieldWrapper>
								<TextField
									aria-label="destinationName"
									{...register('destinationName')}
								/>
								{errors.destinationName && (
									<FormError>{errors.destinationName.message}</FormError>
								)}
							</TextFieldWrapper>
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.RowHeaderCell width="180px" bold>
							<Title>住所</Title>
						</Table.RowHeaderCell>
						<Table.Cell>
							<AddressInputs />
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table.Root>
		</TableWrapper>
	);
};
