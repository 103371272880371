import { useCreditLineWidgetQuery } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import styled from 'styled-components';
import { Card } from '../Card';
import { getIsReapplicationEnabled } from '../_lib/helper';
import { PostPayLink } from './PostPayLink';
import { Status } from './Status';

const FallbackSpace = styled.div`
  height: 72px;
`;

const Heading = styled.p`
  margin: 0;
  font-size: 14px;
  color: #666;
`;

const AmountWrapper = styled.div`
  display: flex;
  gap: 8px;
  height: 32px; // NOTE: for aligning with other widgets
`;

const Amount = styled.p`
  margin: 0;
  font-size: 32px;
  display: inline-flex;
  line-height: 1;
  align-items: baseline;
  font-weight: 500;
`;

const Unit = styled.span`
  font-size: 16px;
  position: relative;
  margin-left: 4px;
`;

export const CreditLineWidget = (): JSX.Element => {
	const { handleQueryError } = useApiError();
	const { data, loading } = useCreditLineWidgetQuery({
		onError: handleQueryError,
		fetchPolicy: 'cache-and-network',
	});

	if (loading) {
		return (
			<Card.Root>
				<FallbackSpace>
					<Card.Fallback />
				</FallbackSpace>
			</Card.Root>
		);
	}

	const { postPaySetting } = { ...data?.currentOffice.wallet };
	const { status, createdAt, startDate } = {
		...data?.currentOffice.postPayRequest,
	};
	// NOTE: status ref => https://docs.google.com/spreadsheets/d/1w09-4_TfSkJm_HdTFc2vOUD_Xn2rQCCWsbKmxwQdZ7Q/edit#gid=1218611599
	const isReapplicationEnabled = getIsReapplicationEnabled(createdAt);
	const creditLineAmount = postPaySetting?.creditLine ?? 0;

	return (
		<Card.Root>
			<Card.Head>
				<Heading>与信限度額</Heading>
				<PostPayLink
					isPostPayEnabled={!!postPaySetting}
					status={status}
					isReapplicationEnabled={isReapplicationEnabled}
				/>
			</Card.Head>
			<Card.Body>
				<AmountWrapper>
					{/* NOTE: Show 0 or actual number if error is not occured */}
					{postPaySetting === null || postPaySetting ? (
						<Amount>
							{creditLineAmount.toLocaleString()}
							<Unit>円</Unit>
						</Amount>
					) : (
						<Amount data-testid="creditLineError">
							-<Unit>円</Unit>
						</Amount>
					)}
					{!postPaySetting && (
						<Status
							status={status}
							startDate={startDate}
							isReapplicationEnabled={isReapplicationEnabled}
						/>
					)}
				</AmountWrapper>
			</Card.Body>
		</Card.Root>
	);
};
