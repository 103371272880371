import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { useFormContext } from 'react-hook-form';
import type { CorporateNameData } from './schema';

export const CorporateNameInput = (): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<CorporateNameData>();

	return (
		<>
			<TextField aria-label="name" {...register('name')} />
			{errors.name && <FormError>{errors.name.message}</FormError>}
		</>
	);
};
