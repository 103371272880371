import { Link } from '@/components/new/Link';
import styled from 'styled-components';

type Props = {
	icon: 'edit' | 'checkmark';
	link: string;
	disabled?: boolean;
};

const Wrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	'& > a': {
		opacity: 1,
		width: '28px',
		height: '28px',
		textDecoration: 'none',
	},
});

const LinkText = styled.span({
	display: 'inline-flex',
	alignItems: 'center',
});

export const StepIcon = ({ icon, link, disabled = false }: Props) => {
	return (
		<Wrapper>
			{icon === 'checkmark' ? (
				<Link href={link} target="_blank" disabled={disabled}>
					<LinkText>
						<svg
							width="28"
							height="28"
							viewBox="0 0 28 28"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							aria-hidden="true"
							aria-disabled={disabled}
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M14.0007 2.33301C7.56065 2.33301 2.33398 7.55967 2.33398 13.9997C2.33398 20.4397 7.56065 25.6663 14.0007 25.6663C20.4407 25.6663 25.6673 20.4397 25.6673 13.9997C25.6673 7.55967 20.4407 2.33301 14.0007 2.33301ZM11.6673 19.833L5.83398 13.9997L7.47898 12.3547L11.6673 16.5313L20.5223 7.67634L22.1673 9.33301L11.6673 19.833Z"
								fill="#3B7DE9"
							/>
						</svg>
					</LinkText>
				</Link>
			) : (
				<Link href={link} target="_blank" disabled={disabled}>
					<LinkText>
						<svg
							width="28"
							height="28"
							viewBox="0 0 28 28"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							aria-hidden="true"
							aria-disabled={disabled}
						>
							<rect
								x="2"
								y="2"
								width="23.33"
								height="23.33"
								rx="11.665"
								fill={disabled ? '#D8DADE' : '#3B7DE9'}
							/>
							<path
								d="M7.5 16.9997V19.4997H10L17.3733 12.1264L14.8733 9.62638L7.5 16.9997ZM19.3067 10.193C19.5667 9.93305 19.5667 9.51305 19.3067 9.25305L17.7467 7.69305C17.4867 7.43305 17.0667 7.43305 16.8067 7.69305L15.5867 8.91305L18.0867 11.413L19.3067 10.193Z"
								fill="#ffffff"
							/>
						</svg>
					</LinkText>
				</Link>
			)}
		</Wrapper>
	);
};
