import { useUnsettledAmountWidgetQuery } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import styled from 'styled-components';
import { Card } from '../Card';
import { BillingTable } from './BillingTable';

const FallbackSpace = styled.div`
  height: 250px;
`;

const Heading = styled.p`
  margin: 0;
  font-size: 14px;
  color: #666;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
`;

const Amount = styled.p`
  margin: 0;
  font-size: 32px;
  display: inline-flex;
  line-height: 1;
  align-items: baseline;
  font-weight: 500;
`;

const Unit = styled.span`
  font-size: 16px;
  position: relative;
  margin-left: 4px;
`;

export const UnsettledAmountWidget = (): JSX.Element => {
	const { handleQueryError } = useApiError();
	const { data, loading } = useUnsettledAmountWidgetQuery({
		onError: handleQueryError,
		fetchPolicy: 'cache-and-network',
	});

	if (loading) {
		return (
			<Card.Root>
				<FallbackSpace>
					<Card.Fallback />
				</FallbackSpace>
			</Card.Root>
		);
	}

	const { value, postPaySetting, postPaidBillings, estimatedPostPaidBilling } =
		{ ...data?.currentOffice.wallet };

	const amount = Number(value?.postPaidAmount);
	const latestPostPaidBilling = postPaidBillings?.nodes?.[0];

	return (
		<Card.Root>
			<Card.Head>
				<Heading>未清算金額</Heading>
			</Card.Head>
			<Card.Body>
				<Layout>
					<div>
						{/* biome-ignore lint/suspicious/noGlobalIsNan: This is a valid case */}
						{isNaN(amount) ? (
							<Amount data-testid="unsettledAmountError">
								-<Unit>円</Unit>
							</Amount>
						) : (
							<Amount>
								{amount.toLocaleString()}
								<Unit>円</Unit>
							</Amount>
						)}
					</div>
					{/* NOTE: Do not render if query error has occured */}
					{(estimatedPostPaidBilling || latestPostPaidBilling) && (
						<BillingTable
							latestPostPaidBilling={latestPostPaidBilling}
							estimatedPostPaidBilling={estimatedPostPaidBilling}
							isPostPayEnabled={!!postPaySetting}
						/>
					)}
				</Layout>
			</Card.Body>
		</Card.Root>
	);
};
