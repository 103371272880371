import { Text } from '@/components/new/Text';
import { RegistrationTable } from '@/components/ui/RegistrationTable';
import { RegistrationTableRow } from '@/components/ui/RegistrationTableRow';
import {
	AddressInputs,
	BirthDateInput,
	CorporateNameEnInput,
	CorporateNameInput,
	CorporateNameKanaInput,
	CorporateNumberInput,
	EstablishmentDateInput,
	IndustryInput,
	InputGroup,
	IsListedInput,
	PhoneNumberInput,
	PurposeInput,
	RepresentativeFirstNameEnInput,
	RepresentativeFirstNameInput,
	RepresentativeFirstNameKanaInput,
	RepresentativeLastNameEnInput,
	RepresentativeLastNameInput,
	RepresentativeLastNameKanaInput,
	RepresentativeMiddleNameEnInput,
	WebsiteInput,
} from '@/components/ui/inputs';
import { AssetsAmountInput } from '@/components/ui/inputs/AssetsAmount';
import { RepresentativeAddressInputs } from '@/components/ui/inputs/RepresentativeAddress';
import type { RepresentativeFirstNameKanaData } from '@/components/ui/inputs/RepresentativeFirstNameKana/schema';
import type { RepresentativeLastNameKanaData } from '@/components/ui/inputs/RepresentativeLastNameKana/schema';
import { RepresentativeNationalityInput } from '@/components/ui/inputs/RepresentativeNationality';
import { SalesAmountInput } from '@/components/ui/inputs/SalesAmount';
import { UsagePurposesInput } from '@/components/ui/inputs/UsagePurpose';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const CautionText = styled.div({
	color: '#ec4949',
});

const Box = styled.div`
  margin-bottom: 8px;
`;

const MiddleNameWrapper = styled.div`
  margin-top: 8px;
`;

const InputWrapper = styled.div`
  width: 260px;
`;

export const Inputs = (): JSX.Element => {
	const { setValue: setValueForLastName } =
		useFormContext<RepresentativeLastNameKanaData>();

	const { setValue: setValueForFirstName } =
		useFormContext<RepresentativeFirstNameKanaData>();

	const updateRepresentativeLastNameKana = (value: string) => {
		setValueForLastName('representativeLastNameKana', value);
	};

	const updateRepresentativeFirstNameKana = (value: string) => {
		setValueForFirstName('representativeFirstNameKana', value);
	};

	return (
		<RegistrationTable header="法人情報">
			<RegistrationTableRow title="法人番号">
				<CorporateNumberInput />
			</RegistrationTableRow>
			<RegistrationTableRow title="法人名">
				<CorporateNameInput />
			</RegistrationTableRow>
			<RegistrationTableRow title="法人名（フリガナ）">
				<CorporateNameKanaInput />
			</RegistrationTableRow>
			<RegistrationTableRow title="法人名（英語表記）">
				<Box>
					<Text size="small">
						「Co.,Ltd.」「Inc.」「LLC」などの法人格を除いて入力してください。
					</Text>
				</Box>
				<CorporateNameEnInput />
			</RegistrationTableRow>
			<RegistrationTableRow title="法人住所">
				<Box>
					<CautionText>
						<Text size="small" color="inherit">
							原則、1枚目のカードは登記住所で受け取っていただく必要があります。
							<br />
							こちらの住所以外でのカードの受け取りはできないため、転送設定をしている場合は、設定を解除してください。
						</Text>
					</CautionText>
				</Box>
				<AddressInputs />
			</RegistrationTableRow>
			<RegistrationTableRow title="設立年月日">
				<EstablishmentDateInput />
			</RegistrationTableRow>
			<RegistrationTableRow title="法人電話番号">
				<Box>
					<Text size="small">
						固定電話がある場合は固定電話の番号を入力してください。
					</Text>
				</Box>
				<PhoneNumberInput />
			</RegistrationTableRow>
			<RegistrationTableRow title="ホームページURL" labelType="optional">
				<WebsiteInput />
			</RegistrationTableRow>
			<RegistrationTableRow title="代表者氏名">
				<InputGroup>
					<RepresentativeLastNameInput
						updateValue={updateRepresentativeLastNameKana}
					/>
					<RepresentativeFirstNameInput
						updateValue={updateRepresentativeFirstNameKana}
					/>
				</InputGroup>
			</RegistrationTableRow>
			<RegistrationTableRow title="代表者氏名（フリガナ）">
				<InputGroup>
					<RepresentativeLastNameKanaInput />
					<RepresentativeFirstNameKanaInput />
				</InputGroup>
			</RegistrationTableRow>
			<RegistrationTableRow title="代表者氏名（英語表記）">
				<InputGroup>
					<RepresentativeLastNameEnInput />
					<RepresentativeFirstNameEnInput />
				</InputGroup>
				<MiddleNameWrapper>
					<RepresentativeMiddleNameEnInput />
				</MiddleNameWrapper>
			</RegistrationTableRow>
			<RegistrationTableRow title="代表者生年月日">
				<BirthDateInput />
			</RegistrationTableRow>
			<RegistrationTableRow title="代表者住所">
				<RepresentativeAddressInputs />
			</RegistrationTableRow>
			<RegistrationTableRow title="代表者国籍">
				<RepresentativeNationalityInput />
			</RegistrationTableRow>
			<RegistrationTableRow title="法人売上額">
				<Box>
					<Text size="small">
						直前期の損益計算書の数字を入力してください。一度も決算期が到来していない場合は、期末見通しの数字を入力してください。
					</Text>
				</Box>
				<InputWrapper>
					<SalesAmountInput />
				</InputWrapper>
			</RegistrationTableRow>
			<RegistrationTableRow title="法人資産額">
				<Box>
					<Text size="small">
						直前期の貸借対照表の数字を入力してください。一度も決算期が到来していない場合は、期末見通しの数字を入力してください。
					</Text>
				</Box>
				<InputWrapper>
					<AssetsAmountInput />
				</InputWrapper>
			</RegistrationTableRow>
			<RegistrationTableRow title="事業内容">
				<IndustryInput />
			</RegistrationTableRow>
			<RegistrationTableRow title="取引目的">
				<PurposeInput />
			</RegistrationTableRow>
			<RegistrationTableRow title="利用目的">
				<UsagePurposesInput />
			</RegistrationTableRow>
			<RegistrationTableRow title="上場・非上場の確認">
				<Box>
					<Text size="small">
						上場している場合、実質的支配者情報の入力が省略されます。
					</Text>
				</Box>
				<IsListedInput />
			</RegistrationTableRow>
		</RegistrationTable>
	);
};
