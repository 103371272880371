import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { useFormContext } from 'react-hook-form';
import type { CardDepartmentNameData } from './schema';

export const CardDepartmentNameInput = (): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<CardDepartmentNameData>();

	return (
		<>
			<TextField aria-label="departmentName" {...register('departmentName')} />
			{errors.departmentName && (
				<FormError>{errors.departmentName.message}</FormError>
			)}
		</>
	);
};
