import { CardTypeCategory, type XardCardRequestInput } from '@/graphql';
import { yup } from '@/lib/yup';
import { produce } from 'immer';

type Pin = Pick<XardCardRequestInput, 'pin'>;
export const pinSchema: yup.ObjectSchema<Pin> = yup
	.object({
		pin: yup.string().when('cardType', ([cardType], schema) =>
			cardType === CardTypeCategory.Physical
				? schema
						.trim()
						// 全角 -> 半角
						.transform((value: string) =>
							value.replace(/[０-９]/g, (s) =>
								String.fromCharCode(s.charCodeAt(0) - 0xfee0),
							),
						)
						.matches(/^\d{4}$/, '4文字の半角数字で入力してください')
				: schema.strip(),
		),
	})
	.transform((value: Pin) =>
		produce(value, (draft) => {
			if ([null, undefined, ''].includes(draft.pin)) {
				draft.pin = undefined;
			}
		}),
	);

type CorporateName = Pick<XardCardRequestInput, 'corporateName'>;
export const corporateNameSchema: yup.ObjectSchema<CorporateName> = yup
	.object({
		corporateName: yup.string().when('cardType', ([cardType], schema) =>
			cardType === CardTypeCategory.Physical
				? schema
						.nullable()
						// "" -> null
						.transform((value: string) => (value === '' ? null : value))
						.officeCardName()
						.optional()
				: schema.strip(),
		),
	})
	.transform((value: CorporateName) =>
		produce(value, (draft) => {
			if ([null, undefined, ''].includes(draft.corporateName)) {
				draft.corporateName = undefined;
			}
		}),
	);
