import { DataTable } from '@/components/new/DataTable';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { Image } from '@/components/ui/Image';
import type { CardDestinationMasterDetailsFragment } from '@/graphql';
import { getPrefectureLabel } from '@/lib/prefecture';
import { transformQueryResult } from '@/lib/transform';
import type { ApolloError } from '@apollo/client';
import noDocuments from '@images/noDocuments.svg';
import styled from 'styled-components';
import { DeleteDestinationButton, EditDestinationButton } from './Buttons';

const TableWrapper = styled.div`
  table {
    width: fit-content;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const StyledText = styled(Text)`
  text-align: center;
`;

export type DestinationsTableProps = {
	loading: boolean;
	error: ApolloError | undefined;
	destinations: CardDestinationMasterDetailsFragment[];
	onDelete: (id: string) => void;
};

export const DestinationsTable = ({
	loading,
	error,
	destinations,
	onDelete,
}: DestinationsTableProps): JSX.Element => (
	<TableWrapper>
		<DataTable
			loading={loading}
			error={error}
			items={destinations}
			noItemsIllustration={
				<Image
					src={noDocuments}
					alt="no destinations"
					width={250}
					height={200}
				/>
			}
			noItemsMessage={
				<StyledText color="notes">
					送付先を登録しておくと、リアルカード発行時に
					<br />
					宛先の入力を省略することができます
				</StyledText>
			}
			thead={
				<Table.Row>
					<Table.ColumnHeaderCell>管理名称</Table.ColumnHeaderCell>
					<Table.ColumnHeaderCell>宛名</Table.ColumnHeaderCell>
					<Table.ColumnHeaderCell />
				</Table.Row>
			}
			renderItem={(item) => (
				<Table.Row>
					<Table.Cell width="250px" size="large">
						{item.label}
					</Table.Cell>
					<Table.Cell size="large">
						{item.postalCode &&
							transformQueryResult.postalCode(item.postalCode)}
						&nbsp;
						{item.prefectureCode && getPrefectureLabel(item.prefectureCode)}
						{item.city}&nbsp;
						{item.street}&nbsp;{item.buildingName}
						<br />
						{item.tradeName && (
							<>
								{item.tradeName}
								<br />
							</>
						)}
						{item.departmentName && `${item.departmentName} `}
						{item.destinationName} 宛
					</Table.Cell>
					<Table.Cell width="128px" size="large">
						<ButtonWrapper>
							<EditDestinationButton id={item.id} />
							<DeleteDestinationButton id={item.id} onDelete={onDelete} />
						</ButtonWrapper>
					</Table.Cell>
				</Table.Row>
			)}
		/>
	</TableWrapper>
);
