import { Prefecture } from '@/graphql';
import { yup } from '@/lib/yup';

export type AddressData = yup.Asserts<typeof representativeAddressSchema>;

export const representativeAddressSchema = yup.object({
	representativeBuildingName: yup.string().nullable().default(null).max(50),
	representativeCity: yup.string().required().max(20),
	representativePostalCode: yup.string().postalCode().required(),
	representativePrefectureCode: yup
		.mixed<Prefecture>()
		.oneOf(Object.values(Prefecture), '選択してください')
		.required('選択してください'),
	representativeStreet: yup.string().max(25).required(),
});
