import { Table } from '@/components/new/Table';
import { MaximumPaymentLimitInput } from '@/components/ui/inputs';
import { type CardControl, CardState, RoleEnum } from '@/graphql';
import { CurrencyFormat } from '@/lib/i18n';

export type MaximumPaymentLimitRowProps = Pick<
	CardControl,
	'maximumPaymentLimit'
> & {
	state: CardState;
	role: RoleEnum | undefined;
};

export const MaximumPaymentLimitRow = ({
	maximumPaymentLimit,
	state,
	role,
}: MaximumPaymentLimitRowProps): JSX.Element => {
	const isText =
		state === CardState.Terminated ||
		state === CardState.Unactivated ||
		role === RoleEnum.Employee;

	return (
		<Table.Row>
			<Table.RowHeaderCell>一回の上限金額</Table.RowHeaderCell>
			<Table.Cell width="440px" size={isText ? 'large' : 'medium'}>
				{isText ? (
					CurrencyFormat.jp.format(maximumPaymentLimit)
				) : (
					<MaximumPaymentLimitInput />
				)}
			</Table.Cell>
		</Table.Row>
	);
};
