import { Text } from '@/components/new/Text';
import { Image } from '@/components/ui/Image';
import { usePostPayGraphQuery } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { CurrencyFormat } from '@/lib/i18n';
import queryError from '@images/queryError.svg';
import NextLink from 'next/link';
import {
	Bar,
	BarChart,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	type TooltipProps,
	XAxis,
	YAxis,
} from 'recharts';
import type {
	NameType,
	ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import styled from 'styled-components';
import { formatDateToYearMonth, generateMonthsArray } from './libs';

const Wrapper = styled.div``;

const TooltipContent = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
  text-align: center;
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px;
`;

const Heading = styled.h3`
  font-size: 14px;
  margin: 0;
  font-weight: 500;
  color: #666;
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const Link = styled.a`
  font-size: 13px;
  text-decoration: none;
  color: #3b7de9;
  &:hover {
    opacity: 0.8;
  }
`;

const CustomTooltip = ({
	active,
	payload,
}: {
	active: boolean | undefined;
	payload: {
		originalAmount: number;
		valueDepositedAmount: number;
		amount: number;
		total: number;
		isCurrentMonth: boolean;
		isFutureMonth: boolean;
	};
	label: string;
}) => {
	if (!active || !payload || payload.isFutureMonth) return <></>;

	if (payload.isCurrentMonth) {
		return (
			<TooltipContent>
				合計: {CurrencyFormat.jp.format(payload.total)}
			</TooltipContent>
		);
	}
	return (
		<TooltipContent>
			利用額: {CurrencyFormat.jp.format(payload.originalAmount)}
			<br />
			ウォレット: {CurrencyFormat.jp.format(payload.valueDepositedAmount)}
			<br />
			引落用口座: {CurrencyFormat.jp.format(payload.amount)}
		</TooltipContent>
	);
};

const renderToolTip = (value: TooltipProps<ValueType, NameType>) => {
	return (
		<CustomTooltip
			active={value.active}
			payload={value.payload?.[0]?.payload.value}
			label={value.label}
		/>
	);
};

const Header = (): JSX.Element => {
	return (
		<HeadingContainer>
			<Heading>利用履歴</Heading>
			<NextLink href="/postpay/transactions" passHref>
				<Link>一覧を見る</Link>
			</NextLink>
		</HeadingContainer>
	);
};

const ErrorView = (): JSX.Element => (
	<ErrorWrapper>
		<Image src={queryError} alt="" width={250} height={200} />
		<Text color="notes">読み込みエラー</Text>
	</ErrorWrapper>
);

export const PostPayGraph = (): JSX.Element | null => {
	const { handleQueryError } = useApiError();
	const { data, loading } = usePostPayGraphQuery({
		onError: handleQueryError,
	});

	if (loading) return null;

	if (
		!data?.currentOffice.wallet?.estimatedPostPaidBilling ||
		!data?.currentOffice.wallet?.postPaidBillings
	)
		return (
			<>
				<Header />
				<ErrorView />
			</>
		);

	const currentMonth =
		data.currentOffice.wallet.estimatedPostPaidBilling.targetMonth;
	const currentMonthValue =
		data.currentOffice.wallet.estimatedPostPaidBilling.amount;
	// MEMO: Under 11, there is no data for 12 months
	const totalCount = data.currentOffice.wallet.postPaidBillings.totalCount;

	const futureMonths = generateMonthsArray(currentMonth, 11 - totalCount).map(
		(date) => ({
			date,
			value: {
				originalAmount: 0,
				valueDepositedAmount: 0,
				amount: 0,
				total: undefined,
				isCurrentMonth: false,
				isFutureMonth: true,
			},
		}),
	);

	return (
		<Wrapper>
			<Header />
			<ResponsiveContainer height={320}>
				<BarChart
					data={
						totalCount < 11
							? [
									...(data.currentOffice.wallet.postPaidBillings.nodes
										?.map((node) => ({
											date: formatDateToYearMonth(node?.targetMonth),
											value: {
												originalAmount: node?.originalAmount,
												valueDepositedAmount: node?.valueDepositedAmount,
												amount: node?.amount,
												total: node?.originalAmount,
												isCurrentMonth: false,
												isFutureMonth: false,
											},
										}))
										.reverse() ?? []),
									// NOTE: This month
									{
										date: formatDateToYearMonth(currentMonth),
										value: {
											originalAmount: undefined,
											valueDepositedAmount: undefined,
											amount: undefined,
											total: currentMonthValue,
											isCurrentMonth: true,
											isFutureMonth: false,
										},
									},
									...futureMonths,
								]
							: [
									...(data.currentOffice.wallet.postPaidBillings.nodes
										?.map((node) => ({
											date: formatDateToYearMonth(node?.targetMonth),
											value: {
												originalAmount: node?.originalAmount,
												valueDepositedAmount: node?.valueDepositedAmount,
												amount: node?.amount,
												total: node?.originalAmount,
												isCurrentMonth: false,
												isFutureMonth: false,
											},
										}))
										.reverse() ?? []),
									// NOTE: This month
									{
										date: formatDateToYearMonth(currentMonth),
										value: {
											originalAmount: undefined,
											valueDepositedAmount: undefined,
											withdrawalAccount: undefined,
											total: currentMonthValue,
											isCurrentMonth: true,
											isFutureMonth: false,
										},
									},
								]
					}
					margin={{ top: 40, left: -40 }}
				>
					<XAxis
						dataKey="date"
						style={{
							color: '#999999',
							fontSize: '11px',
							lineHeight: '16px',
						}}
						tickLine={false}
						tickCount={12}
						axisLine={false}
					/>
					<YAxis ticks={[0]} axisLine={false} stroke="#999999" />
					<ReferenceLine y={0} stroke="#999999" />
					<Tooltip
						itemStyle={{ color: '#333333' }}
						contentStyle={{
							borderRadius: '4px',
							border: 'none',
							boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
						}}
						content={(value) => renderToolTip(value)}
						cursor={false}
						isAnimationActive={false}
					/>
					<Bar dataKey="value.total" fill="#3B7DE9" maxBarSize={32} />
				</BarChart>
			</ResponsiveContainer>
		</Wrapper>
	);
};
