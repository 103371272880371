import { type PostPayRequest, PostPayRequestStatusEnum } from '@/graphql';
import styled from 'styled-components';

type PostPayRequestSlim = Pick<PostPayRequest, 'status' | 'startDate'>;

type Props = {
	isReapplicationEnabled: boolean;
} & Partial<PostPayRequestSlim>;

const StatusLabel = styled.span`
  font-size: 12px;
  line-height: 1;
  background: #c4d8f8;
  padding: 0 16px;
  border-radius: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
`;

const dateOptions: Intl.DateTimeFormatOptions = {
	year: 'numeric',
	month: 'short',
	day: 'numeric',
};

export const Status = ({
	status,
	isReapplicationEnabled,
	startDate,
}: Props): JSX.Element | null => {
	switch (status) {
		case PostPayRequestStatusEnum.Rejected:
			if (isReapplicationEnabled) {
				// NOTE: Hide this for reapplication after one hour.
				return null;
			}
			// NOTE: Show this within one hour after the application is rejected.
			return <StatusLabel>審査済み</StatusLabel>;
		case PostPayRequestStatusEnum.RequestedAccountTransfer:
			// NOTE: During account transfer procedure.
			return <StatusLabel>口座振替手続き中</StatusLabel>;
		case PostPayRequestStatusEnum.Activated:
			// NOTE: After account transfer procedure.
			return (
				<StatusLabel>
					{startDate
						? new Date(startDate).toLocaleDateString('ja-JP', dateOptions)
						: '-'}
					付与予定
				</StatusLabel>
			);
		default:
			// NOTE: Show this status until the application is reviewed.
			return null;
	}
};
