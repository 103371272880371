import { SelectBox } from '@/components/new/SelectBox';
import { FormError } from '@/components/ui/FormError';
import { PlannedCardHolderCountSelectOptions } from '@/lib/plannedCardHolderCount';
import { Controller, useFormContext } from 'react-hook-form';
import type { PlannedCardHolderCountSchemaData } from './schema';

export const PlannedCardHolderCountInput = (): JSX.Element => {
	const {
		control,
		formState: { errors },
	} = useFormContext<PlannedCardHolderCountSchemaData>();

	return (
		<>
			<Controller
				control={control}
				name="plannedCardHolderCount"
				render={({ field }) => (
					<SelectBox
						{...field}
						aria-label="カードご利用予定人数"
						placeholder="選択してください"
						options={PlannedCardHolderCountSelectOptions}
						value={PlannedCardHolderCountSelectOptions.find(
							(option) => option.value === field.value,
						)}
						onValueChange={(value) => field.onChange(value)}
						selectedTestId="selected-plannedCardHolderCount"
						ariaControlsId="plannedCardHolderCount"
					/>
				)}
			/>
			{errors.plannedCardHolderCount && (
				<FormError>{errors.plannedCardHolderCount.message}</FormError>
			)}
		</>
	);
};
