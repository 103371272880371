import { StatusLabel } from '@/components/new/StatusLabel';
import { Table } from '@/components/new/Table';
import { PostPayLimitInput } from '@/components/ui/inputs/PostPayLimit';
import styled from 'styled-components';

const Box = styled.div`
  margin-right: 8px;
  display: inline-block;
`;

export const PostPayLimitRow = (): JSX.Element => {
	return (
		<Table.Row>
			<Table.RowHeaderCell width="240px">
				<Box>希望金額</Box>
				<StatusLabel color="red">必須</StatusLabel>
			</Table.RowHeaderCell>
			<Table.Cell>
				<PostPayLimitInput />
			</Table.Cell>
		</Table.Row>
	);
};
