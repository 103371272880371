import type { WalletCardsQueryVariables } from '@/graphql';
import { getBeginningOfTheMonth, getEndOfTheMonth } from '@/lib/date';

export const useTransactionTimeVariables = (): Pick<
	WalletCardsQueryVariables,
	'transactionTimeGte' | 'transactionTimeLte'
> => {
	const date = new Date(Date.now());
	const transactionTimeGte = getBeginningOfTheMonth(date);
	const transactionTimeLte = getEndOfTheMonth(date);

	return {
		transactionTimeGte,
		transactionTimeLte,
	};
};
