import { FlashMessage } from '@/components/new/FlashMessage';
import { type Control, type FieldError, useFormState } from 'react-hook-form';
import styled from 'styled-components';
import { type FormValues, INVALID_FILES_ERROR } from '../schema';

export type FormErrorsProps = {
	control: Control<FormValues>;
};

const Box = styled.div`
  margin-bottom: 18px;
`;

export const FormErrors = ({
	control,
}: FormErrorsProps): JSX.Element | null => {
	const { errors } = useFormState({
		control,
	});

	if (errors.corporateRegistrations) {
		return (
			<Box>
				<FlashMessage color="error">
					{Array.isArray(errors.corporateRegistrations)
						? INVALID_FILES_ERROR
						: (errors.corporateRegistrations as FieldError).message}
				</FlashMessage>
			</Box>
		);
	}

	return null;
};
