import { IndustryEnum } from '@/graphql';

export const IndustrySelectOptions: { value: IndustryEnum; label: string }[] = [
	// REVIEW: Remove deprecated values after API migration is done
	{ value: IndustryEnum.AgricultureAndForestry, label: '農業・林業' },
	{ value: IndustryEnum.Fisheries, label: '漁業' },
	{
		value: IndustryEnum.AgricultureAndForestryAndFisheries,
		label: '農業・林業・漁業',
	},
	{
		value: IndustryEnum.MiningAndQuarryingOfStoneAndGravel,
		label: '鉱業・採石業・砂利採取業',
	},
	{ value: IndustryEnum.Construction, label: '建設業（設備等工事業含む）' },
	{ value: IndustryEnum.Manufacturing, label: '製造業' },
	{
		value: IndustryEnum.ElectricityGasHeatSupplyAndWater,
		label: '電気・ガス・熱供給・水道業',
	},
	{ value: IndustryEnum.InformationAndCommunications, label: '情報通信業' },
	{ value: IndustryEnum.TransportAndPostalActivities, label: '運輸業・郵便業' },
	{ value: IndustryEnum.WholesaleAndRetailTrade, label: '卸売業・小売業' },
	{ value: IndustryEnum.FinanceAndInsurance, label: '金融業・保険業' },
	{ value: IndustryEnum.Transport, label: '運輸業' },
	{
		value:
			IndustryEnum.RetailAndWholesaleTradeOfPreciousMetalsJewelryArtworksAntiquesUsedVehicles,
		label: '小売業・卸売業（貴金属・宝石・美術品・古物・中古乗物）',
	},
	{
		value: IndustryEnum.OtherRetailAndWholesaleTrade,
		label: '小売業・卸売業（上記以外）',
	},
	{
		value:
			IndustryEnum.FinancialAndInsuranceBusinessesCryptoAssetExchangePawnshop,
		label: '金融業・保険業（暗号資産交換業・質屋）',
	},
	{
		value: IndustryEnum.OtherFinancialAndInsuranceBusinesses,
		label: '金融業・保険業（上記以外）',
	},
	{
		value: IndustryEnum.RealEstateAndGoodsRentalAndLeasing,
		label: '不動産業・物品賃貸業',
	},
	{
		value: IndustryEnum.ScientificResearchProfessionalAndTechnicalServices,
		label: '学術研究・専門・技術サービス業',
	},
	{
		value: IndustryEnum.AccommodationsEatingAndDrinkingServices,
		label: '宿泊業・飲食サービス業',
	},
	{
		value: IndustryEnum.LivingRelatedAndPersonalServicesAndAmusementServices,
		label: '生活関連サービス業・娯楽業',
	},
	{ value: IndustryEnum.EducationLearningSupport, label: '教育・学習支援業' },
	{
		value: IndustryEnum.DigitalContentsAndInformationProductSales,
		label: 'デジタルコンテンツ・情報商材販売業',
	},
	{ value: IndustryEnum.MedicalHealthCareAndWelfare, label: '医療・福祉' },
	{
		value: IndustryEnum.CompoundServices,
		label: '複合サービス事業（協同組合等）',
	},
	{
		value:
			IndustryEnum.PostalItemReceptionTelephoneReceptionAndTransferServices,
		label: 'サービス業（郵便物受取・電話受付代行・電話転送サービス）',
	},
	{ value: IndustryEnum.Services, label: 'サービス業（上記以外）' },
	{
		value: IndustryEnum.GovernmentExceptElsewhereClassified,
		label: '公務（他に分類されるものを除く）',
	},

	{
		value: IndustryEnum.Government,
		label: '公務',
	},
	{
		value: IndustryEnum.ReligiousCorporationsAndNonProfitOrganizations,
		label: '宗教法人・NPO法人',
	},
	{
		value: IndustryEnum.IndustriesUnableToClassify,
		label: '上記以外のその他法人',
	},
];

export const getIndustryLabel = (
	industry: IndustryEnum | undefined,
): string => {
	const result = IndustrySelectOptions.find((option) => {
		return industry === option.value;
	});
	return result?.label ?? '';
};
