import type { IndividualRejectedReason } from '@/graphql';

type Args = {
	rejectedReason: IndividualRejectedReason | null | undefined;
	reapplicationRequestMessage: string | null | undefined;
};

export const getIndividualRejectReasons = ({
	reapplicationRequestMessage,
	rejectedReason,
}: Args): {
	rejectedReasons: string[];
	rejectedComment?: string | null;
} => {
	if (!rejectedReason) return { rejectedReasons: [] };

	const rejectedReasons = Object.entries(rejectedReason).flatMap(
		([key, value]) => {
			// // NOTE: Omit __typename to avoid rendering type name in the UI
			if (key === '__typename') return [];
			return value ?? [];
		},
	);

	return {
		rejectedReasons,
		rejectedComment: reapplicationRequestMessage,
	};
};
