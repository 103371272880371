import type { PostPaidBillingPaymentStatusEnum } from '@/graphql';
import styled from 'styled-components';
import { Status } from './Status';

type Props = {
	amount: number;
	targetMonth: string; // ISO8601Date
	paymentStatus?: PostPaidBillingPaymentStatusEnum;
};

const Row = styled.tr``;

const RowHeaderCell = styled.th`
  text-align: left;
  font-size: 15px;
  width: 112px;
  font-weight: 500;
  padding: 16px 0;
`;

const Cell = styled.td`
  padding: 16px 0;
`;

const AmountCell = styled(Cell)`
  text-align: right;
`;

const Amount = styled.p`
  margin: 0;
  font-size: 24px;
  display: inline-flex;
  line-height: 1;
  align-items: baseline;
  font-weight: 500;
`;

const Unit = styled.span`
  font-size: 14px;
  margin-left: 4px;
`;

const targetMonthOptions: Intl.DateTimeFormatOptions = {
	year: 'numeric',
	month: 'short',
};

export const BillingTableRow = ({
	amount,
	paymentStatus,
	targetMonth,
}: Props): JSX.Element => {
	return (
		<Row>
			<RowHeaderCell scope="row">
				{new Date(targetMonth).toLocaleDateString('ja-JP', targetMonthOptions)}
			</RowHeaderCell>
			<Cell>
				<Status paymentStatus={paymentStatus} />
			</Cell>
			<AmountCell>
				<Amount>
					{amount.toLocaleString()}
					<Unit>円</Unit>
				</Amount>
			</AmountCell>
		</Row>
	);
};
