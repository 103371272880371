export const formatDateToYearMonth = (dateStr: string | undefined): string => {
	if (!dateStr) return '-';
	const date = new Date(dateStr);
	// biome-ignore lint/suspicious/noGlobalIsNan: This is a valid use case
	if (isNaN(date.getTime())) return '-';

	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');

	return `${year}/${month}`;
};

export const generateMonthsArray = (
	startDateStr: string,
	monthsAhead: number,
): string[] => {
	const startDate = new Date(startDateStr);
	const dates: string[] = [];

	for (let i = 1; i <= monthsAhead; i++) {
		const date = new Date(startDate.getFullYear(), startDate.getMonth() + i, 1);
		dates.push(
			`${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}`,
		);
	}

	return dates;
};
