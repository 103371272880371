import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { useFormContext } from 'react-hook-form';
import type { CorporateNameKanaData } from './schema';

export const CorporateNameKanaInput = (): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<CorporateNameKanaData>();

	return (
		<>
			<TextField aria-label="nameKana" {...register('nameKana')} />
			{errors.nameKana && <FormError>{errors.nameKana.message}</FormError>}
		</>
	);
};
