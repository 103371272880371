import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { useAutoKanaInput } from '@/hooks/useAutoKanaInput';
import { useFormContext } from 'react-hook-form';
import type { RepresentativeFirstNameData } from './schema';

export const RepresentativeFirstNameInput = ({
	updateValue,
}: {
	updateValue: (value: string) => void;
}): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<RepresentativeFirstNameData>();

	const { onCompositionUpdate, onCompositionEnd, onInput } = useAutoKanaInput({
		updateFormValue: updateValue,
	});

	return (
		<div>
			<TextField
				aria-label="representativeFirstName"
				{...register('representativeFirstName')}
				onCompositionUpdate={onCompositionUpdate}
				onCompositionEnd={onCompositionEnd}
				onInput={onInput}
				placeholder="例）太郎"
			/>
			{errors.representativeFirstName && (
				<FormError>{errors.representativeFirstName.message}</FormError>
			)}
		</div>
	);
};
