import closeIcon from '@/assets/images/icons/icon_close_for_highlight_announcement.svg';
import { FlashMessage } from '@/components/new/FlashMessage';
import { Link } from '@/components/new/Link';
import { Image } from '@/components/ui/Image';
import type { HighlightAnnouncement as HighlightAnnouncementType } from '@/graphql';
import { useState } from 'react';
import styled from 'styled-components';

export type HighlightAnnouncementProps = Pick<
	HighlightAnnouncementType,
	'id' | 'destinationUrl' | 'message'
>;

const RemoveButton = styled.button`
  display: inline-flex;
  background: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  color: unset;
  cursor: pointer;
  position: absolute;
  right: 12px;
  &:hover {
    opacity: 0.6;
  }
`;

const LinkText = styled.span`
  color: #8a6d3b;
  text-decoration: underline;
  margin-left: 4px;
  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: #8a6d3b;
    text-decoration: underline;
  }
`;

export const HighlightAnnouncement = ({
	id,
	destinationUrl,
	message,
}: HighlightAnnouncementProps): JSX.Element | null => {
	const announceId = id.toString();
	const [hideHighlightAnnouncement, setHideHighlightAnnouncement] = useState(
		() =>
			window.localStorage.getItem('hideHighlightAnnouncement') === announceId,
	);

	const handleClick = () => {
		window.localStorage.setItem('hideHighlightAnnouncement', announceId);
		setHideHighlightAnnouncement(true);
	};

	return (
		<>
			{!hideHighlightAnnouncement && (
				<FlashMessage color="warning" isAnnouncement>
					{message}
					{destinationUrl && (
						<Link href={destinationUrl} target="_blank">
							<LinkText>詳しくはこちら</LinkText>
						</Link>
					)}
					<RemoveButton onClick={handleClick}>
						<Image src={closeIcon} width={15} height={15} alt="閉じる" />
					</RemoveButton>
				</FlashMessage>
			)}
		</>
	);
};
