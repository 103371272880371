import { Table } from '@/components/new/Table';
import type { NotificationDetailFragment } from '@/graphql';
import { NotificationRow } from './NotificationRow';

export type NotificationsTableProps = {
	items: NotificationDetailFragment[];
	theadText: string;
	showSlackColumn: boolean;
};

export const NotificationsTable = ({
	items,
	theadText,
	showSlackColumn,
}: NotificationsTableProps): JSX.Element => {
	return (
		<Table.Root>
			<Table.Head>
				<Table.Row>
					<Table.ColumnHeaderCell>{theadText}</Table.ColumnHeaderCell>
					<Table.ColumnHeaderCell width="112px">
						メール通知
					</Table.ColumnHeaderCell>
					<Table.ColumnHeaderCell width="112px">
						プッシュ通知
					</Table.ColumnHeaderCell>
					{showSlackColumn && (
						<Table.ColumnHeaderCell width="112px">
							Slack通知
						</Table.ColumnHeaderCell>
					)}
				</Table.Row>
			</Table.Head>
			<Table.Body>
				{items.map((item, index) => (
					<NotificationRow
						key={item.id}
						name={item.name}
						thresholdRequired={item.thresholdRequired}
						index={index}
						showSlackColumn={showSlackColumn}
					/>
				))}
			</Table.Body>
		</Table.Root>
	);
};
