import { yup } from '@/lib/yup';

export const MAX_COUNT = 5;
export const INVALID_MIME_ERROR = 'JPG,PNG,PDF形式でアップロードしてください';
export const INVALID_FILENAME_ERROR =
	'ファイル名は255文字以内で設定してください';
export const INVALID_FILESIZE_ERROR =
	'10MBを超えるファイルはアップロードできません';
export const MIN_FILES_ERROR =
	'ファイルが選択されていません。登記事項証明書の写真またはPDFファイルを選択してください。';
export const MAX_FILES_ERROR = `アップロードできるファイルは${MAX_COUNT}つまでです`;
export const INVALID_FILES_ERROR =
	'正しくないファイルがあるようです。メッセージをご確認の上、再度選択してください。';

const localFileSchema = yup.object({
	file: yup
		.mixed()
		.test('mime', INVALID_MIME_ERROR, (value) => {
			if (!(value instanceof File)) return false;

			return /^(?:(image\/jpeg)|(image\/png)|(image\/gif)|(application\/pdf))$/.test(
				value.type,
			);
		})
		.test('fileName', INVALID_FILENAME_ERROR, (value) => {
			if (!(value instanceof File)) return false;

			return value.name.length < 255;
		})
		.test('size', INVALID_FILESIZE_ERROR, (value) => {
			if (!(value instanceof File)) return false;

			return value.size < 10485760;
		})
		.required(),
});

export type FormValues = {
	corporateRegistrations: { file: File }[];
	isConfirmed: boolean;
};
export const schema = yup.object({
	corporateRegistrations: yup
		.array()
		.of(localFileSchema)
		.when('$uploadedFilesCount', ([uploadedFilesCount], schema) =>
			uploadedFilesCount < MAX_COUNT
				? schema
						.min(1, MIN_FILES_ERROR)
						.max(MAX_COUNT - uploadedFilesCount, MAX_FILES_ERROR)
				: schema.length(0, MAX_FILES_ERROR),
		),
	isConfirmed: yup.boolean().required(),
});
