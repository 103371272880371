import {
	type ExportCardMutationVariables,
	useExportCardMutation,
} from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { useDownloadCSV } from '@/hooks/useDownloadCSV';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import { useState } from 'react';

type ExportCardFunction = (arg: ExportCardMutationVariables) => Promise<void>;
type UseExportCardMutationReturn = [ExportCardFunction, { loading: boolean }];
type Args = {
	onAsyncExport?: () => void;
};

export const useExportCards = ({
	onAsyncExport,
}: Args): UseExportCardMutationReturn => {
	const { handleMutationError } = useApiError();
	const { showErrorNotification } = useGlobalNotification();
	const downloadCSV = useDownloadCSV();
	const [getPresignedUrlDetails] = useExportCardMutation({
		onError: (error) => handleMutationError(error),
	});
	const [loading, setLoading] = useState(false);
	const resetMessageAndNotification = useResetMessageAndNotification();

	const exportCardTransactions = async (
		variables: ExportCardMutationVariables,
	) => {
		if (loading) return;

		resetMessageAndNotification();
		setLoading(true);

		const { data } = await getPresignedUrlDetails({ variables });

		if (data?.exportCard?.isAsyncExport) {
			onAsyncExport?.();
			setLoading(false);
			return;
		}

		const presignedUrlDetails = data?.exportCard?.presignedUrlResponse;

		if (!presignedUrlDetails) {
			showErrorNotification(
				'予期せぬエラーが発生しました。繰り返し同様の操作をしても改善されない場合はお問い合わせください。',
			);
			setLoading(false);
			return;
		}

		await downloadCSV(presignedUrlDetails, 'カード一覧');

		setLoading(false);
	};

	return [exportCardTransactions, { loading }];
};
