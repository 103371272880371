import { StatusLabel } from '@/components/new/StatusLabel';
import { Table } from '@/components/new/Table';
import { PhoneNumberInput } from '@/components/ui/inputs';
import styled from 'styled-components';

const LabelBox = styled.div`
  margin-right: 8px;
  display: inline-block;
`;

const TextBox = styled.div`
  margin-bottom: 8px;
`;

export const PostPayPhoneNumberRow = (): JSX.Element => {
	return (
		<Table.Row>
			<Table.RowHeaderCell width="240px">
				<LabelBox>電話番号</LabelBox>
				<StatusLabel color="red">必須</StatusLabel>
			</Table.RowHeaderCell>
			<Table.Cell>
				<TextBox>
					カスタマーサポートからお電話をさせていただく場合がございます。
				</TextBox>
				<PhoneNumberInput />
			</Table.Cell>
		</Table.Row>
	);
};
