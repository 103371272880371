import { Button } from '@/components/new/Button';
import {
	CurrentRequestStatus,
	type LoginOfficesTableRow_TenantFragment,
} from '@/graphql';
import { useLoginOffice } from '@/hooks';
import { getString } from '@/lib/query';
import { formatIdentificationCode } from '@/lib/string';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { RegistrationStatus } from './RegistrationStatus';

type Props = {
	tenant: LoginOfficesTableRow_TenantFragment;
};

const Row = styled.tr`
  border-bottom: 1px solid #dce0e6;
`;

const Cell = styled.td`
  border-bottom: 1px solid #dce0e6;
`;

const OfficeNameCell = styled(Cell)`
  width: auto;
  padding: 8px 16px;
`;

const CodeCell = styled(Cell)`
  width: 64px;
  padding: 8px 0;
`;

const ButtonCell = styled(Cell)`
  text-align: right;
  padding: 8px 16px;
  width: 86px;
`;

const Box = styled.div`
  display: inline-block;
  margin-left: 10px;
`;

export const LoginOfficesTableRow = ({ tenant }: Props): JSX.Element => {
	const router = useRouter();
	const { loginOffice } = useLoginOffice();
	const redirectPath = getString(router.query.redirectPath);

	const handleClick = () => {
		if (tenant.office?.currentRequestStatus === CurrentRequestStatus.Denied) {
			alert(
				'ご利用できません。\n審査の結果、口座開設を見送らせていただくこととなりました。\nご期待に沿えず誠に恐縮ではございますが、ご理解賜りますよう何卒よろしくお願い申し上げます。\nご利用に関して不明点がございましたら、サポートサイトをご確認ください。',
			);
			return;
		}

		loginOffice(tenant.uid, redirectPath);
	};

	return (
		<Row>
			<OfficeNameCell>
				{tenant.name}
				<Box>
					<RegistrationStatus office={tenant.office} />
				</Box>
			</OfficeNameCell>
			<CodeCell>{formatIdentificationCode(tenant.identificationCode)}</CodeCell>
			<ButtonCell>
				<Button
					color="blue"
					variant="outline"
					size="medium"
					onClick={handleClick}
				>
					選択
				</Button>
			</ButtonCell>
		</Row>
	);
};
