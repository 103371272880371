import { StatusLabel } from '@/components/new/StatusLabel';
import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { Image } from '@/components/ui/Image';
import type { XardCardRequestInput } from '@/graphql';
import card from '@images/card.svg';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const NoteAndErrorLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const CorporateNameIllustrationAndNoteLayout = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Box = styled.div`
  margin-bottom: 8px;
`;

export type CardOptionsTableProps = {
	isCorporate: boolean;
};

export const CardOptionsTable = ({
	isCorporate,
}: CardOptionsTableProps): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<Pick<XardCardRequestInput, 'corporateName' | 'pin'>>();

	return (
		<Table.Root>
			<Table.Head>
				<Table.Row>
					<Table.ColumnHeaderCell colSpan={2}>
						オプション設定
					</Table.ColumnHeaderCell>
				</Table.Row>
			</Table.Head>
			<Table.Body>
				<Table.Row>
					<Table.RowHeaderCell width="200px">
						カード暗証番号（PIN）
						<br />
						<StatusLabel color="gray">任意</StatusLabel>
					</Table.RowHeaderCell>
					<Table.Cell>
						<Box>
							<Text size="small">
								カード発行後の変更はできません。指定がない場合は自動で付与されます。カード発行後にWebまたはアプリからご確認ください。
							</Text>
						</Box>
						<TextField
							aria-label="pin"
							{...register('pin')}
							placeholder="4桁の半角数字"
						/>
						<NoteAndErrorLayout>
							<Text size="small" color="notes">
								同じ数字3つ以上/連番(9012,7654等)
								{!isCorporate && '/生年月日'}
								は設定不可
							</Text>
							{errors.pin && <FormError>{errors.pin.message}</FormError>}
						</NoteAndErrorLayout>
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.RowHeaderCell width="200px">
						カードに印字する{isCorporate ? '法人名' : '屋号'}
						<br />
						<StatusLabel color="gray">任意</StatusLabel>
					</Table.RowHeaderCell>
					<Table.Cell>
						<TextField
							aria-label="corporateName"
							{...register('corporateName')}
							placeholder="全角15文字、半角30文字以内 / 和文・英文どちらでも可"
						/>
						{errors.corporateName && (
							<FormError>{errors.corporateName.message}</FormError>
						)}
						<CorporateNameIllustrationAndNoteLayout>
							<Image src={card} width={108} height={72} alt="card" />
							<Text>
								カード裏面に{isCorporate ? '法人名' : '屋号'}
								を印字することができます (青枠)
							</Text>
						</CorporateNameIllustrationAndNoteLayout>
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Table.Root>
	);
};
