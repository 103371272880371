import { UserIndustryEnum } from '@/graphql';
import { yup } from '@/lib/yup';

export type UserIndustrySchemaData = yup.Asserts<typeof userIndustrySchema>;

export const userIndustrySchema = yup.object({
	userIndustry: yup
		.mixed<UserIndustryEnum>()
		.oneOf(Object.values(UserIndustryEnum), '選択してください')
		.required('選択してください'),
});
