import { Text } from '@/components/new/Text';
import { Loading } from '@/components/screen';
import { DestinationForm } from '@/components/ui/DestinationForm/DestinationForm';
import { Image } from '@/components/ui/Image';
import {
	type CardDestinationMasterInput,
	useCardDestinationMasterQuery,
	useUpdateCardDestinationMasterMutation,
} from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import queryError from '@images/queryError.svg';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Layout } from './Layout';

const ErrorWrapper = styled.div`
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const ErrorView = (): JSX.Element => (
	<ErrorWrapper>
		<Image src={queryError} alt="query error" width={250} height={200} />
		<Text color="notes">読み込みエラー</Text>
	</ErrorWrapper>
);

export const SettingsDestinationsEdit = (): JSX.Element => {
	const router = useRouter();
	const { showSuccessNotification } = useGlobalNotification();
	const { id } = router.query;
	const { handleQueryError, handleMutationError } = useApiError();

	const { data, loading } = useCardDestinationMasterQuery({
		variables: {
			id: id as string,
		},
		skip: typeof id !== 'string',
		onError: handleQueryError,
	});

	const [updateDestination, { loading: saving }] =
		useUpdateCardDestinationMasterMutation({
			onCompleted() {
				router
					.push('/settings/destinations')
					.then(() => showSuccessNotification('カード送付先を保存しました'));
			},
			onError(error) {
				handleMutationError(error);
			},
		});

	const resetMessageAndNotification = useResetMessageAndNotification();

	const onSubmit = (data: CardDestinationMasterInput) => {
		if (saving) return;
		resetMessageAndNotification();
		updateDestination({ variables: { ...data, id: id as string } });
	};

	if (loading) return <Loading />;

	const destination = data?.currentOffice.wallet?.cardDestinationMaster;
	if (!destination) return <ErrorView />;

	return (
		<Layout>
			<DestinationForm
				defaultValues={destination}
				saving={saving}
				onSubmit={onSubmit}
			/>
		</Layout>
	);
};
