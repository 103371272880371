import { Checkbox } from '@/components/new/Checkbox';
import { CircularProgress } from '@/components/new/CircularProgress';
import { Table } from '@/components/new/Table';
import { CardImage } from '@/components/ui/CardImage';
import styled from 'styled-components';

import { TextWithQuestionIcon } from '@/components/ui/TextWithQuestionIcon';
import {
	CardColorEnum,
	type CardInfoFragment,
	CardState,
	CardTypeCategory,
} from '@/graphql';
import { formatSerialNumber } from '@/lib/card';
import { CardPin } from '../../components';
import { useToggleCardNumber, useToggleCardSecurityCode } from '../../hooks';

export type CardInfoTableProps = CardInfoFragment & {
	cardType: CardTypeCategory;
	cardId: string;
	cardLock: boolean;
};

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0;
`;

const THeadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FourNumber = styled.span`
  margin-right: 4px;
`;

export const CardInfoTable = ({
	cardId,
	lastFour,
	serialNumber,
	name,
	expirationMonth,
	expirationYear,
	state,
	cardType,
	cardLock,
	isCreditCard,
	color,
}: CardInfoTableProps): JSX.Element => {
	const disabled = [CardState.Unactivated, CardState.Terminated].includes(
		state,
	);

	const [
		showNumber,
		toggleShowNumber,
		{ data: dataNum, loading: loadingNum, errors: errorsNum },
	] = useToggleCardNumber(cardId, lastFour);
	const [
		showSecurityCode,
		toggleShowSecurityCode,
		{ data: dataSecCode, loading: loadingSecCode, errors: errorsSecCode },
	] = useToggleCardSecurityCode(cardId);

	const formattedNumber = dataNum
		.padStart(16, '•')
		.replace(/.{4}(?=.)/g, '$& ');

	const formattedNumberArray = dataNum.padStart(16, '*').match(/.{1,4}/g);

	const handleToggle = () => {
		toggleShowNumber();
		toggleShowSecurityCode();
	};

	// TODO: error handling
	if (errorsNum || errorsSecCode) console.error(errorsNum, errorsSecCode);
	return (
		<>
			<CardWrapper>
				<CardImage
					state={state}
					loading={loadingNum || loadingSecCode}
					hasError={!!errorsNum || !!errorsSecCode}
					pan={formattedNumber}
					name={name}
					expiration={`${expirationMonth
						.toString()
						.padStart(2, '0')}/${expirationYear.toString().slice(-2)}`}
					securityCode={dataSecCode}
					cardType={cardType}
					cardLock={cardLock}
					isCreditCard={isCreditCard}
					color={color ?? CardColorEnum.Black}
					toggleShowNumber={toggleShowNumber}
					toggleShowSecurityCode={toggleShowSecurityCode}
				/>
			</CardWrapper>
			<Table.Root>
				<Table.Head>
					<Table.Row>
						<Table.ColumnHeaderCell colSpan={2}>
							<THeadWrapper>
								カード情報
								{!disabled && (
									<Checkbox
										aria-label="showCardInfo"
										checked={showNumber && showSecurityCode}
										disabled={loadingNum || loadingSecCode}
										onChange={handleToggle}
									>
										カード情報を表示
									</Checkbox>
								)}
							</THeadWrapper>
						</Table.ColumnHeaderCell>
					</Table.Row>
				</Table.Head>
				<Table.Body>
					<Table.Row>
						<Table.RowHeaderCell>カード番号</Table.RowHeaderCell>
						<Table.Cell width="440px" size="large">
							{loadingNum ? (
								<CircularProgress size="small" />
							) : (
								formattedNumberArray?.map((num, i) => (
									// biome-ignore lint/suspicious/noArrayIndexKey: TODO
									<FourNumber key={i}>{num}</FourNumber>
								))
							)}
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.RowHeaderCell>名義人</Table.RowHeaderCell>
						<Table.Cell size="large">{name}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.RowHeaderCell>有効期限</Table.RowHeaderCell>
						<Table.Cell size="large">
							{expirationMonth.toString().padStart(2, '0')}/{expirationYear}
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.RowHeaderCell>セキュリティコード</Table.RowHeaderCell>
						<Table.Cell size="large">
							{loadingNum ? <CircularProgress size="small" /> : dataSecCode}
						</Table.Cell>
					</Table.Row>
					{cardType === CardTypeCategory.Physical && (
						<Table.Row>
							<Table.RowHeaderCell>PIN（暗証番号）</Table.RowHeaderCell>
							<Table.Cell size="large">
								<CardPin {...{ cardId, disabled }} />
							</Table.Cell>
						</Table.Row>
					)}
					{serialNumber && (
						<Table.Row>
							<Table.RowHeaderCell>
								<TextWithQuestionIcon
									label="シリアルナンバー（S/N）"
									content="ナンバーレスリアルカードの裏面に記載されているカード固有の番号です"
								/>
							</Table.RowHeaderCell>
							<Table.Cell size="large">
								{formatSerialNumber(serialNumber)}
							</Table.Cell>
						</Table.Row>
					)}
				</Table.Body>
			</Table.Root>
		</>
	);
};
