import { StatusLabel } from '@/components/new/StatusLabel';
import { Table } from '@/components/new/Table';
import { PostPayAccountInput } from '@/components/ui/inputs/PostPayAccount';
import type { Account } from '@/graphql';
import styled from 'styled-components';

type AccountProps = {
	accounts: Account[];
};

const Box = styled.div`
  margin-right: 8px;
  display: inline-block;
`;

export const PostPayAccountRow = ({ accounts }: AccountProps): JSX.Element => {
	return (
		<Table.Row>
			<Table.RowHeaderCell width="240px">
				<Box>引落口座選択</Box>
				<StatusLabel color="red">必須</StatusLabel>
			</Table.RowHeaderCell>
			<Table.Cell>
				<PostPayAccountInput accounts={accounts} />
			</Table.Cell>
		</Table.Row>
	);
};
