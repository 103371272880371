import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { useFormContext } from 'react-hook-form';
import type { RepresentativeMiddleNameEnData } from './schema';

export const RepresentativeMiddleNameEnInput = (): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<RepresentativeMiddleNameEnData>();

	return (
		<div>
			<TextField
				aria-label="代表者ミドルネーム（英語表記）"
				{...register('representativeMiddleNameEn')}
				placeholder="ミドルネーム（任意）"
			/>
			{errors.representativeMiddleNameEn && (
				<FormError>{errors.representativeMiddleNameEn.message}</FormError>
			)}
		</div>
	);
};
