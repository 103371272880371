import { Head } from '@/components/head';
import { RegistrationLayout } from '@/components/ui/RegistrationLayout';
import { RegistrationNote } from '@/components/ui/RegistrationNote';
import { RegistrationTitle } from '@/components/ui/RegistrationTitle';
import { Form } from './Form';

export const RegistrationCorporateSearch = (): JSX.Element => (
	<RegistrationLayout>
		<Head title="法人情報検索" />
		<RegistrationTitle title="法人情報の入力" center />
		<RegistrationNote center>
			法人名から、法人番号と所在地を自動で入力します。
		</RegistrationNote>

		<Form />
	</RegistrationLayout>
);
