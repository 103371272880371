import {
	type CreateIdentificationVerificationMutation,
	useCreateIdentificationVerificationMutation,
} from '@/graphql';
import { useGoToStepsOrConfirmation } from '@/hooks';
import { useApiError } from '@/hooks/useApiError';
import type { FetchResult } from '@apollo/client';
import type { FormData } from './types';

export const useTermsForm = (): {
	onSubmit: (
		data: FormData,
	) => Promise<FetchResult<CreateIdentificationVerificationMutation>>;
} => {
	const goToStepsOrConfirmation = useGoToStepsOrConfirmation();
	const { handleMutationError } = useApiError();
	const [createIdentificationVerification] =
		useCreateIdentificationVerificationMutation({
			onCompleted: () => goToStepsOrConfirmation(),
			onError: (error) => handleMutationError(error),
		});

	const onSubmit = (formData: FormData) =>
		createIdentificationVerification({
			variables: {
				isRegisteredInJapan: formData.isRegisteredInJapan === 'true',
				isNotFatca: formData.isNotFatca === 'true',
				isNotPeps: formData.isNotPeps === 'true',
				isNotAntisocialForce: formData.isNotAntisocialForce === 'true',
				isNotRelatedToSanctionedCountries:
					formData.isNotRelatedToSanctionedCountries === 'true',
			},
		});

	return { onSubmit };
};
