import { Link } from '@/components/new/Link';
import { Table } from '@/components/new/Table';
import type {
	Maybe,
	PostPayRequest,
	PostPaySettingDetailsFragment,
} from '@/graphql';
import { transformQueryResult } from '@/lib/transform';
import styled from 'styled-components';

export type PostPayTableProp = {
	// アクセス制御しているのでこのページにいる時点でnullになる可能性はないが表示上必要
	setting: Maybe<PostPaySettingDetailsFragment> | undefined;
	postpayRequest: Maybe<PostPayRequest> | undefined;
	isShowGuide: boolean;
};

const AccountWrapper = styled.div`
  line-height: 20px;
`;

const TableWrapper = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const LinkWrapper = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	paddingRight: '8px',
});

export const SettingPostPayTable = ({
	setting,
	postpayRequest,
	isShowGuide,
}: PostPayTableProp): JSX.Element => {
	return (
		<TableWrapper>
			<Table.Root>
				<Table.Head>
					<Table.Row>
						<Table.ColumnHeaderCell colSpan={2}>
							与信限度額
						</Table.ColumnHeaderCell>
					</Table.Row>
				</Table.Head>
				<Table.Body>
					<Table.Row>
						<Table.RowHeaderCell>与信限度額</Table.RowHeaderCell>
						<Table.Cell width="440px" size="large">
							{setting?.creditLine !== undefined ? (
								<LinkWrapper>
									{`${transformQueryResult.tenThousandLocaleString(
										setting?.creditLine,
									)}万円`}
									<Link href="/settings/postpay/limit/edit">
										与信限度額の変更
									</Link>
								</LinkWrapper>
							) : (
								<>
									{!postpayRequest || isShowGuide ? (
										<LinkWrapper>
											-<Link href="/postpay/registration">与信審査</Link>
										</LinkWrapper>
									) : (
										<>-</>
									)}
								</>
							)}
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.RowHeaderCell>希望金額</Table.RowHeaderCell>
						<Table.Cell width="440px" size="large">
							{setting?.requestAmount !== undefined ? (
								<>
									{`${transformQueryResult.tenThousandLocaleString(
										setting.requestAmount,
									)}万円`}
								</>
							) : (
								'-'
							)}
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table.Root>
			<Table.Root>
				<Table.Head>
					<Table.Row>
						<Table.ColumnHeaderCell colSpan={2}>口座</Table.ColumnHeaderCell>
					</Table.Row>
				</Table.Head>
				<Table.Body>
					<Table.Row>
						<Table.RowHeaderCell>引落用口座</Table.RowHeaderCell>
						<Table.Cell width="440px" size="large">
							<AccountWrapper>
								{setting?.ownerSubAccount ? (
									<>
										{setting.ownerSubAccount.serviceName}&emsp;
										{setting.ownerSubAccount.name}
										<br />
										{setting.ownerSubAccount.type}&emsp;
										{setting.ownerSubAccount.number}
									</>
								) : (
									'-'
								)}
							</AccountWrapper>
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table.Root>
		</TableWrapper>
	);
};
