import { RoleEnum } from '@/graphql';
import { isString } from '@/lib/query';
import { useRouter } from 'next/router';

export const useDefaultOpen = (): boolean => {
	const router = useRouter();

	const hasDisplayName = isString(router.query.displayName);
	const hasRole =
		router.query.role === RoleEnum.Admin ||
		router.query.role === RoleEnum.Employee;

	return hasDisplayName || hasRole;
};
