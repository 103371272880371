import { DataTable, type DataTableProps } from '@/components/new/DataTable';
import { Table } from '@/components/new/Table';
import { TextWithQuestionIcon } from '@/components/ui/TextWithQuestionIcon';
import type { ValueTransactionDetailsFragment } from '@/graphql';
import { CurrencyFormat, DateTimeFormat } from '@/lib/i18n';
import { TransactionContentCell } from './TransactionContentCell';

export type ValueTransactionsTableProps = Pick<
	DataTableProps<ValueTransactionDetailsFragment>,
	'loading' | 'error' | 'items' | 'noItemsIllustration' | 'noItemsMessage'
>;
export const ValueTransactionsTable = ({
	loading,
	error,
	items,
	noItemsIllustration,
	noItemsMessage,
}: ValueTransactionsTableProps) => (
	<DataTable
		loading={loading}
		error={error}
		items={items}
		noItemsIllustration={noItemsIllustration}
		noItemsMessage={noItemsMessage}
		thead={
			<Table.Row>
				<Table.ColumnHeaderCell>取引日時</Table.ColumnHeaderCell>
				<Table.ColumnHeaderCell>取引内容</Table.ColumnHeaderCell>
				<Table.ColumnHeaderCell justify="end">入金</Table.ColumnHeaderCell>
				<Table.ColumnHeaderCell justify="end">出金</Table.ColumnHeaderCell>
			</Table.Row>
		}
		renderItem={(item) => (
			<Table.Row>
				<Table.Cell width="152px" size="large">
					{DateTimeFormat.jp.format(new Date(item.transactionTime))}
				</Table.Cell>
				<TransactionContentCell
					amount={item.amount}
					description={item.description}
					cardTransaction={item.cardTransaction}
				/>
				<Table.Cell justify="end" width="144px" size="large">
					{Number(item.amount) > 0 &&
						CurrencyFormat.jp.format(Number(item.amount))}
				</Table.Cell>
				<Table.Cell justify="end" width="144px" size="large">
					{Number(item.amount) < 0 && (
						<>
							{CurrencyFormat.jp.format(-Number(item.amount))}
							{Math.abs(Number(item.amount)) <= 10 && (
								<TextWithQuestionIcon
									label=""
									content={
										<>
											購入店舗によっては有効性確認のため、少額の引き落とし行う場合があります。
											<br />
											通常は数日以内に返金処理が行われますが、
											<br />
											店舗によっては1～2ヶ月程度を要する場合があります。
										</>
									}
								/>
							)}
						</>
					)}
				</Table.Cell>
			</Table.Row>
		)}
	/>
);
