import { Head } from '@/components/head';
import { PageLayout } from '@/components/screen';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 935px;
`;

export const Layout: React.FC = ({ children }) => (
	<PageLayout title="カード送付先">
		<Head title="カード送付先" />
		<Container>{children}</Container>
	</PageLayout>
);
