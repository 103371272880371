import type { ParsedUrlQuery } from 'node:querystring';
import {
	DepositWithdrawal,
	type WalletValueTransactionsQueryVariables,
} from '@/graphql';
import { getIso8601DateTime, getString } from '@/lib/query';
import { useRouter } from 'next/router';

export const useSearchVariables = (): Pick<
	WalletValueTransactionsQueryVariables,
	'depositWithdrawalType' | 'transactionTimeGte' | 'transactionTimeLte'
> => {
	const router = useRouter();

	const depositWithdrawalType = getDepositWithdrawalType(
		router.query.depositWithdrawalType,
	);
	const keyword = getString(router.query.keyword);
	const transactionTimeGte = getIso8601DateTime(
		router.query.transactionTimeGte,
		'gte',
	);
	const transactionTimeLte = getIso8601DateTime(
		router.query.transactionTimeLte,
		'lte',
	);

	return {
		depositWithdrawalType,
		...(keyword && { keyword }),
		...(transactionTimeGte && { transactionTimeGte }),
		...(transactionTimeLte && { transactionTimeLte }),
	};
};

const getDepositWithdrawalType = (
	query: ParsedUrlQuery[string],
): DepositWithdrawal => {
	switch (query) {
		case DepositWithdrawal.Deposit:
		case DepositWithdrawal.Withdrawal:
			return query;
		default:
			return DepositWithdrawal.Both;
	}
};
