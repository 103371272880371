import { SelectBox } from '@/components/new/SelectBox';
import { Table } from '@/components/new/Table';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { AddressInputs } from '@/components/ui/inputs';
import { useWalletCardsNewQuery } from '@/graphql';
import type { Option } from '@moneyforward/cloud-react-ui/lib/components/Select/Select';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

const TextFieldWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CardDestinationTable = (): JSX.Element => {
	const {
		register,
		formState: { errors },
		control,
		setValue,
	} = useFormContext();

	const { data } = useWalletCardsNewQuery({
		fetchPolicy: 'cache-and-network',
	});
	const destinations = data?.currentOffice.wallet?.cardDestinationMasters || [];

	const ONE_TIME_DESTINATION = 'oneTimeDestination';
	const options: Option[] = [
		{ value: ONE_TIME_DESTINATION, label: '今回だけの送付先' },
	];
	for (const element of destinations) {
		options.push({ value: element.id, label: element.label });
	}
	const defaultValue =
		options.find((e) => e.value !== ONE_TIME_DESTINATION) || options[0];

	const onCardDestinationChanged = (value: string) => {
		if (value === ONE_TIME_DESTINATION) {
			// NOTE:When selected '今回だけの送付先' reset all fields
			setValue('tradeName', '');
			setValue('destinationDepartmentName', '');
			setValue('destinationName', '');
			setValue('postalCode', '');
			setValue('prefectureCode', '');
			setValue('city', '');
			setValue('street', '');
			setValue('buildingName', '');
		} else {
			const selection = destinations.find((d) => d.id === value);
			if (selection) {
				setValue('tradeName', selection.tradeName);
				setValue('destinationDepartmentName', selection.departmentName);
				setValue('destinationName', selection.destinationName || '');
				setValue('postalCode', selection.postalCode || '');
				setValue('prefectureCode', selection.prefectureCode);
				setValue('city', selection.city || '');
				setValue('street', selection.street || '');
				setValue('buildingName', selection.buildingName);
			}
		}
	};
	return (
		<Table.Root aria-label="cardDestination">
			<Table.Head>
				<Table.Row>
					<Table.ColumnHeaderCell colSpan={2}>
						カード送付先
					</Table.ColumnHeaderCell>
				</Table.Row>
			</Table.Head>
			<Table.Body>
				<Table.Row>
					<Table.RowHeaderCell width="180px" bold>
						送付先選択
					</Table.RowHeaderCell>
					<Table.Cell>
						<Controller
							control={control}
							name={'cardDestination'}
							render={({ field }) => (
								<SelectBox
									{...field}
									aria-label="cardDestination"
									options={options}
									value={options.find((option) => option.value === field.value)}
									onValueChange={(value) => {
										field.onChange(value);
										onCardDestinationChanged(value);
									}}
									defaultValue={defaultValue}
									selectedTestId="selected-cardDestination"
									ariaControlsId="cardDestination"
								/>
							)}
						/>
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.RowHeaderCell width="180px" bold>
						会社名
					</Table.RowHeaderCell>
					<Table.Cell>
						<TextFieldWrapper>
							<TextField aria-label="tradeName" {...register('tradeName')} />
							{errors.tradeName && (
								<FormError>{errors.tradeName.message}</FormError>
							)}
						</TextFieldWrapper>
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.RowHeaderCell width="180px" bold>
						部署名
					</Table.RowHeaderCell>
					<Table.Cell>
						<TextFieldWrapper>
							<TextField
								aria-label="deptName"
								{...register('destinationDepartmentName')}
							/>
							{errors.destinationDepartmentName && (
								<FormError>
									{errors.destinationDepartmentName.message}
								</FormError>
							)}
						</TextFieldWrapper>
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.RowHeaderCell width="180px" bold>
						担当者名
					</Table.RowHeaderCell>
					<Table.Cell>
						<TextFieldWrapper>
							<TextField
								aria-label="destinationName"
								{...register('destinationName')}
							/>
							{errors.destinationName && (
								<FormError>{errors.destinationName.message}</FormError>
							)}
						</TextFieldWrapper>
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.RowHeaderCell width="180px" bold>
						住所
					</Table.RowHeaderCell>
					<Table.Cell>
						<AddressInputs />
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Table.Root>
	);
};
