import { IncomeEnum } from '@/graphql';
import { yup } from '@/lib/yup';

export type IncomeSchemaData = yup.Asserts<typeof incomeSchema>;

export const incomeSchema = yup.object({
	income: yup
		.mixed<IncomeEnum>()
		.oneOf(Object.values(IncomeEnum), '選択してください')
		.required('選択してください'),
});
