import { Button } from '@/components/new/Button';
import { Dialog } from '@/components/new/Dialog';
import { Image } from '@/components/ui/Image';
import { UpdatePostPaySettingRequestAmountResponseTypeEnum } from '@/graphql';
import registrationCompleted from '@images/applicationCompleted.svg';
import Link from 'next/link';
import styled from 'styled-components';

export type Props = {
	open: boolean;
	responseTitle?: string;
	responseType?: UpdatePostPaySettingRequestAmountResponseTypeEnum;
};

const ContentWrapper = styled.div`
  text-align: center;
  padding: 24px 0 16px;
`;

const Heading = styled.h1`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
`;

const Message = styled.p`
  font-size: 13px;
  margin: 24px 0;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  border-top: 1px solid #d4d8dd;
`;

const EmptyLink = styled.a`
  all: unset;
  & > button {
    width: 160px;
  }
`;

export const ResultModal = ({
	open,
	responseType,
	responseTitle,
}: Props): JSX.Element => {
	const isExpectedResult =
		responseType ===
			UpdatePostPaySettingRequestAmountResponseTypeEnum.Increase ||
		responseType === UpdatePostPaySettingRequestAmountResponseTypeEnum.Decrease;
	return (
		<Dialog open={open}>
			<Dialog.Window
				size="medium"
				onInteractOutside={(e) => e.preventDefault()}
				onEscapeKeyDown={(e) => e.preventDefault()}
				onPointerDownOutside={(e) => e.preventDefault()}
			>
				<Dialog.Contents>
					<ContentWrapper>
						<Heading>審査が完了しました</Heading>
						<Message>{responseTitle}</Message>
						{isExpectedResult && (
							<ImageWrapper>
								<Image
									src={registrationCompleted}
									width={360}
									height={160}
									alt=""
								/>
							</ImageWrapper>
						)}
					</ContentWrapper>
				</Dialog.Contents>
				<Dialog.Footer>
					<Footer>
						<Link href="/settings/postpay" passHref>
							<EmptyLink>
								<Button type="button" size="large">
									OK
								</Button>
							</EmptyLink>
						</Link>
					</Footer>
				</Dialog.Footer>
			</Dialog.Window>
		</Dialog>
	);
};
