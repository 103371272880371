import { Loading } from '@/components/screen/loading';
import { usePostPaySettingLimitEditQuery } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { PostPayLimitEditForm } from '../Form';

export const SettingsPostPayLimitEditContent = (): JSX.Element => {
	const { handleQueryError } = useApiError();
	const { data, loading } = usePostPaySettingLimitEditQuery({
		onError: handleQueryError,
	});

	if (loading) return <Loading />;

	const requestAmount =
		data?.currentOffice.wallet?.postPaySetting?.requestAmount;
	const creditLine = data?.currentOffice.wallet?.postPaySetting?.creditLine;

	// requestAmountがundefinedならtextboxを空白にする
	const inputValues =
		requestAmount !== undefined
			? {
					// 実際の数値を万円表示に対応する
					postPayLimit: Math.floor(requestAmount / 10000),
				}
			: undefined;

	return (
		<PostPayLimitEditForm defaultValues={inputValues} creditLine={creditLine} />
	);
};
