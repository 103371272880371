import { CardTypeCategory, type XardCardRequestInput } from '@/graphql';
import { yup } from '@/lib/yup';

export const cardTypeSchema: yup.ObjectSchema<
	Pick<XardCardRequestInput, 'cardType'>
> = yup.object({
	cardType: yup
		.mixed<CardTypeCategory>()
		.required()
		.when('$hasInsufficientBalance', {
			is: true,
			// biome-ignore lint/suspicious/noThenProperty: TODO
			then: (schema) =>
				schema.oneOf([CardTypeCategory.Virtual], '残高が足りません'),
			otherwise: (schema) => schema.oneOf(Object.values(CardTypeCategory)),
		}),
});
