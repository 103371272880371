import type { StepProps } from '@/components/new/Steps';
import {
	type Context,
	type ReactNode,
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';

interface ITwoStepperContext {
	statuses: StepProps['stepStatus'][];
	nextStep: VoidFunction;
	previousStep: VoidFunction;
}

interface IThreeStepperContext {
	statuses: StepProps['stepStatus'][];
	firstStep: VoidFunction;
	nextStep: VoidFunction;
	previousStep: VoidFunction;
}

type Props = {
	children: ReactNode;
	Context:
		| React.Context<ITwoStepperContext>
		| React.Context<IThreeStepperContext>;
};

export const createTwoStepperContext = () =>
	createContext<ITwoStepperContext>({} as ITwoStepperContext);

export const createThreeStepperContext = () =>
	createContext<IThreeStepperContext>({} as IThreeStepperContext);

export const useCreateUseTwoStepper = (
	context: Context<ITwoStepperContext>,
): ITwoStepperContext => {
	return useContext(context);
};

export const useCreateUseThreeStepper = (
	context: Context<IThreeStepperContext>,
): IThreeStepperContext => {
	return useContext(context);
};

export const Stepper: React.FC<Props> = ({ children, Context }) => {
	const [statuses, setStatuses] = useState<StepProps['stepStatus'][]>([
		'inProgress',
	]);
	const currentStep = statuses.length - 1;

	const firstStep = () => {
		const newStatuses: StepProps['stepStatus'][] = ['inProgress'];
		setStatuses(newStatuses);
	};

	const nextStep = () => {
		if (statuses.every((status) => status === 'completed')) return;

		const newStatuses: StepProps['stepStatus'][] = [...statuses];

		newStatuses[currentStep] = 'completed';

		if (statuses.length < 2) newStatuses.push('inProgress');
		if (statuses.length === 2) newStatuses.push('completed');

		setStatuses(newStatuses);
	};

	const previousStep = () => {
		if (statuses.length === 1 && statuses[0] === 'inProgress') return;

		const newStatuses = [...statuses];
		newStatuses.pop();

		newStatuses[newStatuses.length - 1] = 'inProgress';

		setStatuses(newStatuses);
	};

	const value = useMemo(
		() => ({ statuses, firstStep, nextStep, previousStep }),
		// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
		[statuses, firstStep, nextStep, previousStep],
	);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};
