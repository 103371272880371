import {
	type ExportValueTransactionMutationVariables,
	useExportValueTransactionMutation,
} from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { useDownloadCSV } from '@/hooks/useDownloadCSV';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import { useResetMessageAndNotification } from '@/hooks/useResetMessageAndNotification';
import { useState } from 'react';

type ExportValueTransactionFunction = (
	arg: ExportValueTransactionMutationVariables,
) => Promise<void>;
type UseExportValueTransactionMutationReturn = [
	ExportValueTransactionFunction,
	{ loading: boolean },
];
type Args = {
	onAsyncExport?: () => void;
};

export const useExportValueTransactions = ({
	onAsyncExport,
}: Args): UseExportValueTransactionMutationReturn => {
	const { handleMutationError } = useApiError();
	const { showErrorNotification } = useGlobalNotification();
	const downloadCSV = useDownloadCSV();
	const [getPresignedUrlDetails] = useExportValueTransactionMutation({
		onError: (error) => handleMutationError(error),
	});
	const [loading, setLoading] = useState(false);
	const resetMessageAndNotification = useResetMessageAndNotification();

	const exportCardTransactions = async (
		variables: ExportValueTransactionMutationVariables,
	) => {
		if (loading) return;

		resetMessageAndNotification();
		setLoading(true);

		const { data } = await getPresignedUrlDetails({ variables });

		// If the export is async, show the dialog and return
		if (data?.exportValueTransaction?.isAsyncExport) {
			onAsyncExport?.();
			setLoading(false);
			return;
		}

		const presignedUrlDetails =
			data?.exportValueTransaction?.presignedUrlResponse;

		if (!presignedUrlDetails) {
			showErrorNotification(
				'予期せぬエラーが発生しました。繰り返し同様の操作をしても改善されない場合はお問い合わせください。',
			);
			setLoading(false);
			return;
		}

		await downloadCSV(presignedUrlDetails, '入出金履歴');

		setLoading(false);
	};

	return [exportCardTransactions, { loading }];
};
