import { Table } from '@/components/new/Table';
import { Text } from '@/components/new/Text';
import { TextWithQuestionIcon } from '@/components/ui/TextWithQuestionIcon';
import { type CardControl, CardState, RoleEnum } from '@/graphql';
import styled from 'styled-components';
import { CardLockInput } from './inputs/CardLock';

export type CardLockRowProps = Pick<CardControl, 'cardLock'> & {
	state: CardState;
	role: RoleEnum | undefined;
};

const ErrorText = styled(Text)`
  color: #ec4949;
`;

const renderCardLock = (
	state: CardState,
	role: RoleEnum,
	cardLock: boolean,
): React.ReactNode => {
	switch (state) {
		case CardState.Unactivated:
			return '有効化待ち';
		case CardState.Terminated:
			return '解約済み';
		case CardState.Frozen:
			return <ErrorText>強制停止されました</ErrorText>;
		default: {
			if (role === RoleEnum.Admin) {
				return <CardLockInput />;
			}
			if (role === RoleEnum.Employee) {
				return cardLock ? '利用停止中' : 'オフ';
			}
		}
	}
};

export const CardLockRow = ({
	state,
	cardLock,
	role,
}: CardLockRowProps): JSX.Element => {
	return (
		<Table.Row>
			<Table.RowHeaderCell>
				{state === CardState.Frozen ||
				state === CardState.Terminated ||
				state === CardState.Unactivated ||
				role === RoleEnum.Employee ? (
					<>一時利用停止</>
				) : (
					<TextWithQuestionIcon
						label="一時利用停止"
						content="カードの利用を停止します"
					/>
				)}
			</Table.RowHeaderCell>
			<Table.Cell width="440px" size="large">
				{role && renderCardLock(state, role, cardLock)}
			</Table.Cell>
		</Table.Row>
	);
};
