type IconProps = {
	size?: number;
	color?: string;
};

export const DropdownIcon = ({ size = 18, color = '#747474' }: IconProps) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: TODO
		<svg
			width={size}
			height={size}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.00004 6C4.58681 6 4.21614 6.25417 4.06723 6.63964C3.91831 7.02511 4.02184 7.46248 4.32775 7.74029L8.99998 11.9834L9.6654 11.3791C9.66772 11.377 9.67003 11.375 9.67233 11.3729L13.6723 7.74029C13.9782 7.46248 14.0818 7.02511 13.9329 6.63964C13.7839 6.25417 13.4133 6 13 6H5.00004Z"
				fill={color}
			/>
		</svg>
	);
};
