import { yup } from '@/lib/yup';
import { plannedCardHolderCountSchema } from '../inputs/PlannedCardHolderCount';
import { plannedUsageAmountSchema } from '../inputs/PlannedUsageAmount';

export type RegistrationFirstCardPlansData = yup.Asserts<
	typeof registrationFirstCardPlansSchema
>;

export const registrationFirstCardPlansSchema = yup
	.object({})
	.concat(plannedCardHolderCountSchema)
	.concat(plannedUsageAmountSchema);
