import { Checkbox } from '@/components/new/Checkbox';
import { Table } from '@/components/new/Table';
import type { NotificationDetailFragment } from '@/graphql';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { ThresholdInput } from '../inputs/ThresholdInput';
import type { NotificationSettingInputsData } from '../schema';

export type NotificationRowProps = Pick<
	NotificationDetailFragment,
	'name' | 'thresholdRequired'
> & {
	index: number;
	showSlackColumn: boolean;
};

const NameWrapper = styled.div<{ hideInput: boolean }>`
  display: flex;
  gap: 16px;
  align-items: center;
  padding-top: ${(props) => (props.hideInput ? '8px' : '0')};
  padding-bottom: ${(props) => (props.hideInput ? '8px' : '0')};
`;

const CheckboxWrapper = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & span {
    margin-right: 0;
    margin-top: 0;
  }
`;

export const NotificationRow = ({
	name,
	thresholdRequired,
	index,
	showSlackColumn,
}: NotificationRowProps): JSX.Element => {
	const { register } = useFormContext<NotificationSettingInputsData>();
	return (
		<Table.Row>
			<Table.Cell>
				<NameWrapper hideInput={!thresholdRequired}>
					{name}
					{thresholdRequired && <ThresholdInput index={index} />}
				</NameWrapper>
			</Table.Cell>
			<Table.Cell>
				<CheckboxWrapper>
					<Checkbox
						{...register(`notificationSettingInputs.${index}.isMailActive`)}
						aria-label={`isMailActive-${index}`}
					/>
				</CheckboxWrapper>
			</Table.Cell>
			<Table.Cell>
				<CheckboxWrapper>
					<Checkbox
						{...register(`notificationSettingInputs.${index}.isPushActive`)}
						aria-label={`isPushActive-${index}`}
					/>
				</CheckboxWrapper>
			</Table.Cell>
			{showSlackColumn && (
				<Table.Cell>
					<CheckboxWrapper>
						<Checkbox
							{...register(`notificationSettingInputs.${index}.isSlackActive`)}
							aria-label={`isSlackActive-${index}`}
						/>
					</CheckboxWrapper>
				</Table.Cell>
			)}
		</Table.Row>
	);
};
