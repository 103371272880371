import { Link, type Props as LinkProps } from '@/components/new/Link';
import { Text } from '@/components/new/Text';
import { Image } from '@/components/ui/Image';
import angleRight from '@images/icons/angleRight.svg';
import lock from '@images/icons/lock.svg';
import styled, { css } from 'styled-components';

export type Props = LinkProps & {
	label: string;
	caption?: string;
	showLockIcon?: boolean;
};

const Block = styled.div<Pick<Props, 'caption'>>`
  ${({ caption }) => css`
    background: #ffffff;
    border: 1px solid #d4d8dd;
    ${
			!caption ? 'padding: 12px 8px 12px 16px;' : 'padding: 16px 8px 16px 16px;'
		}
    display: flex;
    color: #000;
    align-items: center;
    justify-content: space-between;
  `}
`;

const TextWrapper = styled.div`
  white-space: pre-wrap;
  word-break: break-all;
  padding-right: 16px;
  flex: 1;
`;

const LabelWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  line-height: 1.2;
`;

const StyledLabel = styled(Text)`
  margin-right: 4px;
`;

const StyledCaption = styled(Text)`
  color: #666;
  letter-spacing: 0.4px;
`;

const Box = styled.div`
  margin-top: 12px;
`;

export const LinkBlock = ({
	label,
	caption,
	showLockIcon = false,
	...rest
}: Props): JSX.Element => {
	return (
		<Link {...rest}>
			<Block caption={caption}>
				<TextWrapper>
					<LabelWrapper>
						<StyledLabel size={!caption ? 'large' : 'xlarge'}>
							{label}
						</StyledLabel>
						{showLockIcon && <Image src={lock} width={16} height={16} />}
					</LabelWrapper>
					{caption && (
						<Box>
							<StyledCaption size="small">{caption}</StyledCaption>
						</Box>
					)}
				</TextWrapper>
				<Image src={angleRight} width={24} height={24} />
			</Block>
		</Link>
	);
};
