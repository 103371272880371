import { RouteHistoryContext } from '@/contexts/RouteHistoryProvider';
import { useContext } from 'react';

export const useBackHref = (): string => {
	const { routeHistory } = useContext(RouteHistoryContext);

	const lastSettingsUsersRoute = routeHistory
		.filter((route) => /^\/settings\/users(\?.+)?$/.test(route))
		.pop();

	return lastSettingsUsersRoute ?? '/settings/users';
};
