import { Layout } from './components/Layout';
import { PointBalance } from './components/PointBalance';
import { PointTabs } from './components/PointTabs';

export const WalletPointsIndex = (): JSX.Element => {
	return (
		<Layout>
			<PointBalance />
			<PointTabs />
		</Layout>
	);
};
