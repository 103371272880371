import { yup } from '@/lib/yup';

export type PhoneNumberData = yup.Asserts<typeof phoneNumberSchema>;

export const phoneNumberSchema = yup.object({
	phoneNumber: yup
		.string()
		.stripDashes()
		.max(11)
		.halfWidthNumber()
		.required()
		// NOTE: unified validation with server
		// https://github.com/moneyforward/biz_pay_api/blob/f1faa65f2d3c07cc08596a81025c22e9226f9043/app/models/post_pay_request.rb
		.matches(/^0\d0\d{8}$|^0\d[^0]\d{7}$/, {
			message:
				'11桁の携帯電話番号、または10桁の固定電話番号で入力してください。',
		})
		.required(),
});
