import { PageLayout } from '@/components/screen/layouts/PageLayout';
import styled from 'styled-components';
import { StepperContext } from './New';

const Box = styled.div`
  max-width: 800px;
`;

export const Layout: React.FC = ({ children }) => (
	<StepperContext.Consumer>
		{({ statuses }) => (
			<PageLayout
				{...(statuses.length === 1
					? {
							title: 'カード追加発行',
							backLinkText: 'カード',
							backHref: '/cards',
						}
					: {
							title: 'カード追加発行',
						})}
			>
				<Box>{children}</Box>
			</PageLayout>
		)}
	</StepperContext.Consumer>
);
