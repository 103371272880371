import { Table } from '@/components/new/Table';
import { NavisSsoLink } from '@/components/ui/NavisSsoLink';
import type { OfficeMember } from '@/graphql';
import { usersUrl } from '@/lib/navis';
import styled from 'styled-components';

export type UserInfoTableProps = Pick<
	OfficeMember,
	'email' | 'displayName' | 'activatedAt'
>;

const Box = styled.div`
  margin-top: 8px;
`;

export const UserInfoTable = ({
	email,
	displayName,
	activatedAt,
}: UserInfoTableProps): JSX.Element => {
	const link = new URL(usersUrl);

	return (
		<Table.Root>
			<Table.Head>
				<Table.Row>
					<Table.ColumnHeaderCell colSpan={2}>
						ユーザー情報
					</Table.ColumnHeaderCell>
				</Table.Row>
			</Table.Head>
			<Table.Body>
				<Table.Row>
					<Table.RowHeaderCell>表示名</Table.RowHeaderCell>
					<Table.Cell width="440px">
						{displayName}
						<Box>
							表示名の変更は
							<NavisSsoLink link={link} />
							から行なってください。
						</Box>
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.RowHeaderCell>メールアドレス</Table.RowHeaderCell>
					<Table.Cell width="440px">{email || '---'}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.RowHeaderCell>利用状況</Table.RowHeaderCell>
					<Table.Cell width="440px">
						{activatedAt
							? 'ログイン済（規約同意済）'
							: '未ログイン（規約未同意）'}
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Table.Root>
	);
};
