import { Button } from '@/components/new/Button';
import { type PostPayRequest, PostPayRequestStatusEnum } from '@/graphql';
import NextLink from 'next/link';
import styled from 'styled-components';

type PostPayRequestSlim = Pick<PostPayRequest, 'status'>;

type Props = {
	isPostPayEnabled: boolean;
	isReapplicationEnabled: boolean;
} & Partial<PostPayRequestSlim>;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const Link = styled.a`
  font-size: 13px;
  text-decoration: none;
  color: #3b7de9;
  &:hover {
    opacity: 0.8;
  }
`;

export const PostPayLink = ({
	isPostPayEnabled,
	status,
	isReapplicationEnabled,
}: Props): JSX.Element => {
	// NOTE: isPostpayEnabled changes depending on whether postpaySetting is null or not.
	if (isPostPayEnabled) {
		return (
			<Wrapper>
				<NextLink href="/settings/postpay/limit/edit" passHref>
					<Link>与信限度額の変更</Link>
				</NextLink>
			</Wrapper>
		);
	}
	switch (status) {
		case PostPayRequestStatusEnum.Rejected:
			if (isReapplicationEnabled) {
				// NOTE: Allow for reapplication after one hour.
				return (
					<Wrapper>
						<NextLink href="/postpay/registration" passHref>
							<Button type="button" variant="outline" size="small">
								与信審査はこちら
							</Button>
						</NextLink>
					</Wrapper>
				);
			}
			// NOTE: Show the link to see the result within one hour after the application is rejected.
			return (
				<Wrapper>
					<NextLink href="/settings/postpay" passHref>
						<Link>詳細を見る</Link>
					</NextLink>
				</Wrapper>
			);
		case PostPayRequestStatusEnum.RequestedAccountTransfer:
		case PostPayRequestStatusEnum.Activated:
			// NOTE: During account transfer procedure.
			return (
				<Wrapper>
					<NextLink href="/settings/postpay" passHref>
						<Link>詳細を見る</Link>
					</NextLink>
				</Wrapper>
			);
		default:
			// NOTE: Show this status until the application is reviewed.
			return (
				<Wrapper>
					<NextLink href="/postpay/registration" passHref>
						<Button type="button" variant="outline" size="small">
							与信審査はこちら
						</Button>
					</NextLink>
				</Wrapper>
			);
	}
};
