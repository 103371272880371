import { Table } from '@/components/new/Table';
import { NavisSsoLink } from '@/components/ui/NavisSsoLink';
import { RoleInput, TenantUserInput } from '@/components/ui/inputs';
import { RoleEnum } from '@/graphql';
import { addUserUrl } from '@/lib/navis';
import styled from 'styled-components';

const Box = styled.div`
  margin-bottom: 8px;
`;

export const UsersNewTable = (): JSX.Element => {
	const link = new URL(addUserUrl);

	return (
		<Table.Root>
			<Table.Head>
				<Table.Row>
					<Table.ColumnHeaderCell colSpan={2}>
						マネーフォワード Pay for Business のユーザーを招待する
					</Table.ColumnHeaderCell>
				</Table.Row>
			</Table.Head>
			<Table.Body>
				<Table.Row>
					<Table.RowHeaderCell width="150px">表示名</Table.RowHeaderCell>
					<Table.Cell>
						<Box>
							ユーザーが存在しない場合は
							<NavisSsoLink link={link} />
							から追加してください。
						</Box>
						<TenantUserInput />
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.RowHeaderCell width="150px">ロール</Table.RowHeaderCell>
					<Table.Cell>
						<RoleInput>
							<RoleInput.Radio role={RoleEnum.Admin} />
							<RoleInput.Radio
								role={RoleEnum.Employee}
								disabled
								errorMessage="ここからは招待できません"
							/>
						</RoleInput>
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Table.Root>
	);
};
