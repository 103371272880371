import { useState } from 'react';
import { useStepper } from '../Terminate';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import type { FormValues } from './schema';

export const Step = (): JSX.Element => {
	const [values, setValues] = useState<FormValues | null>(null);
	const { statuses } = useStepper();

	if (values) {
		if (statuses.length === 2) return <Step2 savedValues={values} />;

		if (statuses.length === 3) return <Step3 />;
	}

	return <Step1 savedValues={values} saveValues={setValues} />;
};
