import { Button } from '@/components/new/Button';
import { CircularProgress } from '@/components/new/CircularProgress';

export type HaltButtonProps = {
	loading: boolean;
	halt: () => void;
};

export const HaltButton = ({ loading, halt }: HaltButtonProps): JSX.Element => {
	return (
		<Button
			type="button"
			size="small"
			color="red"
			disabled={loading}
			onClick={() => {
				if (loading) return;

				const confirmed = confirm(
					'カードを緊急停止してよろしいですか？この操作は取り消せません。緊急停止の解除は、マネーフォワード ビジネスカードの担当者へ依頼してください。',
				);
				if (!confirmed) return;

				halt();
			}}
		>
			{loading ? <CircularProgress size="small" /> : '緊急停止'}
		</Button>
	);
};
