import { StatusLabel } from '@/components/new/StatusLabel';
import {
	CurrentRequestStatus,
	IdentificationVerificationRequestStatus,
	type LoginOfficesTableRow_TenantFragment,
} from '@/graphql';

type Props = Partial<Pick<LoginOfficesTableRow_TenantFragment, 'office'>>;

export const RegistrationStatus = ({ office }: Props): JSX.Element | null => {
	if (!office) return null;

	switch (office.currentRequestStatus) {
		case CurrentRequestStatus.ActivatedCard:
			return (
				<StatusLabel color="blue" outline>
					開設済
				</StatusLabel>
			);
		case CurrentRequestStatus.Rejected:
			return (
				<StatusLabel color="blue" outline>
					再申請
				</StatusLabel>
			);
		default:
			return office.identificationVerificationRequest?.status ===
				IdentificationVerificationRequestStatus.Submitted ? (
				<StatusLabel color="blue" outline>
					審査中
				</StatusLabel>
			) : null;
	}
};
