import { SelectBox } from '@/components/new/SelectBox';
import { FormError } from '@/components/ui/FormError';
import { FinancialAssetsSelectOptions } from '@/lib/financialAssets';
import { Controller, useFormContext } from 'react-hook-form';
import type { FinancialAssetsSchemaData } from './schema';

export const FinancialAssetsInput = (): JSX.Element => {
	const {
		control,
		formState: { errors },
	} = useFormContext<FinancialAssetsSchemaData>();

	return (
		<>
			<Controller
				control={control}
				name="financialAssets"
				render={({ field }) => (
					<SelectBox
						{...field}
						aria-label="金融資産額"
						placeholder="選択してください"
						options={FinancialAssetsSelectOptions}
						value={FinancialAssetsSelectOptions.find(
							(option) => option.value === field.value,
						)}
						onValueChange={(value) => field.onChange(value)}
						selectedTestId="selected-financialAssets"
						ariaControlsId="financialAssets"
					/>
				)}
			/>
			{errors.financialAssets && (
				<FormError>{errors.financialAssets.message}</FormError>
			)}
		</>
	);
};
