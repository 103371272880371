import { Button } from '@/components/new/Button';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import Link from 'next/link';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import type { CorporateNumberData } from './schema';

const Wrapper = styled.div({
	display: 'grid',
	gridTemplateColumns: 'auto auto auto',
	alignItems: 'center',
	justifyContent: 'start',
	columnGap: '8px',
});
const Box = styled.div({
	width: '190px',
});
export const CorporateNumberInput = (): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<CorporateNumberData>();

	return (
		<Wrapper>
			<Box>
				<TextField
					aria-label="corporateNumber"
					{...register('corporateNumber')}
				/>
			</Box>
			<Link href="/registration/corporate/search">
				{/* type='button'を指定してsubmitでRouter.pushが実行されないようにする */}
				<Button type="button" variant="outline">
					法人検索
				</Button>
			</Link>
			{errors.corporateNumber && (
				<Box>
					<FormError>{errors.corporateNumber.message}</FormError>
				</Box>
			)}
		</Wrapper>
	);
};
