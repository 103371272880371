import { Head } from '@/components/head';
import { PageLayout } from '@/components/screen';
import styled from 'styled-components';

const Box = styled.div`
  max-width: 800px;
`;

export const Layout: React.FC = ({ children }) => (
	<PageLayout
		title="変更申請"
		backHref="/settings/postpay"
		backLinkText="与信限度額"
	>
		<Head title="変更申請" />
		<Box>{children}</Box>
	</PageLayout>
);
