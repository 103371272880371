import { yup } from '@/lib/yup';

export type CardDepartmentNameData = yup.Asserts<
	typeof cardDepartmentNameSchema
>;

export const cardDepartmentNameSchema = yup.object({
	departmentName: yup
		.string()
		.trim()
		.nullable()
		.emptyToNull()
		.max(50, '50文字以内で入力してください')
		.default(null), // default to null for individual
});
