import { Button } from '@/components/new/Button';
import { CircularProgress } from '@/components/new/CircularProgress';
import styled from 'styled-components';

const ButtonWrapper = styled.div({
	'> button': {
		width: '160px',
	},
});

type SaveButtonProps = {
	loading: boolean;
	isDirty: boolean;
};

export const SaveButton = ({
	loading,
	isDirty,
}: SaveButtonProps): JSX.Element => (
	<ButtonWrapper>
		<Button size="large" disabled={loading || !isDirty}>
			{loading ? <CircularProgress size="medium" /> : '保存'}
		</Button>
	</ButtonWrapper>
);
