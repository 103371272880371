import { CardColorEnum } from '@/graphql';
import { yup } from '@/lib/yup';

export type RegistrationFirstCardDesignData = yup.Asserts<
	typeof registrationFirstCardDesignSchema
>;

export const registrationFirstCardDesignSchema = yup.object({
	color: yup
		.mixed<CardColorEnum>()
		.oneOf(Object.values(CardColorEnum))
		.required(),
});
