import styled from 'styled-components';

type Props = {
	children: React.ReactNode;
};

const Wrapper = styled.div`
  margin-top: 16px;
`;

export const Body = ({ children }: Props): JSX.Element => {
	return <Wrapper>{children}</Wrapper>;
};
