import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { RegistrationAlert } from '@/components/ui/RegistrationAlert';
import { RegistrationBeneficialOwners } from '@/components/ui/RegistrationBeneficialOwners';
import { RegistrationButtons } from '@/components/ui/RegistrationButtons';
import {
	type BeneficialOwner,
	BeneficialOwnerClassificationEnum,
	type Prefecture,
	RegistrationCorporateBeneficialOwnersDocument,
	useUpdateBeneficialOwnersMutation,
} from '@/graphql';
import { useGoToStepsOrConfirmation } from '@/hooks';
import { useApiError } from '@/hooks/useApiError';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { type FormData, schema } from './types';

type Props = {
	beneficialOwners: BeneficialOwner[];
	prefectureCode?: Prefecture;
};

export const Form = ({
	beneficialOwners,
	prefectureCode,
}: Props): JSX.Element => {
	const { handleMutationError } = useApiError();
	const goToStepsOrConfirmation = useGoToStepsOrConfirmation();
	const [saveInfo, { loading }] = useUpdateBeneficialOwnersMutation({
		refetchQueries: [RegistrationCorporateBeneficialOwnersDocument],
		onCompleted: () => goToStepsOrConfirmation(),
		onError: (error) => handleMutationError(error),
	});
	const savedBeneficialOwners = beneficialOwners?.map((owner) => {
		const newOwner = { ...owner };
		newOwner.__typename = undefined;
		return newOwner;
	});

	const methods = useForm<FormData>({
		resolver: yupResolver(schema),
		defaultValues: {
			beneficialOwners:
				savedBeneficialOwners.length === 0
					? [
							{
								classification: BeneficialOwnerClassificationEnum.Individual,
								nationalityId: 'JP',
							},
						]
					: savedBeneficialOwners.map(
							({ nationality, nameEn, ...beneficialOwner }) => ({
								nationalityId: nationality?.id ?? 'JP',
								nameEn: nameEn ?? '',
								...beneficialOwner,
							}),
						),
		},
	});

	// 削除したい実質的支配者をサーバ側に通知するために、UI上で削除されたものを保持
	const [removedBeneficialOwnerIds, setRemovedBeneficialOwnerIds] = useState<
		string[]
	>([]);
	const addRemovedBeneficialOwnerIds = (id: string | undefined) => {
		if (id) {
			setRemovedBeneficialOwnerIds((prev) => prev.concat([id]));
		}
	};

	const onSubmit = (inputs: FormData) => {
		if (loading) return;

		saveInfo({
			variables: { ...inputs, deletedItemIds: removedBeneficialOwnerIds },
		});
	};

	return (
		<FormProvider {...methods}>
			<GlobalMessage />
			<RegistrationAlert />
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<RegistrationBeneficialOwners
					prefectureCode={prefectureCode}
					addRemovedBeneficialOwnerIds={addRemovedBeneficialOwnerIds}
				/>
				<RegistrationButtons />
			</form>
		</FormProvider>
	);
};
