import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { type Corporation, useSearchCorporationLazyQuery } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { yupResolver } from '@/lib/yup';
import { Button } from './Button';
import { Conditions } from './Conditions';
import { Result } from './Result';
import { type CorporateSearchData, corporateSearchSchema } from './types';

export const Form = (): JSX.Element => {
	const { handleQueryError } = useApiError();
	const [search, { data, loading }] = useSearchCorporationLazyQuery({
		onError: handleQueryError,
	});
	const methods = useForm<CorporateSearchData>({
		mode: 'onChange',
		resolver: yupResolver(corporateSearchSchema),
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: TODO
	const onSearch = useCallback(
		({
			searchMethod,
			corporateName,
			prefectureCode,
			cityName,
		}: CorporateSearchData) => {
			search({
				variables:
					searchMethod === 'partial'
						? {
								partialName: corporateName,
								prefectureCode,
								cityName,
							}
						: {
								prefixName: corporateName,
								prefectureCode,
								cityName,
							},
			});
		},
		[],
	);

	const results = (data?.corporations?.nodes || []) as Corporation[];
	const resultTotalCount = data?.corporations?.totalCount || 0;

	return (
		<FormProvider {...methods}>
			<GlobalMessage />

			<form onSubmit={methods.handleSubmit(onSearch)}>
				<Conditions />
				<Button isLoading={loading} />
				{data && (
					<Result results={results} resultTotalCount={resultTotalCount} />
				)}
			</form>
		</FormProvider>
	);
};
