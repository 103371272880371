import { yup } from '@/lib/yup';

export type TenantUsersData = yup.Asserts<typeof tenantUsersSchema>;

export const tenantUsersSchema = yup.object({
	tenantUsers: yup
		.array()
		.of(
			yup
				.object({
					label: yup.string().required(),
					value: yup.string().required(),
				})
				.typeError('ユーザーを指定してください'),
		)
		.min(1, 'ユーザーを指定してください')
		.when('$maxUserCount', ([maxUserCount], schema) =>
			maxUserCount
				? schema.max(
						maxUserCount,
						`最大${maxUserCount}名のユーザーを指定してください`,
					)
				: schema,
		)
		.required('ユーザーを指定してください'),
});
