import { Head } from '@/components/head';
import { PageLayout } from '@/components/screen';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 800px;
`;

type Props = {
	children: React.ReactNode;
};

export const Layout = ({ children }: Props): JSX.Element => (
	<PageLayout title="ポイント" backHref="/home" backLinkText="ホーム">
		<Head title="ポイント" />

		<Container>{children}</Container>
	</PageLayout>
);
