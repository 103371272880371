import { Head } from '@/components/head';
import { PageLayout } from '@/components/screen';
import styled from 'styled-components';

const Box = styled.div`
  max-width: 800px;
`;

export const Layout: React.FC = ({ children }) => (
	<PageLayout
		title="新規追加"
		backHref="/settings/destinations"
		backLinkText="カード送付先"
	>
		<Head title="新規追加" />
		<Box>{children}</Box>
	</PageLayout>
);
