import { RegistrationAlert } from '@/components/ui/RegistrationAlert';
import { yupResolver } from '@/lib/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Buttons } from './Buttons';
import { Inputs } from './Inputs';
import { useTermsForm } from './hooks';
import { type FormData, schema } from './types';

type Props = {
	tenantType: 'corporate' | 'individual';
};

export const Form = ({ tenantType }: Props): JSX.Element => {
	const methods = useForm<FormData>({
		resolver: yupResolver(schema),
	});
	const { onSubmit } = useTermsForm();

	return (
		<FormProvider {...methods}>
			<RegistrationAlert message="正しくチェックされていない項目があります。すべての項目をご確認ください。" />
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<Inputs tenantType={tenantType} />
				<Buttons />
			</form>
		</FormProvider>
	);
};
