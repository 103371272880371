import { Head } from '@/components/head';
import { Link } from '@/components/new/Link';
import { RegistrationNote } from '@/components/ui/RegistrationNote';
import { RegistrationRejectedReason } from '@/components/ui/RegistrationRejectedReason';
import { RegistrationStepCard } from '@/components/ui/RegistrationStepCard';
import { RegistrationStepLayout } from '@/components/ui/RegistrationStepLayout';
import { RegistrationStepLink } from '@/components/ui/RegistrationStepLink';
import { RegistrationTitle } from '@/components/ui/RegistrationTitle';
import type { RegistrationCorporateStepQuery } from '@/graphql';
import styled from 'styled-components';
import {
	useCorporateRejectReasons,
	useCorporateSteps,
} from '../../shared/Steps/hooks';

const Spacer = styled.div`
  height: 16px;
`;

type Props = {
	data: RegistrationCorporateStepQuery | undefined;
};

export const RegistrationCorporateSteps = ({ data }: Props): JSX.Element => {
	const { stepStatuses } = useCorporateSteps(data);
	const { rejectedReasons, rejectedComment } = useCorporateRejectReasons(data);

	return (
		<RegistrationStepLayout>
			<Head title="利用申込" />
			<RegistrationTitle
				center
				title="マネーフォワード Pay for Business 利用申込"
			/>
			<RegistrationNote center>
				以下の情報をすべて入力し、「申請内容の確認・提出」へお進みください。
			</RegistrationNote>

			<RegistrationRejectedReason
				rejectedReasons={rejectedReasons}
				rejectedComment={rejectedComment}
			/>

			<RegistrationStepCard
				step={
					stepStatuses.identification === 'waiting' ? 'search' : 'applicant'
				}
				status={stepStatuses.identification}
				title="法人情報の入力"
				subTitle="法人情報を入力してください。"
			/>

			<Spacer />

			<RegistrationStepCard
				step="first-card"
				status={stepStatuses.firstCard}
				title="新規カード設定"
				subTitle="新規作成するカードの設定をしてください。"
			/>

			<Spacer />

			<RegistrationStepCard
				step="beneficial-owners"
				status={stepStatuses.beneficialOwner}
				title="実質的支配者情報の入力"
				subTitle={
					<>
						非上場企業の場合、
						<Link
							href="https://support.biz.moneyforward.com/biz-pay/faq/word/f023.html"
							target="_blank"
						>
							実質的支配者情報
						</Link>
						を入力してください。
					</>
				}
			/>

			<Spacer />

			<RegistrationStepCard
				step="account-manager"
				status={stepStatuses.accountManager}
				title="取引担当者情報の入力"
				subTitle="本サービスに関するお取引を行う方の情報を入力してください。"
			/>

			<RegistrationStepLink
				entered={
					stepStatuses.accountManager === 'completed' ||
					stepStatuses.accountManager === 'incomplete'
				}
			/>

			<RegistrationStepCard
				step="identification"
				status={stepStatuses.appearanceAndDocument}
				title="取引担当者の本人確認"
				subTitle="取引担当者ご自身により本人確認書類および容貌の撮影を行ってください。"
				warning="Webサイトからは本人確認書類・本人容貌写真のアップロードはできません。マネーフォワード Pay for Business アプリにて撮影・アップロードをお願いいたします。"
			/>

			<Spacer />

			<RegistrationStepCard
				step="certificate-upload"
				status={stepStatuses.corporateRegistration}
				title="登記事項証明書のアップロード"
				subTitle="6ヶ月以内に取得した登記事項証明書を、写真またはPDF形式でアップロードしてください。アプリ上で撮影・アップロードも可能です。"
			/>

			<Spacer />

			<RegistrationStepCard
				step="confirmation"
				status={stepStatuses.confirmation}
				title="申請内容の確認・提出"
				subTitle="申請後、オペレーターが審査を行います。"
			/>
		</RegistrationStepLayout>
	);
};
