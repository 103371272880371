import { type CardColorEnum, CardTypeCategory } from '@/graphql';
import styled from 'styled-components';
import { FirstVirtualCard } from './FirstVirtualCard';
import { PhysicalCard } from './PhysicalCard';
import { SecondVirtualCard } from './SecondVirtualCard';

export type Props = {
	pan: string;
	name: string;
	expiration: string;
	securityCode: string;
	cardType: CardTypeCategory;
	isCreditCard: boolean;
	color: CardColorEnum;
};

const Box = styled.div`
  width: 353px;
  height: 223px;
`;

export const Card = ({
	pan,
	name,
	expiration,
	securityCode,
	cardType,
	isCreditCard,
	color,
}: Props): JSX.Element => {
	switch (cardType) {
		case CardTypeCategory.Physical:
			return (
				<Box aria-label="physicalCard">
					<PhysicalCard
						pan={pan}
						name={name}
						expiration={expiration}
						securityCode={securityCode}
						isCreditCard={isCreditCard}
						color={color}
					/>
				</Box>
			);

		case CardTypeCategory.Virtual:
			if (isCreditCard) {
				return (
					<Box aria-label="virtualCreditCard">
						<SecondVirtualCard pan={pan} name={name} />
					</Box>
				);
			}
			return (
				<Box aria-label="virtualCard">
					<FirstVirtualCard
						pan={pan}
						name={name}
						expiration={expiration}
						securityCode={securityCode}
					/>
				</Box>
			);
	}
};
