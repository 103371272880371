import { Radio } from '@/components/new/Radio';
import { Text } from '@/components/new/Text';
import { FormError } from '@/components/ui/FormError';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import type { IsListedData } from './schema';

const Box = styled.span`
  padding: 0 16px 0 4px;
`;
export const IsListedInput = (): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<IsListedData>();

	return (
		<>
			<Radio
				aria-label="isListed-false"
				{...register('isListed')}
				value="false"
			>
				<Box>
					<Text>非上場企業</Text>
				</Box>
			</Radio>
			<Radio aria-label="isListed-true" {...register('isListed')} value="true">
				<Box>
					<Text>上場企業</Text>
				</Box>
			</Radio>
			{errors.isListed && <FormError>{errors.isListed.message}</FormError>}
		</>
	);
};
