import { PostPaidBillingPaymentStatusEnum } from '@/graphql';
import styled from 'styled-components';

type Props = {
	paymentStatus?: PostPaidBillingPaymentStatusEnum;
};

const Base = styled.span`
  font-size: 12px;
  line-height: 1;
  width: 60px;
  height: 20px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const Estimating = styled(Base)`
  background: #d4d8dd;
  color: #666;
`;

const Failed = styled(Base)`
  background: #f57575;
  color: #fff;
`;

const Confirmed = styled(Base)`
  background: #c4d8f8;
  color: #333;
`;

export const Status = ({ paymentStatus }: Props): JSX.Element => {
	switch (paymentStatus) {
		case undefined:
		case PostPaidBillingPaymentStatusEnum.Estimating:
			return <Estimating>未確定</Estimating>;
		case PostPaidBillingPaymentStatusEnum.Failure:
			return <Failed>失敗</Failed>;
		default:
			return <Confirmed>確定</Confirmed>;
	}
};
