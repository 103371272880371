import { Head } from '@/components/head';

export const Layout: React.FC = ({ children }) => {
	return (
		<>
			<Head title="CSVダウンロード" />
			{children}
		</>
	);
};
