import { Button } from '@/components/new/Button';
import { CircularProgress } from '@/components/new/CircularProgress';
import styled from 'styled-components';

const ButtonWrapper = styled.div({
	'> button': {
		width: '160px',
	},
});

type UpdateButtonProps = {
	loading: boolean;
};

export const UpdateButton = ({ loading }: UpdateButtonProps): JSX.Element => (
	<ButtonWrapper>
		<Button color="blue" size="large" disabled={loading}>
			{loading ? <CircularProgress size="medium" /> : '申請'}
		</Button>
	</ButtonWrapper>
);
